import { App } from '../smartphone/types';
import { Maps } from './game-scene/functions/mapGenerator';

export enum Direction {
  DOWN = 'DOWN',
  UP = 'UP',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum Action {
  WALK = 'WALK',
  STOP = 'STOP',
}

export interface Tiles {
  name: string;
  path: string;
}

export type Spritesheet = Phaser.GameObjects.Sprite & {
  body: Phaser.Physics.Arcade.Body;
};

export type AnimationManager = Phaser.Animations.AnimationManager;

export type ArcadePhysics = Phaser.Physics.Arcade.ArcadePhysics;

export interface ITileSetProperty {
  name: string;
  type: string;
  value: true;
}

export interface ILayer {
  data: number[];
  height: number;
  id: number;
  name: string;
  opacity: number;
  type: string;
  visible: true;
  width: number;
  x: number;
  y: number;
  layers: ILayer[];
}

export interface ITileSet {
  columns: number;
  firstgid: number;
  image: string;
  imageheight: number;
  imagewidth: number;
  margin: number;
  name: string;
  spacing: number;
  tilecount: number;
  tileheight: number;
  tilewidth: number;
  properties: ITileSetProperty[];
  tiles: {
    id: number;
    properties: {
      name: string;
      type: string;
      value: true;
    }[];
  }[];
}

export interface ITiledMap {
  compressionlevel: number;
  editorsettings: {
    export: {
      format: 'tmx';
    };
  };
  height: number;
  infinite: boolean;
  layers: ILayer[];
  nextlayerid: number;
  nextobjectid: number;
  orientation: string;
  renderorder: string;
  tiledversion: string;
  tileheight: number;
  tilesets: ITileSet[];
  tilewidth: number;
  type: string;
  version: string;
  width: number;
}

export interface PlayerInstance {
  // playerState: PlayerState;
  playerAddress: string;
  penguinId: number;
  sprite?: Spritesheet;
  // target?: Phaser.Math.Vector2;
  direction?: Direction;
  action?: Action;
  x: number;
  y: number;
}

export type PhaserCreateProps = {
  startPosition: { x: number; y: number };
  openApp: (app: App, appProps?: any) => void;
};

export type PositionState = {
  direction: Direction;
  action: Action;
  x: number;
  y: number;
};

export interface ICoordinates {
  x: number;
  y: number;
}
export interface IExitToEvent {
  coordinates: ICoordinates;
  newMapName: Maps;
}
export interface IMusicEvent {
  musicName: string;
  source: string;
  audioLoop?: boolean;
}
export interface IOpenWebsiteEvent {
  url: string;
}

export interface IjitsiEvent {
  jitsiRoom: string;
  jitsiConfig: {
    startWithAudioMuted: boolean;
  };
}

export type CursorKeys = {
  up: Phaser.Input.Keyboard.Key;
  down: Phaser.Input.Keyboard.Key;
  right: Phaser.Input.Keyboard.Key;
  left: Phaser.Input.Keyboard.Key;
  space: Phaser.Input.Keyboard.Key;
  shift: Phaser.Input.Keyboard.Key;
  z: Phaser.Input.Keyboard.Key;
  w: Phaser.Input.Keyboard.Key;
  q: Phaser.Input.Keyboard.Key;
  a: Phaser.Input.Keyboard.Key;
  s: Phaser.Input.Keyboard.Key;
  d: Phaser.Input.Keyboard.Key;
};

export type WatchableLayers = Map<
  string,
  {
    onEnter: (layer: Phaser.Tilemaps.LayerData) => void;
    onLeave: (layer: Phaser.Tilemaps.LayerData) => void;
    layer?: Phaser.Tilemaps.LayerData;
  }
>;

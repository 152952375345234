import { Button, majorScale, Pane } from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import { FC, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as TwitterIcon } from './../../assets/icons/twitter.svg';
import { ReactComponent as DiscordIcon } from './../../assets/icons/discord.svg';
import { ReactComponent as RocketSvg } from './../../assets/icons/Rocket.svg';
import { ReactComponent as Title } from './../../assets/icons/Title.svg';
import { ReactComponent as Logo } from './../../assets/icons/Logo.svg';
import { useAuthContext } from '~/contexts/auth';
import { ConnectComponent } from './components/ConnectComponent';

const fontSize = 14;
const iconSize = 24;

interface IHeaderDesktopView {
  setHeaderHeight: (height: number) => void;
}

export const HeaderDesktopView: FC<IHeaderDesktopView> = ({
  setHeaderHeight,
}) => {
  const { user } = useAuthContext();
  let navigate = useNavigate();
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (headerRef && headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, [headerRef, setHeaderHeight]);

  return (
    <Pane
      zIndex={100}
      paddingRight={majorScale(3)}
      paddingLeft={majorScale(3)}
      height={majorScale(12)}
      width={'100%'}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      // position="fixed"
      ref={headerRef}
      backgroundColor="#031920"
    >
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() => navigate('/')}
      >
        <LogoPengFam />
        <TitlePengFam />
      </Pane>
      <Pane display="flex">
        <HeaderButton
          onClick={() =>
            window
              .open(
                'https://marketplace.kalao.io/collection/0x96f020b13f1b8b46d70723c3a3c7b4cc6618e99e',
                '_blank',
              )!
              .focus()
          }
          color={'white'}
          fontSize={fontSize}
          appearance="minimal"
        >
          Collections
        </HeaderButton>
      </Pane>
      <Pane display="flex">
        <HeaderButton
          // onClick={() => navigate('/vision')}
          disabled={true}
          color={'grey'}
          fontSize={fontSize}
          appearance="minimal"
        >
          Vision
        </HeaderButton>
      </Pane>
      <Pane display="flex">
        <Twitter
          onClick={() =>
            window.open('https://twitter.com/AvaxPenguinFam', '_blank')!.focus()
          }
        />
        <Discord
          onClick={() =>
            window.open('https://t.co/7kZ1ZLrDUu', '_blank')!.focus()
          }
        />
      </Pane>
      <Pane display="flex" alignItems="center" justifyContent="center">
        <ConnectComponent />
        {/* <PlayButton disabled={!user} onClick={() => navigate('/play')}>
          <RocketIcon disabled={!user ? 1 : 0} />
          Play now
        </PlayButton> */}
      </Pane>
    </Pane>
  );
};

const HeaderButton = styled(Button)`
  font-family: 'GravityBold8';

  :hover {
    background-color: transparent !important;
    color: white;
  }
  :focus {
    border: none !important;
    color: white;
  }
  :active {
    color: white;
  }
`;

const Twitter = styled(TwitterIcon)`
  width: ${iconSize}px;
  height: ${iconSize}px;
  fill: white;
  cursor: pointer;

  :hover {
    fill: white;
  }
  :focus {
    fill: white;
  }
  :active {
    fill: white;
  }
`;
const Discord = styled(DiscordIcon)`
  margin-left: 1rem;
  width: ${iconSize}px;
  height: ${iconSize}px;
  fill: white;
  cursor: pointer;

  :hover {
    fill: white;
  }
  :focus {
    fill: white;
  }
  :active {
    fill: white;
  }
`;

const TitlePengFam = styled(Title)`
  fill: white;
  cursor: pointer;

  :hover {
    fill: white;
  }
  :focus {
    fill: white;
  }
  :active {
    fill: white;
  }
`;

const LogoPengFam = styled(Logo)`
  fill: white;
  cursor: pointer;
  margin-right: 10px;

  :hover {
    fill: white;
  }
  :focus {
    fill: white;
  }
  :active {
    fill: white;
  }
`;

const PlayButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'GravityBold8';
  background-color: #006b8c;
  box-shadow: 3px 2px 2px rgba(0, 0, 0, 0.4), inset 2px 0px 2px #0a3745,
    inset 0px 2px 2px #5bcbee;
  color: white;
  border: none;
  border-radius: 0px;
  padding: 18px 8px;
  margin-left: 16px;
  cursor: pointer;

  :disabled {
    color: #343434;
    background-color: #605f5f;
    cursor: initial;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4), inset 4px 0px 4px #605f5f,
      inset 0px 4px 4px #605f5f;
  }
`;

const RocketIcon = styled(RocketSvg)<{ disabled: 0 | 1 }>`
  * {
    fill: ${({ disabled }) => (disabled ? '#343434' : '#002733')};
  }
  cursor: pointer;
  margin-right: 10px;

  max-width: 20px;
  max-height: 20px;

  :active {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4), inset 4px 0px 1px #10323d,
      inset 0px 4px 1px #10323d;
  }
`;

import styled from '@emotion/styled';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import * as Dialog from '~/components/dialog/Dialog';
import { MetamaskLogo } from '~/components/metamask-logo';
import { useAuthContext } from '~/contexts/auth';
import * as Form from '~/components/form/Form';
import { ButtonVariant } from '~/components/button/Button';
import { useUpdateUserMutation } from '~/generated/graphql/hooks';
import { css } from '@emotion/react';
import { useClickOutside } from '~/hooks/use-click-outside/useClickOutside';

interface IConnectedAsProps {}

export const ConnectComponent: FC<IConnectedAsProps> = () => {
  const { signin, signout, user } = useAuthContext();
  const [openPopup, setOpenPopup] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [updateUser] = useUpdateUserMutation();
  const [nickname, setNickName] = useState(user?.nickname ?? '');
  useClickOutside<HTMLDivElement>(containerRef, () => setOpenPopup(false));

  const userName = useMemo(() => {
    return (
      user &&
      (user.nickname ??
        `${user.publicAddress.substring(0, 5)}...${user.publicAddress.substring(
          user.publicAddress.length - 5,
        )}`)
    );
  }, [user]);

  return (
    <>
      <ConnectedAsContainer
        connected={user ? 1 : 0}
        ref={containerRef}
        onClick={() => {
          if (!user) {
            signin().then(() => user && setOpenPopup(!openPopup));
          } else {
            setOpenPopup(!openPopup);
          }
        }}
      >
        <MetamaskContainer>
          <MetamaskLogo />
        </MetamaskContainer>
        {openPopup ? (
          <Popup>
            <PopupHeader>Connected as</PopupHeader>
            <Name>{userName}</Name>
            <Button
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setDialogOpen(true);
                setOpenPopup(false);
              }}
            >
              Change Nickname
            </Button>
            <Button style={{ cursor: 'pointer' }} onClick={() => signout()}>
              Sign out
            </Button>
          </Popup>
        ) : null}
      </ConnectedAsContainer>
      {user && (
        <Dialog.Root visible={dialogOpen} hide={() => setDialogOpen(false)}>
          <Form.Root
            onSubmit={async (e) => {
              e.preventDefault();
              await updateUser({ variables: { id: user?.id, nickname } });
              setDialogOpen(false);
            }}
          >
            <Dialog.Header>Change your nickname</Dialog.Header>
            <Dialog.Content>
              <Form.TextInput
                autoFocus
                align="center"
                light
                value={nickname}
                onChange={(e) => setNickName(e.target.value)}
              />
            </Dialog.Content>
            <Dialog.Footer>
              <Form.SubmittButton variant={ButtonVariant.BLACK} light>
                Ok
              </Form.SubmittButton>
            </Dialog.Footer>
          </Form.Root>
        </Dialog.Root>
      )}
    </>
  );
};

const ConnectedAsContainer = styled.div<{ connected: 1 | 0 }>`
  position: relative;
  font-family: 'GravityBold8';
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  ${({ connected }) => {
    return connected
      ? css`
          background: #008c5a;
          box-shadow: inset 2px 0px 2px #0e553c, inset 0px 2px 2px #1fc68a;
        `
      : css`
          background-color: #006b8c;
          box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4), inset 2px 0px 2px #0a3745,
            inset 0px 2px 2px #5bcbee;
        `;
  }}
`;

const MetamaskContainer = styled.div`
  padding-left: 4px;
`;

const Popup = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  top: 0px;
  right: 0px;
  width: 260px;
  background: #152529;
  border: 2px solid #000000;
  box-sizing: border-box;
  box-shadow: inset 2px 0px 4px #143a46, inset 0px 2px 4px #168eb4;
  line-height: 12px;
  padding: 8px;

  & > div + div {
    margin-top: 8px;
  }
`;

const PopupHeader = styled.div`
  margin-bottom: 4px;
`;

const Name = styled.div`
  background: #344246;
  width: fit-content;
  padding: 4px 8px;
  margin-bottom: 12px;
`;

const Button = styled.button`
  font-family: 'GravityBold8';
  color: white;
  background: #074052;
  width: 100%;

  border: 1px solid #000000;
  padding: 8px 16px;
  margin-bottom: 8px;

  box-sizing: border-box;
  box-shadow: inset 2px 0px 2px #0f2e37, inset 0px 2px 2px #1b637a;
`;

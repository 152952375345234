import styled from '@emotion/styled';
import { useSmartphoneContext } from '~/contexts/smartphone';
import { ReactComponent as HomeSvg } from '../../../assets/icons/home.svg';
import { ReactComponent as PowerSvg } from '../../../assets/icons/power.svg';

export const SmartphoneHeader: React.FC = () => {
  const { closeSmartphone, currentApp, closeApp } = useSmartphoneContext();

  return (
    <SmartphoneHeaderContainer>
      <div onClick={() => closeSmartphone()} style={{ marginLeft: '12px' }}>
        <PowerIcon />
      </div>
      <div>{currentApp ? currentApp.app : 'peng phone'}</div>
      {currentApp ? (
        <div onClick={() => closeApp()} style={{ marginRight: '12px' }}>
          <HomeIcon />
        </div>
      ) : (
        <div></div>
      )}
    </SmartphoneHeaderContainer>
  );
};

const SmartphoneHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  width: 100%;
  font-size: 8px;
  box-sizing: border-box;
  padding: 4px 0px;

  background-color: #0f2c3b;
`;

const HomeIcon = styled(HomeSvg)`
  min-width: 12px;
  max-width: 12px;
  min-height: 12px;
  max-height: 12px;
  cursor: pointer;
`;

const PowerIcon = styled(PowerSvg)`
  min-width: 12px;
  max-width: 12px;
  min-height: 12px;
  max-height: 12px;
  cursor: pointer;
`;

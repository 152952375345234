import { GameScene } from '../GameScene';

export function redefineTilesetsPaths(this: GameScene) {
  this.tiledMap!.tilesets = this.tiledMap!.tilesets.map((tileSet) => {
    return {
      ...tileSet,
      image: tileSet.image
        ? tileSet.image.substring(tileSet.image.indexOf('assets'))
        : '',
    };
  });
}

export function loadTiledMapAndAssets(this: GameScene) {
  this.load.tilemapTiledJSON(this.gameSceneName, this.tiledMap);
  this.tiledMap!.tilesets.forEach((tileset) => {
    if (tileset && tileset.name && tileset.image) {
      this.load.image(tileset.name, tileset.image);
      this.tilesets.push({ name: tileset.name, path: tileset.image });
    }
  });
}

import styled from '@emotion/styled';
import { SmartphoneBottom } from './SmartphoneBottom';
import { SmartphoneHeader } from './SmartphoneHeader';
import BannierDesPengs from '../../../assets/images/BannieresdesPengz.jpg';
import { ReactComponent as SmartphoneSVG } from '../../../assets/icons/smartphone.svg';
import { MyPengsApp } from './apps/my-pengs/MyPengs';
import { useGameContext } from '~/contexts/game';
import { animated, useSpring, config } from 'react-spring';
import { useSmartphoneContext } from '~/contexts/smartphone';
import { App } from './types';
import { VisioApp } from './apps/visio/Visio';
import { VisioProvider } from '~/contexts/visio';
import { ContactApp } from './apps/contact/Contact';
import { SettingsApp } from './apps/settings/Settings';
import { InventoryApp } from './apps/inventory/Inventory';

export const Smartphone: React.FC = () => {
  const { currentPenguin, player } = useGameContext();
  const { currentApp, isOpen, openSmartphone } = useSmartphoneContext();

  const opening = useSpring({
    config: config.stiff,
    width: isOpen ? '300px' : '50px',
    height: isOpen ? '500px' : '50px',
    opacity: isOpen ? 1 : 0,
  });

  const iconAnim = useSpring({
    config: config.stiff,
    zIndex: isOpen ? -1 : 1,
    opacity: isOpen ? 0 : 1,
  });

  return (
    <>
      <ClosedIcon onClick={openSmartphone} style={{ ...iconAnim }}>
        <SmartphoneIcon />
      </ClosedIcon>
      <VisioProvider>
        <SmartphoneContainer style={{ ...opening }}>
          <SmartphoneHeader />
          {currentApp ? (
            <AppContainer>
              {currentApp.app === App.MY_PENGS_APP && (
                <MyPengsApp
                  penguins={player!.penguins}
                  currentPeng={currentPenguin!}
                />
              )}
              {currentApp.app === App.VISIO && (
                <VisioApp playerToCall={currentApp.appProps?.playerToCall} />
              )}
              {currentApp.app === App.CONTACT && (
                <ContactApp player={currentApp.appProps?.player} />
              )}
              {currentApp.app === App.SETTINGS && <SettingsApp />}
              {currentApp.app === App.INVENTORY && <InventoryApp />}
            </AppContainer>
          ) : (
            <>
              <SmartphoneBody />
              <SmartphoneBottom />
            </>
          )}
        </SmartphoneContainer>
      </VisioProvider>
    </>
  );
};

const SmartphoneContainer = styled(animated.div)`
  position: absolute;
  top: 120px;
  left: 25px;
  width: 300px;
  height: 500px;
  background: rgba(15, 44, 59, 0.44);
  border-radius: 6px;
  background-image: url(${BannierDesPengs});
  background-position: 64%;
  background-size: 500%;

  display: flex;
  flex-direction: column;

  font-family: 'GravityBold8';
  overflow: hidden;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const SmartphoneBody = styled.div`
  flex-grow: 1;
  width: 100%;
`;

const AppContainer = styled.div`
  width: 100%;
  height: calc(100% - 20px);
`;

const ClosedIcon = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0f2c3b;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 120px;
  left: 32px;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%),
    inset 4px 2px 3px rgb(162 162 162 / 35%);
`;

const SmartphoneIcon = styled(SmartphoneSVG)`
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
`;

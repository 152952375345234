import Phaser from 'phaser';
import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GameScene } from './phaser/game-scene/GameScene';
import { randomIntFromInterval } from '../../helpers/math';
import { Smartphone } from './smartphone/Smartphone';
import { Chat } from './chat/Chat';
import { useGameContext } from '~/contexts/game';
import LoadingImage from '~/assets/images/Loading.gif';
import { mapGenerator, Maps } from './phaser/game-scene/functions/mapGenerator';
import { IExitToEvent, IMusicEvent } from './phaser/types';
import { useSmartphoneContext } from '~/contexts/smartphone';
import { EmbededWebsite } from './embeded-website/EmbededWebsite';
import { useAudioContext } from '~/contexts/audio';
import { Menu } from './Menu';
import { Jitsi } from './jitsi/Jitsi';
import { useAuthContext } from '~/contexts/auth';
import { Twitch } from './twitch/Twitch';

export const Game: React.FC = () => {
  const { openApp } = useSmartphoneContext();

  const {
    currentPenguin,
    setCurrentPenguin,
    player,
    setCurrentMap,
    currentMap,
    loading: gameContextLoading,
  } = useGameContext();
  const { user } = useAuthContext();
  const { playLoadingMusic, stopLoadingMusic, playMusic, stopMusic } =
    useAudioContext();
  const [game, setGame] = useState<Phaser.Game | null>(null);
  const [twitchChannel, setTwitchChannel] = useState<string | null>(null);
  const [gameSceneLoading, setGameSceneLoading] = useState<boolean>(false);
  const gameContainer = useRef<HTMLDivElement>(null);
  const [config, setConfig] = useState<Phaser.Types.Core.GameConfig | null>(
    null,
  );

  const createGameScene = useCallback(
    async (
      mapName: Maps,
      coordinates: { x: number; y: number },
      gameSceneToRemove?: GameScene,
    ) => {
      setGameSceneLoading(true);
      playLoadingMusic();
      if (gameSceneToRemove && game) {
        game.scene.remove(gameSceneToRemove.gameSceneName);
      }
      if (game && currentPenguin && player) {
        const map = await mapGenerator(mapName);
        const gameScene = game.scene.add(
          mapName,
          new GameScene(mapName, currentPenguin.id, player, map),
          true,
          {
            startPosition: coordinates,
            openApp,
          },
        ) as GameScene;
        setCurrentMap(gameScene);
        gameScene.events.on(
          'playMusic',
          ({ musicName, source, audioLoop }: IMusicEvent) => {
            playMusic(source, musicName, audioLoop);
          },
        );
        gameScene.events.on('stopMusic', () => {
          stopMusic();
        });
        gameScene.events.on('createOver', () => {
          stopLoadingMusic();
          gameScene.initializePlayer(currentPenguin.id);
          setGameSceneLoading(false);
        });
        gameScene.events.on('startTwitch', (twitch: { channel: string }) => {
          setTwitchChannel(twitch.channel);
        });
        gameScene.events.on('stopTwitch', () => {
          setTwitchChannel(null);
        });
        gameScene.events.once(
          'exitTo',
          ({ newMapName, coordinates }: IExitToEvent) => {
            gameScene.room?.connection.close();
            createGameScene(newMapName, coordinates, gameScene);
            setTwitchChannel(null);
          },
        );
      }
    },
    [
      playLoadingMusic,
      game,
      currentPenguin,
      player,
      openApp,
      playMusic,
      stopMusic,
      stopLoadingMusic,
      setCurrentMap,
    ],
  );

  useEffect(() => {
    if (gameContainer.current) {
      setConfig({
        type: Phaser.AUTO,
        width: window.innerWidth,
        height: window.innerHeight,
        parent: gameContainer.current,
        roundPixels: true,
        antialias: false,
        physics: {
          default: 'arcade',
          arcade: {
            gravity: { y: 0 },
            // debug: true,
          },
        },
      });
    }
  }, [gameContainer]);

  useEffect(() => {
    if (player && user) {
      if (user.activeNft) {
        const initPeng = player.penguins.find(
          (p) => p.id === Number(user.activeNft!.tokenId),
        );
        if (initPeng) {
          setCurrentPenguin(initPeng);
          return;
        }
      }

      const pengIndex = randomIntFromInterval(0, player.penguins.length - 1);
      const initPeng = player.penguins[pengIndex];
      setCurrentPenguin(initPeng);
    }
  }, [player, user, setCurrentPenguin]);

  useEffect(() => {
    if (config && !game) {
      const _game = new Phaser.Game(config);
      setGame(_game);
      return () => {
        if (currentMap) {
          currentMap.disconnect();
          _game.destroy(true);
          setGame(null);
        }
      };
    }
  }, [config, currentMap, game]);

  useEffect(() => {
    if (game && currentPenguin && player && !currentMap && !gameSceneLoading) {
      createGameScene(Maps.ISLANDS, { x: 82 * 32, y: 76 * 32 });
    }
  }, [
    game,
    currentMap,
    createGameScene,
    gameSceneLoading,
    currentPenguin,
    player,
  ]);

  const loading = useMemo(() => {
    return gameContextLoading || gameSceneLoading;
  }, [gameContextLoading, gameSceneLoading]);

  return (
    <>
      <PhaserWrapper>
        <GameWrapper
          ref={gameContainer}
          onClick={() =>
            document?.activeElement &&
            (document?.activeElement as HTMLElement).blur()
          }
        />
        <EmbededWebsite gameScene={currentMap} />
        <Jitsi gameScene={currentMap} />
      </PhaserWrapper>

      {game && currentMap && player && currentPenguin ? (
        <Menu currentPenguin={currentPenguin} />
      ) : null}
      {player && currentMap ? <Chat player={player} /> : null}
      {player && currentMap && currentPenguin ? <Smartphone /> : null}
      {player && currentMap && currentPenguin && twitchChannel && (
        <Twitch viewportRef={gameContainer} channel={twitchChannel} />
      )}
      {loading ? <LoadingScreen src={LoadingImage} /> : null}
    </>
  );
};

const PhaserWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`;

const GameWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  justify-content: center;
`;

const LoadingScreen = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
`;

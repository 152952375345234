import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useGesture } from 'react-use-gesture';
import { GameScene } from '../phaser/game-scene/GameScene';
import { IOpenWebsiteEvent } from '../phaser/types';

const WEBSITE_INITIAL_WIDTH = 700;

type Props = { gameScene?: GameScene };
export const EmbededWebsite = ({ gameScene }: Props) => {
  const [websiteUrl, setWebsiteUrl] = useState<string | null>(null);

  const [{ width: previewWidth }, setPreviewWidth] = useSpring(
    () => ({
      width: websiteUrl ? WEBSITE_INITIAL_WIDTH : 0,
    }),
    [websiteUrl],
  );

  const [{ width: websiteWidth }, setWebsiteWidth] = useSpring(
    () => ({
      width: websiteUrl ? WEBSITE_INITIAL_WIDTH : 0,
    }),
    [websiteUrl],
  );

  const [dragging, setDragging] = useState(false);

  const bind = useGesture({
    onDrag: ({ movement: [mx], active }) => {
      setDragging(active);
      setPreviewWidth({ width: websiteWidth.get() - mx, immediate: active });
    },
    onDragEnd: ({ movement: [mx], active }) => {
      !active && setWebsiteWidth({ width: websiteWidth.get() - mx });
    },
  });

  useEffect(() => {
    gameScene?.events.on('openWebsite', ({ url }: IOpenWebsiteEvent) => {
      setWebsiteUrl(url);
    });

    gameScene?.events.on('closeWebsite', () => {
      setPreviewWidth({ width: 0 });
      setWebsiteWidth({ width: 0 });
      setTimeout(() => {
        setWebsiteUrl(null);
      }, 300);
    });
  });
  if (!gameScene || !websiteUrl) {
    return null;
  }
  return (
    <>
      <ScreenSeparator
        {...bind()}
        style={{
          transform: previewWidth.to((w) => `translateX(${-w}px)`),
        }}
      >
        <GrabHandle>
          <GrabHandleBar />
          <GrabHandleBar />
          <GrabHandleBar />
        </GrabHandle>
      </ScreenSeparator>
      <WebsiteWrapper
        style={{
          width: websiteWidth.to((w) => `${w}px`),
        }}
      >
        <Iframe title={websiteUrl} src={websiteUrl} />
      </WebsiteWrapper>
      <ResizePreview
        style={{
          width: previewWidth.to((w) => `${w}px`),
          display: dragging ? 'block' : 'none',
        }}
      />
    </>
  );
};

const WebsiteWrapper = styled(animated.div)`
  margin-left: 22px;
`;

const ResizePreview = styled(animated.div)`
  top: 0;
  right: 0;
  height: 100%;
  position: absolute;
  opacity: 0.5;
  background-color: #364e91;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

const ScreenSeparator = styled(animated.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  width: 22px;
  height: 100%;
  background-color: #587a8b;
  box-shadow: -1px -1px 1px 0px #ffffff;
`;

const GrabHandle = styled.div`
  display: flex;
`;

const GrabHandleBar = styled.div`
  margin: 0 1px;
  width: 3px;
  height: 16px;
  border: 1px solid #525252;
  box-shadow: inset -1px -1px 1px 0px #ffffff;
  border-radius: 2px;
`;

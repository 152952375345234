import { createContext, useContext, useEffect, useMemo } from 'react';
import { useCallback, useState } from 'react';
import Web3 from 'web3';

interface IMetamaskProvider {
  disconnectWeb3: () => void;
  connectToWeb3: () => Promise<{
    publicAddress?: string;
    web3?: Web3;
    connected: boolean;
    errors: Error[];
  }>;
  web3?: Web3;
  account?: string;
}

export const MetamaskContext = createContext<IMetamaskProvider | null>(null);

interface IMetamaskProviderProps {}

export const MetamaskProvider: React.FC<IMetamaskProviderProps> = ({
  children,
}) => {
  const [web3, setWeb3] = useState<Web3>();
  const [account, setAccount] = useState<string>();

  const connectToWeb3 = useCallback(async () => {
    if (window.ethereum) {
      //@ts-ignore
      const _web3 = new Web3(window.ethereum);
      const addresses = await _web3.eth.requestAccounts();
      if (addresses[0]) {
        setAccount(addresses[0]);
        setWeb3(_web3);
      }
      return {
        publicAddress: addresses[0],
        web3: _web3,
        connected: true,
        errors: !!addresses.length ? [new Error('Metamask not detected')] : [],
      };
    } else {
      return { connected: false, errors: [new Error('Metamask not detected')] };
    }
  }, []);

  const disconnectWeb3 = useCallback(() => {
    setAccount(undefined);
    setWeb3(undefined);
  }, []);

  const values: IMetamaskProvider = useMemo(
    () => ({
      disconnectWeb3,
      connectToWeb3,
      account,
      web3,
    }),
    [account, connectToWeb3, web3],
  );

  return (
    <MetamaskContext.Provider value={values}>
      {children}
    </MetamaskContext.Provider>
  );
};

export default function useMetamask() {
  const context = useContext(MetamaskContext);

  if (!context) {
    throw new Error(
      'useMetaMask hook must be used with a MetaMaskProvider component',
    );
  }

  return context as IMetamaskProvider;
}

import styled from '@emotion/styled';
import { ReactComponent as StarSVG } from '../../../../../..//assets/icons/Star.svg';
import { ReactComponent as ClothesSVG } from '../../../../../..//assets/icons/Clothes.svg';
import { ReactComponent as AppleSVG } from '../../../../../..//assets/icons/Apple.svg';
import { ReactComponent as TotemSVG } from '../../../../../..//assets/icons/Totem.svg';
import {
  createRef,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { animated, config, useSpring } from 'react-spring';

export enum Categories {
  STUFF = 'stuff',
  QUEST = 'quest',
  EDIBLE = 'edible',
  STARS = 'stars',
}

interface InventoryHeaderProps {
  onCategorySelect: (category: Categories) => void;
}

export const InventoryHeader: FC<InventoryHeaderProps> = ({
  onCategorySelect,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedCat, setSelectedCat] =
    useState<{ category: Categories; ref: React.RefObject<HTMLDivElement> }>();

  const categories = useMemo(() => {
    return [
      { cat: Categories.QUEST, element: <TotemIcon /> },
      { cat: Categories.STUFF, element: <ClothesIcon /> },
      { cat: Categories.EDIBLE, element: <AppleIcon /> },
      { cat: Categories.STARS, element: <StarIcon /> },
    ].map((item) => ({
      ref: createRef<HTMLDivElement>(),
      element: item.element,
      category: item.cat,
    }));
  }, []);

  const [sliderPosition, setSliderPosition] = useSpring(() => ({
    config: { ...config.stiff, mass: 0.5 },
    left: '0px',
  }));

  const updateSliderPosition = useCallback(() => {
    if (selectedCat) {
      setSliderPosition({
        left:
          selectedCat.ref.current && containerRef.current
            ? `${
                selectedCat.ref.current.offsetLeft -
                containerRef.current.offsetLeft
              }px`
            : '0px',
      });
    }
  }, [setSliderPosition, selectedCat]);

  useEffect(() => {
    if (selectedCat) {
      updateSliderPosition();
    }
  }, [selectedCat, updateSliderPosition]);

  return (
    <InventoryHeaderContainer ref={containerRef}>
      <CategoriesContainer>
        {categories.map(({ element, category, ref }) => {
          return (
            <IconContainer
              ref={ref}
              key={category}
              onClick={() => {
                onCategorySelect(category);
                setSelectedCat({ category, ref });
              }}
            >
              {element}
            </IconContainer>
          );
        })}
      </CategoriesContainer>
      <SliderContainer>
        <Slider style={sliderPosition} />
      </SliderContainer>
    </InventoryHeaderContainer>
  );
};

const InventoryHeaderContainer = styled.div`
  background-color: #0a1518cc;
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const CategoriesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;

const SliderContainer = styled.div`
  width: 100%;
  background-color: #dfdfdf3d;
`;

const Slider = styled(animated.div)`
  position: relative;
  margin-top: 1px;
  background-color: white;
  height: 2px;
  width: 25%;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
`;

const AppleIcon = styled(AppleSVG)`
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  cursor: pointer;
`;
const StarIcon = styled(StarSVG)`
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  cursor: pointer;
`;
const TotemIcon = styled(TotemSVG)`
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  cursor: pointer;
`;
const ClothesIcon = styled(ClothesSVG)`
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  cursor: pointer;
`;

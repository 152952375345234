import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const MessageFieldsFragmentDoc = gql`
    fragment MessageFields on Message {
  id
  message
  createdAt
  user {
    id
    publicAddress
    nickname
    activeNft {
      id
      tokenId
    }
  }
}
    `;
export const PlayerStateFieldsFragmentDoc = gql`
    fragment PlayerStateFields on PlayerState {
  id
  action
  direction
  x
  y
  active
  user {
    id
    nickname
    publicAddress
    activeNft {
      id
      tokenId
      spriteUrl
      nftContract {
        id
        blockchain
        contractAddress
      }
    }
  }
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  publicAddress
  nickname
  activeNft {
    id
    tokenId
    spriteUrl
    nftContract {
      id
      blockchain
      contractAddress
    }
  }
}
    `;
export const AddMessageDocument = gql`
    mutation AddMessage($message: String!, $conversationId: String!) {
  addMessage(
    addMessageInput: {message: $message, conversationId: $conversationId}
  ) {
    id
    message
    createdAt
  }
}
    `;
export type AddMessageMutationFn = Apollo.MutationFunction<Types.AddMessageMutation, Types.AddMessageMutationVariables>;

/**
 * __useAddMessageMutation__
 *
 * To run a mutation, you first call `useAddMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMessageMutation, { data, loading, error }] = useAddMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useAddMessageMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddMessageMutation, Types.AddMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddMessageMutation, Types.AddMessageMutationVariables>(AddMessageDocument, options);
      }
export type AddMessageMutationHookResult = ReturnType<typeof useAddMessageMutation>;
export type AddMessageMutationResult = Apollo.MutationResult<Types.AddMessageMutation>;
export type AddMessageMutationOptions = Apollo.BaseMutationOptions<Types.AddMessageMutation, Types.AddMessageMutationVariables>;
export const LoginDocument = gql`
    mutation Login($publicAddress: String!, $signature: String!) {
  login(publicAddress: $publicAddress, signature: $signature) {
    token
    user {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<Types.LoginMutation, Types.LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      publicAddress: // value for 'publicAddress'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<Types.LoginMutation, Types.LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LoginMutation, Types.LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<Types.LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<Types.LoginMutation, Types.LoginMutationVariables>;
export const SignoutDocument = gql`
    mutation Signout {
  signout {
    ok
  }
}
    `;
export type SignoutMutationFn = Apollo.MutationFunction<Types.SignoutMutation, Types.SignoutMutationVariables>;

/**
 * __useSignoutMutation__
 *
 * To run a mutation, you first call `useSignoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signoutMutation, { data, loading, error }] = useSignoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignoutMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignoutMutation, Types.SignoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignoutMutation, Types.SignoutMutationVariables>(SignoutDocument, options);
      }
export type SignoutMutationHookResult = ReturnType<typeof useSignoutMutation>;
export type SignoutMutationResult = Apollo.MutationResult<Types.SignoutMutation>;
export type SignoutMutationOptions = Apollo.BaseMutationOptions<Types.SignoutMutation, Types.SignoutMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: String!, $nickname: String, $nftId: String) {
  updateUser(updateUserInput: {id: $id, nickname: $nickname, nftId: $nftId}) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      nickname: // value for 'nickname'
 *      nftId: // value for 'nftId'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<Types.UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>;
export const ConversationDocument = gql`
    query Conversation($id: String!, $messagesLimit: Int!, $messagesAfter: String) {
  conversation(id: $id) {
    id
    name
    access
    messages(limit: $messagesLimit, after: $messagesAfter) {
      total
      items {
        ...MessageFields
      }
    }
  }
}
    ${MessageFieldsFragmentDoc}`;

/**
 * __useConversationQuery__
 *
 * To run a query within a React component, call `useConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      messagesLimit: // value for 'messagesLimit'
 *      messagesAfter: // value for 'messagesAfter'
 *   },
 * });
 */
export function useConversationQuery(baseOptions: Apollo.QueryHookOptions<Types.ConversationQuery, Types.ConversationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConversationQuery, Types.ConversationQueryVariables>(ConversationDocument, options);
      }
export function useConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConversationQuery, Types.ConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConversationQuery, Types.ConversationQueryVariables>(ConversationDocument, options);
        }
export type ConversationQueryHookResult = ReturnType<typeof useConversationQuery>;
export type ConversationLazyQueryHookResult = ReturnType<typeof useConversationLazyQuery>;
export type ConversationQueryResult = Apollo.QueryResult<Types.ConversationQuery, Types.ConversationQueryVariables>;
export function refetchConversationQuery(variables: Types.ConversationQueryVariables) {
      return { query: ConversationDocument, variables: variables }
    }
export const MeDocument = gql`
    query Me {
  me {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<Types.MeQuery, Types.MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MeQuery, Types.MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MeQuery, Types.MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MeQuery, Types.MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<Types.MeQuery, Types.MeQueryVariables>;
export function refetchMeQuery(variables?: Types.MeQueryVariables) {
      return { query: MeDocument, variables: variables }
    }
export const NftDocument = gql`
    query Nft($tokenId: String!) {
  nft(tokenId: $tokenId) {
    id
    tokenId
    spriteUrl
    metadata {
      image
      tokenId
      name
      generation
      description
      attributes {
        trait_type
        value
      }
    }
    nftContract {
      id
      blockchain
      contractAddress
    }
  }
}
    `;

/**
 * __useNftQuery__
 *
 * To run a query within a React component, call `useNftQuery` and pass it any options that fit your needs.
 * When your component renders, `useNftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNftQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useNftQuery(baseOptions: Apollo.QueryHookOptions<Types.NftQuery, Types.NftQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NftQuery, Types.NftQueryVariables>(NftDocument, options);
      }
export function useNftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NftQuery, Types.NftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NftQuery, Types.NftQueryVariables>(NftDocument, options);
        }
export type NftQueryHookResult = ReturnType<typeof useNftQuery>;
export type NftLazyQueryHookResult = ReturnType<typeof useNftLazyQuery>;
export type NftQueryResult = Apollo.QueryResult<Types.NftQuery, Types.NftQueryVariables>;
export function refetchNftQuery(variables: Types.NftQueryVariables) {
      return { query: NftDocument, variables: variables }
    }
export const NftMetadataDocument = gql`
    query NftMetadata($tokenId: String!) {
  nftMetadata(tokenId: $tokenId) {
    image
    tokenId
    name
    generation
    description
    attributes {
      trait_type
      value
    }
  }
}
    `;

/**
 * __useNftMetadataQuery__
 *
 * To run a query within a React component, call `useNftMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useNftMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNftMetadataQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useNftMetadataQuery(baseOptions: Apollo.QueryHookOptions<Types.NftMetadataQuery, Types.NftMetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NftMetadataQuery, Types.NftMetadataQueryVariables>(NftMetadataDocument, options);
      }
export function useNftMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NftMetadataQuery, Types.NftMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NftMetadataQuery, Types.NftMetadataQueryVariables>(NftMetadataDocument, options);
        }
export type NftMetadataQueryHookResult = ReturnType<typeof useNftMetadataQuery>;
export type NftMetadataLazyQueryHookResult = ReturnType<typeof useNftMetadataLazyQuery>;
export type NftMetadataQueryResult = Apollo.QueryResult<Types.NftMetadataQuery, Types.NftMetadataQueryVariables>;
export function refetchNftMetadataQuery(variables: Types.NftMetadataQueryVariables) {
      return { query: NftMetadataDocument, variables: variables }
    }
export const NonceDocument = gql`
    query Nonce($publicAddress: String!) {
  nonce(publicAddress: $publicAddress) {
    publicAddress
    nonce
  }
}
    `;

/**
 * __useNonceQuery__
 *
 * To run a query within a React component, call `useNonceQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonceQuery({
 *   variables: {
 *      publicAddress: // value for 'publicAddress'
 *   },
 * });
 */
export function useNonceQuery(baseOptions: Apollo.QueryHookOptions<Types.NonceQuery, Types.NonceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NonceQuery, Types.NonceQueryVariables>(NonceDocument, options);
      }
export function useNonceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NonceQuery, Types.NonceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NonceQuery, Types.NonceQueryVariables>(NonceDocument, options);
        }
export type NonceQueryHookResult = ReturnType<typeof useNonceQuery>;
export type NonceLazyQueryHookResult = ReturnType<typeof useNonceLazyQuery>;
export type NonceQueryResult = Apollo.QueryResult<Types.NonceQuery, Types.NonceQueryVariables>;
export function refetchNonceQuery(variables: Types.NonceQueryVariables) {
      return { query: NonceDocument, variables: variables }
    }
export const UserConversationsDocument = gql`
    query UserConversations($id: String!) {
  user(id: $id) {
    id
    conversations {
      id
      name
      access
    }
  }
}
    `;

/**
 * __useUserConversationsQuery__
 *
 * To run a query within a React component, call `useUserConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserConversationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserConversationsQuery(baseOptions: Apollo.QueryHookOptions<Types.UserConversationsQuery, Types.UserConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserConversationsQuery, Types.UserConversationsQueryVariables>(UserConversationsDocument, options);
      }
export function useUserConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserConversationsQuery, Types.UserConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserConversationsQuery, Types.UserConversationsQueryVariables>(UserConversationsDocument, options);
        }
export type UserConversationsQueryHookResult = ReturnType<typeof useUserConversationsQuery>;
export type UserConversationsLazyQueryHookResult = ReturnType<typeof useUserConversationsLazyQuery>;
export type UserConversationsQueryResult = Apollo.QueryResult<Types.UserConversationsQuery, Types.UserConversationsQueryVariables>;
export function refetchUserConversationsQuery(variables: Types.UserConversationsQueryVariables) {
      return { query: UserConversationsDocument, variables: variables }
    }
export const OnMessageDocument = gql`
    subscription OnMessage($conversationId: String!) {
  onMessage(conversationId: $conversationId) {
    ...MessageFields
  }
}
    ${MessageFieldsFragmentDoc}`;

/**
 * __useOnMessageSubscription__
 *
 * To run a query within a React component, call `useOnMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnMessageSubscription({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useOnMessageSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.OnMessageSubscription, Types.OnMessageSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.OnMessageSubscription, Types.OnMessageSubscriptionVariables>(OnMessageDocument, options);
      }
export type OnMessageSubscriptionHookResult = ReturnType<typeof useOnMessageSubscription>;
export type OnMessageSubscriptionResult = Apollo.SubscriptionResult<Types.OnMessageSubscription>;
export const OnUserUpdatedDocument = gql`
    subscription OnUserUpdated {
  onUserUpdated {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useOnUserUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnUserUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUserUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUserUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnUserUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Types.OnUserUpdatedSubscription, Types.OnUserUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.OnUserUpdatedSubscription, Types.OnUserUpdatedSubscriptionVariables>(OnUserUpdatedDocument, options);
      }
export type OnUserUpdatedSubscriptionHookResult = ReturnType<typeof useOnUserUpdatedSubscription>;
export type OnUserUpdatedSubscriptionResult = Apollo.SubscriptionResult<Types.OnUserUpdatedSubscription>;
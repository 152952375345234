import Biomes from '../../assets/images/Biomes.png';
import TotemsImage from '../../assets/images/totems.jpeg';
import HappyPengImage from '../../assets/images/HappyPeng.png';
import { browserName } from 'react-device-detect';
import styled from '@emotion/styled';
import { FC, useContext } from 'react';
import { HeaderContext } from '../Application';
import { css } from '@emotion/react';

export const ChooseTribe: FC = () => {
  const headerHeight = useContext(HeaderContext);
  return (
    <ChooseTribeContainer
      style={{
        height: `calc(100vh - ${headerHeight.height}px)`,
      }}
    >
      <TotemsContainer>
        <Title useNeon={browserName !== 'Chrome' ? 1 : 0}>
          choose your tribe and defend your quote of arms
        </Title>
        <Totems />
      </TotemsContainer>
      <Caption>
        <HappyPeng />
        <Text>
          <div>
            games, quests,Tribe war, conferences and events to provide
            entertainment!
          </div>
          <br />
          <div>
            enjoy yourself and have a good time with our amazing community.
          </div>
        </Text>
      </Caption>
    </ChooseTribeContainer>
  );
};

const ChooseTribeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'GravityBold8';
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: url(${Biomes}) no-repeat center center fixed;
  background-size: 100% 100%;
  height: 100%;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    background: url(${Biomes}) no-repeat center center;
    background-size: auto 100%;
    background-position: 50%;
    margin-top: 0px;
    /* padding: 32px 0px; */
    box-sizing: border-box;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    flex-direction: column;
    background: url(${Biomes}) no-repeat center center;
    background-size: auto 100%;
    background-position: 50%;
    margin-top: 0px;
    /* padding: 32px 0px; */
    box-sizing: border-box;
  }
`;

const Caption = styled.div`
  position: relative;
  width: 40%;
  height: fit-content;
  background: #031920;
  border: 5px solid #000000;
  box-sizing: border-box;
  border-radius: 35px;
  font-size: 24px;
  line-height: 38px;
  display: flex;
  text-align: start;
  align-items: start;
  justify-content: start;

  padding: 16px 16px;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    padding-top: 20px;
    height: fit-content;
    width: 90%;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    font-size: 12px;
    padding-top: 20px;
    height: fit-content;
    width: 90%;
  }
`;

const Text = styled.div`
  border: 4px solid #ffffff;
  padding: 32px 24px;
  @media only screen and (max-width: 600px) {
    padding: 6px 14px;
    font-size: 10px;
    line-height: 16px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    padding: 6px 14px;
    font-size: 10px;
  }
`;

const TotemsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 600px;
  height: 600px;
  background: #031920;
  border: 3px solid #000000;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 32px 16px;
  animation: float 5s ease-in-out infinite;

  @media only screen and (max-width: 600px) {
    width: 90%;
    height: 300px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    width: 80%;
    height: 600px;
  }

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }
`;

const Totems = styled.div`
  background: url(${TotemsImage}) no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100%;
  border: 4px solid #ffffff;
  @media only screen and (max-width: 600px) {
    background-position: center;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    background-position: center;
  }
`;

const Title = styled.div<{ useNeon: 1 | 0 }>`
  position: absolute;
  box-sizing: border-box;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  top: -60px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 0px;
  letter-spacing: -2px;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #157e8c;
  ${({ useNeon }) =>
    useNeon
      ? css`
          animation: light 4s infinite linear;
        `
      : null}
  color: white;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 42px #157e8c, 0 0 77px #157e8c,
    0 0 100px #157e8c;
  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 26px;
    top: -30px;
    -webkit-text-stroke-width: 1px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    font-size: 16px;
    line-height: 26px;
    top: -30px;
    -webkit-text-stroke-width: 1px;
  }
  @media only screen and (max-width: 375px) and (max-height: 667px) {
    font-size: 14px;
    line-height: 20px;
    top: -30px;
    -webkit-text-stroke-width: 1px;
  }

  @keyframes light {
    0% {
      text-shadow: 0 0 7px #fff, 0 0 10px #157e8c, 0 0 42px #157e8c,
        0 0 77px #157e8c, 0 0 100px #157e8c;
    }
    25% {
      text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 32px #157e8c,
        0 0 87px #157e8c, 0 0 120px #157e8c;
    }
    50% {
      text-shadow: 0 0 7px #fff, 0 0 10px #157e8c, 0 0 32px #157e8c,
        0 0 87px #157e8c, 0 0 120px #157e8c;
    }
    75% {
      text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 32px #157e8c,
        0 0 87px #157e8c, 0 0 120px #157e8c;
    }
    90% {
      text-shadow: -4px 4px 0px #157e8c, 4px 4px 0px #157e8c,
        4px 4px 0px #157e8c, 4px 4px 0px #157e8c;
    }
  }
`;

const HappyPeng = styled.div`
  background-image: url(${HappyPengImage});
  position: absolute;
  width: 300px;
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  top: -220px;
  left: 220px;
  margin-left: auto;
  margin-right: auto;
  filter: drop-shadow(10px 10px 4px rgba(0, 0, 0, 0.25));
  @media only screen and (max-width: 600px) {
    width: 200px;
    height: 200px;
    top: -160px;
    left: 100px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    width: 200px;
    height: 200px;
    top: -160px;
    left: 220px;
  }
  @media only screen and (max-width: 375px) and (max-height: 667px) {
    width: 160px;
    height: 160px;
    top: -120px;
    left: 100px;
  }
`;

import { useState, useCallback } from 'react';
import { createGenericContext } from '~/helpers/createGenericContext';
import { App } from '~/modules/game/smartphone/types';

type SmartphoneContextType = {
  openApp: (app: App, appProps?: any) => void;
  closeApp: () => void;
  openSmartphone: () => void;
  closeSmartphone: () => void;
  isOpen: boolean;
  currentApp: { app: App; appProps?: any } | null;
};

export const [useSmartphoneContext, SmartphoneContextProvider] =
  createGenericContext<SmartphoneContextType>();

type Props = {
  children: React.ReactNode;
};

export const SmartphoneProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [currentApp, setCurrentApp] = useState<{
    app: App;
    appProps?: any;
  } | null>(null);

  const openApp = useCallback((app: App, appProps?: any) => {
    setCurrentApp({ app, appProps });
    setIsOpen(true);
  }, []);

  const closeApp = useCallback(() => {
    setCurrentApp(null);
  }, []);

  const openSmartphone = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeSmartphone = useCallback(() => {
    setCurrentApp(null);
    setIsOpen(false);
  }, []);

  return (
    <SmartphoneContextProvider
      value={{
        openApp,
        closeApp,
        openSmartphone,
        closeSmartphone,
        isOpen,
        currentApp,
      }}
    >
      {children}
    </SmartphoneContextProvider>
  );
};

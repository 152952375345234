import { Pane } from 'evergreen-ui';
import styled from '@emotion/styled';

import { Landing } from './Landing';
import { HeaderContext } from '../Application';
import { useContext } from 'react';
import { Teaser } from './Teaser';
import { NftSeparator } from './NftSeparator';
import { CaptionAndVideo } from './CaptionAndVideo';
import { ChooseTribe } from './ChooseTribe';
import { JoinCommunity } from './JoinCommunity';
import { Team } from './Team';

export const Home: React.FC = () => {
  const headerHeight = useContext(HeaderContext);
  return (
    <HomeContainer
      style={{
        height: `calc(100vh - ${headerHeight.height}px)`,
      }}
    >
      <Landing />
      <Teaser />
      <NftSeparator />
      <CaptionAndVideo />
      <ChooseTribe />
      <Team />
      <JoinCommunity />
    </HomeContainer>
  );
};

const HomeContainer = styled(Pane)`
  width: 100%;
  height: 100vh;
  overflow: overlay;

  /* ----------- Non-Retina Screens ----------- */
  @media screen and (min-device-width: 1200px) and (max-device-width: 1920px) and (-webkit-min-device-pixel-ratio: 1) {
    /* background-size: 120%;
    background-position-x: right;     */
  }

  /* ----------- Retina Screens ----------- */
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media only screen and (max-width: 600px) {
    padding: 0px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    padding: 0px;
  }
`;

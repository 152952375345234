import { ReactComponent as Speech } from '../../../assets/icons/speech.svg';
import { ReactComponent as TopArrow } from '../../../assets/icons/Top-arrow.svg';
import styled from '@emotion/styled';
import { FC, useEffect, useMemo, useState } from 'react';
import { Message } from './Message';
import { useInView } from 'react-intersection-observer';
import { Player } from '~/models/player';
import {
  useUserConversationsQuery,
  useConversationQuery,
  useOnMessageSubscription,
  useAddMessageMutation,
  useOnUserUpdatedSubscription,
} from '~/generated/graphql/hooks';
import { useAuthContext } from '~/contexts/auth';
import { ConversationAccess } from '~/generated/graphql/types';
import { MessageFieldsFragment } from '~/generated/graphql/operations';
import { useGameContext } from '~/contexts/game';
import { animated, config, useSpring } from 'react-spring';

enum ChatSizes {
  CLOSED = 'closed',
  NORMAL = 'normal',
  LARGE = 'large',
}

interface IChatProps {
  player: Player;
}

export const Chat: FC<IChatProps> = ({ player }) => {
  const [subScibtionMessages, setSubscribptionMessages] = useState<
    MessageFieldsFragment[]
  >([]);

  const [isOpen, setIsOpen] = useState(true);
  const [chatSize, setChatSize] = useState<ChatSizes>(ChatSizes.NORMAL);

  const opening = useSpring({
    config: config.stiff,
    height: isOpen ? (chatSize === ChatSizes.NORMAL ? '25%' : '40%') : '4%',
  });

  const showChat = useSpring({
    config: config.stiff,
    opacity: isOpen ? 1 : 0,
    zIndex: isOpen ? 1 : -1,
  });

  const minimized = useSpring({
    config: config.stiff,
    opacity: isOpen ? 0 : 1,
    zIndex: isOpen ? -1 : 1,
  });

  const [unreadMessageCounter, setUnreadMessageCounter] = useState(0);

  const [message, setMessage] = useState('');

  const { user } = useAuthContext();

  const [addMessage] = useAddMessageMutation();

  const { ref, inView } = useInView();

  const { currentMap } = useGameContext();

  const { data: userConversationsData } = useUserConversationsQuery({
    variables: { id: user?.id ?? '' },
    skip: !user,
  });

  const generalConversation = useMemo(() => {
    return userConversationsData?.user.conversations.find(
      (conversation) =>
        conversation.access === ConversationAccess.Public &&
        conversation.name === 'General Channel',
    );
  }, [userConversationsData?.user.conversations]);

  const { data: conversationData, fetchMore } = useConversationQuery({
    variables: {
      id: generalConversation?.id ?? '',
      messagesLimit: 20,
    },
    skip: !generalConversation,
  });

  useOnUserUpdatedSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      console.log('subscriptionData');
    },
  });

  useOnMessageSubscription({
    variables: { conversationId: generalConversation?.id ?? '' },
    skip: !generalConversation,
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data) {
        setSubscribptionMessages([
          subscriptionData.data?.onMessage,
          ...subScibtionMessages,
        ]);
        if (!isOpen) {
          setUnreadMessageCounter(unreadMessageCounter + 1);
        }
      }
    },
  });

  useEffect(() => {
    if (isOpen) {
      setUnreadMessageCounter(0);
    }
  }, [isOpen]);

  useEffect(() => {
    const messages = conversationData?.conversation.messages.items;
    if (messages?.length && inView) {
      fetchMore({
        variables: {
          messagesLimit: 20,
          messagesAfter: messages[messages.length - 1].id,
        },
      });
    }
  }, [conversationData?.conversation.messages.items, fetchMore, inView]);

  return (
    <ChatContainer style={{ ...opening }}>
      <Minimized style={{ ...minimized }}>
        <SpeechIcon />
        <TopArrowIcon onClick={() => setIsOpen(true)} />
        <UnreadMessage messageNumber={unreadMessageCounter}>
          {unreadMessageCounter}
        </UnreadMessage>
      </Minimized>
      <ChatView style={{ ...showChat }}>
        <SizeButton
          style={{ left: '50%' }}
          onClick={() => {
            setChatSize(ChatSizes.NORMAL);
            if (chatSize === ChatSizes.NORMAL) {
              setIsOpen(false);
            }
          }}
        >
          -
        </SizeButton>
        <SizeButton
          style={{ left: '56%' }}
          onClick={() => {
            setChatSize(ChatSizes.LARGE);
          }}
        >
          {' '}
          +{' '}
        </SizeButton>
        <Channels>
          <Channel isOpen={isOpen ? 1 : 0}> General </Channel>
        </Channels>
        <MessagingContainer>
          <SpeechView>
            <Messages>
              {subScibtionMessages.map((message) => (
                <Message
                  key={message.id}
                  externalMessage={
                    player.account.address !== message.user.publicAddress
                  }
                  message={message.message}
                  sender={message.user}
                />
              ))}
              {conversationData?.conversation.messages.items.map((message) => (
                <Message
                  key={message.id}
                  externalMessage={
                    player.account.address !== message.user.publicAddress
                  }
                  message={message.message}
                  sender={message.user}
                />
              ))}
              <div ref={ref} />
            </Messages>
          </SpeechView>
          <Actions>
            {generalConversation && (
              <InputForm
                isOpen={isOpen ? 1 : 0}
                onSubmit={(e) => {
                  e.preventDefault();
                  addMessage({
                    variables: {
                      conversationId: generalConversation?.id,
                      message,
                    },
                  });
                  setMessage('');
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <TyperField
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onFocus={() => currentMap?.lockKeys()}
                    onBlur={() => currentMap?.unLockKeys()}
                  />
                </div>
              </InputForm>
            )}
          </Actions>
        </MessagingContainer>
      </ChatView>
    </ChatContainer>
  );
};

const ChatContainer = styled(animated.div)`
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 25%;
  width: 34%;
`;

const ChatView = styled(animated.div)`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

const Minimized = styled(animated.div)`
  background-color: #161616d1;
  border-top-right-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  position: absolute;
  padding: 0px 8px;
`;

const MessagingContainer = styled.div`
  background-color: #161616d1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  height: 100%;
  width: 100%;
  border-top-right-radius: 6px;
`;

const SpeechView = styled.div`
  display: flex;
  flex-direction: column-reverse;

  direction: rtl;

  width: 100%;
  height: 100%;

  overflow-y: scroll;
  overflow-x: hidden;
`;

const Actions = styled.div`
  display: flex;
  width: 100%;
`;

const TyperField = styled.input`
  background-color: #5d5d5db0;
  border-width: 0px;
  border-radius: 10px;
  height: 34px;
  width: calc(100%);
  color: white;
  font-size: 14px;
  padding-left: 8px;

  :focus {
    outline-color: white;
    outline-width: 1px;
  }
  :focus-visible {
    outline-style: solid;
    outline-color: white;
    outline-width: 1px;
  }
`;

const Messages = styled.div`
  direction: ltr;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: center;
  padding-left: 16px;
  padding-top: 16px;
`;

const InputForm = styled.form<{ isOpen: 0 | 1 }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ isOpen }) => (isOpen ? '8px 16px' : '0px')};
`;

const Channels = styled.div`
  font-family: 'GravityBold8';
  background-color: #1e1e1ef0;
  width: 30%;
  font-size: 8px;
`;

const Channel = styled.div<{ isOpen: 0 | 1 }>`
  background-color: #1e1e1e;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ isOpen }) => (isOpen ? '50px' : '0px')};
`;

const SizeButton = styled.div`
  font-family: 'GravityBold8';
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #063345;
  top: -13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
`;

const SpeechIcon = styled(Speech)`
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  * {
    fill: white;
  }
`;

const TopArrowIcon = styled(TopArrow)`
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  * {
    fill: white;
  }
  cursor: pointer;
`;

const UnreadMessage = styled.div<{ messageNumber: number }>`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${({ messageNumber }) =>
    messageNumber > 0 ? '#ff9292' : '#7ddf6e'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
`;

import { useEffect } from 'react';

export const useClickOutside = <T extends HTMLElement>(
  componentRef: React.RefObject<T>,
  onClick: (ref: React.RefObject<T>) => void,
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        onClick(componentRef);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

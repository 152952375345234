import axios from 'axios';
import { ITiledMap } from '../../types';

const MapApesJSON = 'https://storage.googleapis.com/peng-maps/Apes.json';
const MapAvalaunchJSON =
  'https://storage.googleapis.com/peng-maps/Avalaunch.json';
const MapAvawareJSON = 'https://storage.googleapis.com/peng-maps/Avaware.json';
const MapAvaxNftJSON = 'https://storage.googleapis.com/peng-maps/AvaxNFT.json';
const MapAvaxRarityJSON =
  'https://storage.googleapis.com/peng-maps/Avaxrarity.json';
const MapAventuresDaoJSON =
  'https://storage.googleapis.com/peng-maps/AventuresDAO.json';
const MapBenqiJSON = 'https://storage.googleapis.com/peng-maps/Benqi.json';
const MapColonyJSON = 'https://storage.googleapis.com/peng-maps/Colony.json';
const MapConferenceJSON =
  'https://storage.googleapis.com/peng-maps/conference.json';
const MapCrabadaJSON = 'https://storage.googleapis.com/peng-maps/Crabada.json';
const MapForestJSON = 'https://storage.googleapis.com/peng-maps/forest.json';
const MapGoodBridgingGJSON =
  'https://storage.googleapis.com/peng-maps/GoodBridging.json';
const MapIslandsJSON = 'https://storage.googleapis.com/peng-maps/islands.json';
const MapKalaoJSON = 'https://storage.googleapis.com/peng-maps/Kalao.json';
const MapPangolinJSON =
  'https://storage.googleapis.com/peng-maps/pangolin.json';
const MapPefiJSON = 'https://storage.googleapis.com/peng-maps/PeFi.json';
const MapRoomCloudJSON =
  'https://storage.googleapis.com/peng-maps/RoomCloud.json';
const MapRoomIceFloeJSON =
  'https://storage.googleapis.com/peng-maps/RoomIcefloe.json';
const MapRyuJSON = 'https://storage.googleapis.com/peng-maps/Ryu.json';
const MapTraderJoeJSON =
  'https://storage.googleapis.com/peng-maps/traderjoe.json';
const MapVolcanoJSON = 'https://storage.googleapis.com/peng-maps/Volcano.json';
const MapApaJSON = 'https://storage.googleapis.com/peng-maps/APA.json';
const MapEmbrJSON = 'https://storage.googleapis.com/peng-maps/Embr.json';
const MapMemeLordHallJSON = 'https://storage.googleapis.com/peng-maps/MemeLordHall.json';
const MapPengClubJSON = 'https://storage.googleapis.com/peng-maps/PengClub.json';

export enum Maps {
  APES = 'apes',
  AVALAUNCH = 'avalaunch',
  AVAWARE = 'avaware',
  AVAX_NFT = 'avaxNFT',
  AVAX_RARITY = 'avaxRarity',
  AVENTURES_DAO = 'aventuresDAO',
  BENQI = 'benqi',
  COLONY = 'colony',
  CONFERENCE = 'conference',
  CRABADA = 'crabada',
  FOREST = 'forest',
  GOOD_BRIDGING = 'goodBridging',
  ISLANDS = 'islands',
  KALAO = 'kalao',
  PANGOLIN = 'pangolin',
  PEFI = 'pefi',
  ROOM_CLOUD = 'roomCloud',
  ROOM_ICE_FLOE = 'roomIceFloe',
  RYU = 'ryu',
  TRADER_JOE = 'traderJoe',
  VOLCANO = 'volcano',
  APA = 'apa',
  EMBR = 'embr',
  MEME_LORD_HALL = 'memeLordHall',
  PENG_CLUB= 'pengClub',
}

const getMap = async (mapUrl: string) => {
  return (await axios.get(mapUrl)).data;
};

export async function mapGenerator(gameSceneName: Maps) {
  let jsonMapUrl: unknown;
  switch (gameSceneName) {
    case Maps.APES:
      jsonMapUrl = await getMap(MapApesJSON);
      break;
    case Maps.AVALAUNCH:
      jsonMapUrl = await getMap(MapAvalaunchJSON);
      break;
    case Maps.AVAWARE:
      jsonMapUrl = await getMap(MapAvawareJSON);
      break;
    case Maps.AVAX_NFT:
      jsonMapUrl = await getMap(MapAvaxNftJSON);
      break;
    case Maps.AVAX_RARITY:
      jsonMapUrl = await getMap(MapAvaxRarityJSON);
      break;
    case Maps.AVENTURES_DAO:
      jsonMapUrl = await getMap(MapAventuresDaoJSON);
      break;
    case Maps.BENQI:
      jsonMapUrl = await getMap(MapBenqiJSON);
      break;
    case Maps.COLONY:
      jsonMapUrl = await getMap(MapColonyJSON);
      break;
    case Maps.CONFERENCE:
      jsonMapUrl = await getMap(MapConferenceJSON);
      break;
    case Maps.CRABADA:
      jsonMapUrl = await getMap(MapCrabadaJSON);
      break;
    case Maps.FOREST:
      jsonMapUrl = await getMap(MapForestJSON);
      break;
    case Maps.GOOD_BRIDGING:
      jsonMapUrl = await getMap(MapGoodBridgingGJSON);
      break;
    case Maps.ISLANDS:
      jsonMapUrl = await getMap(MapIslandsJSON);
      break;
    case Maps.KALAO:
      jsonMapUrl = await getMap(MapKalaoJSON);
      break;
    case Maps.PANGOLIN:
      jsonMapUrl = await getMap(MapPangolinJSON);
      break;
    case Maps.PEFI:
      jsonMapUrl = await getMap(MapPefiJSON);
      break;
    case Maps.ROOM_CLOUD:
      jsonMapUrl = await getMap(MapRoomCloudJSON);
      break;
    case Maps.ROOM_ICE_FLOE:
      jsonMapUrl = await getMap(MapRoomIceFloeJSON);
      break;
    case Maps.RYU:
      jsonMapUrl = await getMap(MapRyuJSON);
      break;
    case Maps.TRADER_JOE:
      jsonMapUrl = await getMap(MapTraderJoeJSON);
      break;
    case Maps.VOLCANO:
      jsonMapUrl = await getMap(MapVolcanoJSON);
      break;
    case Maps.APA:
      jsonMapUrl = await getMap(MapApaJSON);
      break;
    case Maps.EMBR:
      jsonMapUrl = await getMap(MapEmbrJSON);
      break;
      case Maps.MEME_LORD_HALL:
        jsonMapUrl = await getMap(MapMemeLordHallJSON);
        break;
      case Maps.PENG_CLUB:
        jsonMapUrl = await getMap(MapPengClubJSON);
        break;
    default:
      throw new Error(`Hm this map does not exist: ${gameSceneName}`);
  }
  const tiledMap = jsonMapUrl as ITiledMap;
  return tiledMap;
}

import styled from '@emotion/styled';
import { useAudioContext } from '~/contexts/audio';
import { AudioController } from './AudioController';

export const AudioSettings = () => {
  const { volume, setVolume, effectVolume, setEffectVolume } =
    useAudioContext();
  return (
    <Container>
      <AudioController
        style={{ marginBottom: '16px' }}
        title="music"
        defaultValue={volume}
        onChange={(newValue) => {
          setVolume(newValue);
        }}
      />
      <AudioController
        style={{ marginBottom: '16px' }}
        title="effects"
        defaultValue={effectVolume}
        onChange={(newValue) => {
          setEffectVolume(newValue);
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

import { FieldPolicy, StoreObject } from '@apollo/client';

export enum AgregationOrder {
  ADD_AT_TOP,
  ADD_AT_BOTTOM,
}

export const replace = (): FieldPolicy => ({
  merge(_: StoreObject[], incoming: StoreObject[]) {
    return incoming;
  },
});

export const agregate = (
  order: AgregationOrder,
  keyArgs?: false | string[],
): FieldPolicy => ({
  keyArgs,
  merge(existing: StoreObject[], incoming: StoreObject[]) {
    if (existing) {
      return order === AgregationOrder.ADD_AT_BOTTOM
        ? existing.concat(incoming)
        : incoming.concat(existing);
    }
    return incoming;
  },
});

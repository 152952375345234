import styled from '@emotion/styled';
import { FC } from 'react';
import { ReactComponent as LeftArrowSvg } from '../../../../../assets/icons/leftArrow.svg';
import { PlayerState } from '~/generated/graphql/types';
import { Button } from '~/components/button/Button';
import { useSmartphoneContext } from '~/contexts/smartphone';
import { App } from '../../types';
import { usePenguin } from '~/hooks/use-penguin';
import { ReactComponent as AcceptCallSVG } from '~/assets/icons/acceptCall.svg';
import { Player } from '~/generated/colyseus/Player';

type Props = {
  player: Player;
};

export const ContactApp: FC<Props> = ({ player }) => {
  const { openApp, closeApp } = useSmartphoneContext();
  const [penguin] = usePenguin(player.nftId);
  return (
    <Wrapper>
      <Header>
        <LeftArrowIcon onClick={closeApp} />
      </Header>
      <Content>
        {penguin && (
          <div>
            <ProfilePicture src={penguin?.image} />
          </div>
        )}
        {player.nickname ??
          `${player.publicAddress.substring(
            0,
            5,
          )}...${player.publicAddress.substring(
            player.publicAddress.length - 5,
          )}`}
        <Button
          onClick={() => {
            openApp(App.VISIO, { playerToCall: player });
          }}
        >
          <CallIcon />
        </Button>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #0b1a22;
`;

const Content = styled.div`
  display: grid;
  grid-template-rows: min-content min-content min-content min-content;
  gap: 30px;
  padding: 50px 0;
  justify-content: center;
`;

const ProfilePicture = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const Header = styled.div`
  display: flex;
  height: 20px;
  width: 100%;
`;

const LeftArrowIcon = styled(LeftArrowSvg)`
  min-width: 18px;
  max-width: 18px;
  min-height: 18px;
  max-height: 18px;
  cursor: pointer;
  margin: 5px;
`;

const CallIcon = styled(AcceptCallSVG)`
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
`;

import { Player } from '~/models/player';
import { GameScene } from '../GameScene';
import * as helpers from './helpers';
import { getLayerProperty } from './updateTools';

export function layerWatcher(this: GameScene) {
  const layers = this.tileMap?.layers.filter((l) =>
    l.name.includes('door-opener/'),
  );

  layers?.forEach((layerToWatch) => {
    this.layersToWatch.set(layerToWatch.name, {
      onEnter: (l) => {
        const hasCondition = getLayerProperty(l, 'condition');
        if (
          !hasCondition ||
          (hasCondition && validateCondition.bind(this)(hasCondition.value))
        ) {
          l.properties.forEach((p: any) => {
            if (p.name === 'layersToShow') {
              const layersToHide = (p.value as string).split(';');
              layersToHide.forEach(helpers.showLayer.bind(this));
            } else if (p.name === 'collidesToRemove') {
              const layersToUncollide = (p.value as string).split(';');
              layersToUncollide.forEach(helpers.removeCollides.bind(this));
            } else if (p.name === 'layersToHides') {
              const layersToHide = (p.value as string).split(';');
              layersToHide.forEach(helpers.hideLayer.bind(this));
            }
          });
        }
      },
      onLeave: (l) => {
        l.properties.forEach((p: any) => {
          if (p.name === 'layersToShow') {
            const layersToHide = (p.value as string).split(';');
            layersToHide.forEach(helpers.hideLayer.bind(this));
          } else if (p.name === 'collidesToRemove') {
            const layersTocollide = (p.value as string).split(';');
            layersTocollide.forEach(helpers.addCollides.bind(this));
          } else if (p.name === 'layersToHides') {
            const layersToHide = (p.value as string).split(';');
            layersToHide.forEach(helpers.showLayer.bind(this));
          }
        });
      },
    });
  });
}

enum Conditions {
  HAS_LAVA_TOTEM = 'hasLavaTotem',
  HAS_ICE_TOTEM = 'hasIceTotem',
  HAS_JUNGLE_TOTEM = 'hasJungleTotem',
  HAS_STORM_TOTEM = 'hasStormTotem',
}

enum Totems {
  VOLCANO = 'volcano',
  STORM = 'storm',
  ICE = 'ice',
  JUNGLE = 'jungle',
}

const hasTotem = (player: Player, totemType: Totems) => {
  return (
    player?.inventory.keyItems.find((keyItem) =>
      keyItem.name.toLowerCase().includes(totemType),
    ) !== undefined
  );
};

function validateCondition(this: GameScene, condition: string) {
  let isValidated = false;
  switch (condition) {
    case Conditions.HAS_LAVA_TOTEM:
      isValidated = hasTotem(this.player!, Totems.VOLCANO);
      break;
    case Conditions.HAS_JUNGLE_TOTEM:
      isValidated = hasTotem(this.player!, Totems.JUNGLE);
      break;
    case Conditions.HAS_STORM_TOTEM:
      isValidated = hasTotem(this.player!, Totems.STORM);
      break;
    case Conditions.HAS_ICE_TOTEM:
      isValidated = hasTotem(this.player!, Totems.ICE);
      break;
    default:
      break;
  }
  return isValidated;
}

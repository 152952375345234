import styled from '@emotion/styled';
import { useEffect, useRef } from 'react';
import { useSmartphoneContext } from '~/contexts/smartphone';
import { useVisioContext } from '~/contexts/visio';
import { ReactComponent as AcceptCallSVG } from '~/assets/icons/acceptCall.svg';
import { ReactComponent as RefuseCallSVG } from '~/assets/icons/refuseCall.svg';
import { ReactComponent as CameraSVG } from '~/assets/icons/camera.svg';
import { ReactComponent as MicrophoneSVG } from '~/assets/icons/microphone.svg';
import { ReactComponent as SpeackerSVG } from '~/assets/icons/speacker.svg';
import { ToggleButton } from './components/ToggleButton';
import { ButtonVariant } from '~/components/button/Button';
import { AwaitingCall } from './AwaitingCall';
import { Player } from '~/generated/colyseus/Player';

export type VisioAppProps = { playerToCall?: Player };

export const VisioApp: React.FC<VisioAppProps> = ({ playerToCall }) => {
  const callerVideoRef = useRef<HTMLVideoElement>(null);
  const userVideoRef = useRef<HTMLVideoElement>(null);
  const {
    incomingStream,
    outgoingStream,
    call,
    hangUp,
    takeCall,
    callUser,
    toggleMuteCamera,
    toggleMuteMicrophone,
    toggleMuteSound,
    cameraMuted,
    microphoneMuted,
    soundMuted,
  } = useVisioContext();
  const { closeApp } = useSmartphoneContext();

  useEffect(() => {
    if (outgoingStream && userVideoRef.current) {
      userVideoRef.current.srcObject = outgoingStream;
      userVideoRef.current.play();
      userVideoRef.current.volume = 0;
    }
  }, [outgoingStream]);

  useEffect(() => {
    if (incomingStream && callerVideoRef.current) {
      callerVideoRef.current.srcObject = incomingStream;
      callerVideoRef.current.play();
      callerVideoRef.current.volume = 0.6;
    }
  }, [incomingStream]);

  useEffect(() => {
    if (playerToCall && !call) {
      callUser(playerToCall);
    }
  }, [call, callUser, playerToCall]);

  return (
    <Wrapper>
      {call && !incomingStream && call.callerMetadata && (
        <AwaitingCall caller={call.callerMetadata} direction={call.direction} />
      )}
      <CallerVideo ref={callerVideoRef} />
      <UserVideoWrapper>
        <UserVideo ref={userVideoRef} />
      </UserVideoWrapper>
      <AppFooter>
        {call && incomingStream && (
          <>
            <ToggleButton onClick={toggleMuteSound} active={soundMuted}>
              <Icon as={SpeackerSVG} />
            </ToggleButton>
            <ToggleButton onClick={toggleMuteCamera} active={cameraMuted}>
              <Icon as={CameraSVG} />
            </ToggleButton>
            <ToggleButton
              onClick={toggleMuteMicrophone}
              active={microphoneMuted}
            >
              <Icon as={MicrophoneSVG} />
            </ToggleButton>
          </>
        )}
        {call?.direction === 'incoming' && !incomingStream && (
          <ToggleButton onClick={takeCall} variant={ButtonVariant.GREEN}>
            <Icon as={AcceptCallSVG} />
          </ToggleButton>
        )}
        {call && (
          <ToggleButton
            variant={ButtonVariant.RED}
            onClick={() => {
              hangUp();
              closeApp();
            }}
          >
            <Icon as={RefuseCallSVG} />
          </ToggleButton>
        )}
      </AppFooter>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #0b1a22;
  overflow: hidden;
`;

const CallerVideo = styled.video`
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
`;

const UserVideo = styled.video`
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%) scaleX(-1);
`;

const UserVideoWrapper = styled.div`
  position: absolute;
  bottom: 70px;
  right: 10px;
  width: 90px;
  height: 150px;
  overflow: hidden;
`;

const AppFooter = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  background-color: rgba(228, 228, 228, 0.6);
  height: 60px;
  width: 100%;
  border-radius: 12px 12px 0 0;
  align-items: center;
  justify-content: space-around;
`;

const Icon = styled.svg`
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
`;

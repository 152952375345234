import styled from '@emotion/styled';
//@ts-ignore
import ModelViewer from '@metamask/logo';

import React, { useEffect } from 'react';

interface IMetamaskLogoProps {
  onClick?: () => void;
}

export const MetamaskLogo: React.FC<IMetamaskLogoProps> = ({ onClick }) => {
  useEffect(() => {
    const viewer = ModelViewer({
      // Dictates whether width & height are px or multiplied
      pxNotRatio: true,
      width: 50,
      height: 50,
      id: 'metamask',
      // pxNotRatio: false,
      // width: 0.9,
      // height: 0.9,

      // To make the face follow the mouse.
      followMouse: true,

      // head should slowly drift (overrides lookAt)
      slowDrift: false,
    });
    const container = document.getElementById('logo-container');
    if (container && container.childElementCount < 1) {
      viewer.container.setAttribute('id', 'fox');
      container.appendChild(viewer.container);
    }
    return () => {
      const fox = document.getElementById('fox');
      if (fox) {
        fox.remove();
      }
    };
  }, []);

  return (
    <>
      <Clickable onClick={onClick}> </Clickable>
      <div style={{ cursor: 'pointer' }} id="logo-container"></div>
    </>
  );
};

const Clickable = styled.div`
  position: absolute;
  z-index: 1000;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

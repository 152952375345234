import styled from '@emotion/styled';

export const TermsOfUse = () => {
  return (
    <TermsOfUseContainer>
      <h1>PengVerse TERMS OF USE</h1>
      <Separator />
      Last updated: April 28, 2022
      <Separator />
      These PengVerse Terms of Use (the “Terms”) set forth the terms and
      conditions under which Peng Labs, a Simplified Joint Stock Company with
      capital of € 1000, headquartered in Paris (75001), 229 rue Saint Honoré,
      registered in the Paris Trade and Companies Register under number 909 398
      828 (collectively, “PL”, “us”, “our”, or “we”) and you (“you” or “your”)
      agree to be governed as it relates to any of your activity in PengVerse, a
      decentralized, community-driven platform created and owned by PL (“Peng
      Labs”). These Terms govern your access to, use of, and interaction with
      PengVerse, including any content, functionality, and services offered on
      or through PengVerse and your creation of any Assets and/or Games (defined
      below) (collectively, the “Services”).
      <SmalSeparator />
      Please read these Terms carefully before you start to use PengVerse. By
      using Pengverse, you accept and agree to be bound and abide by these Terms
      and our Privacy Policy, which can be found at the following URL: Privacy
      Policy, you must not access or use PengVerse.
      <SmalSeparator />
      PengVerse is offered and available to users who are 18 years of age or
      older. By accessing or using PengVerse you represent and warrant that you
      are of legal age to form a binding contract with PL and meet the foregoing
      eligibility requirements. If you do not meet all of these requirements,
      you must not access or use PengVerse
      <SmalSeparator />
      <Separator />
      PengVerse
      <SmalSeparator />
      PengVerse enables users such as you to create, buy, transfer, and trade
      unique digital game assets in 2D pixel art (each, an “Asset” and together,
      the “Assets”), which can then be used on the PengVerse for game-making
      purposes. The PengVerse further enables users such as you to purchase and
      publish onto LANDs parcels in The PengVerse within which you can create
      Assets and visual scripting using PengVerse Game Maker, which creates an
      interactive experience built for, and published on, PengVerse (each, a
      “Game”). A Game may be made available to users of PengVerse and you will
      control the pricing and business model (free, free-2-play or premium) of
      the Game. By utilizing our Services, you affirm that you are aware and
      acknowledge that PL is a non-custodial provider of software services that
      has developed and distributed PengVerse where the Services occur without
      any involvement or actions taken by PL or any third-party.
      <Separator />
      ASSET & GAME CREATION
      <SmalSeparator />
      You may create, upload, and exchange Assets and create Games that comply
      with these Terms, including the following requirements:
      <SmalSeparator />
      • Assets are created in VerseEdit. Games are created with PengVerse Game
      Maker.
      <SmalSeparator />
      • Assets and Games must comply with the Metadata Specifications (defined
      and included below).
      <SmalSeparator />
      • Assets must be unique. Any Assets that exhibit obvious visual
      similarities to a pre-existing Asset will be removed from PengVerse. PL
      retains the right to moderate and review Assets for copyright infringement
      and to remove Assets from PengVerse that violate these Terms.
      <SmalSeparator />
      • Assets and Games must not be pornographic, threatening, harassing,
      libelous, hate-oriented, harmful, defamatory, racist, xenophobic, or
      illegal, as will be determined by PL in its sole discretion.
      <SmalSeparator />
      • To reiterate, each new Asset must be different enough from all existing
      Assets. If variations are not allowed, which is the default, no Asset
      should look like, or be similar to, another Asset even if they differ in
      size and/or color. New Assets being sufficiently different from existing
      Assets will be determined in the sole discretion of PL.
      <SmalSeparator />
      • By uploading an Asset to the PengVerse, you agree never to publish the
      Asset elsewhere.
      <SmalSeparator />
      • You are solely responsible for ensuring that any Assets you submit to
      the Services comply with any applicable laws and third party rights,
      including but not limited to any intellectual property rights, privacy
      rights and publicity rights.
      <SmalSeparator />
      • You agree that any information included in your Assets and/or Games may
      be used in accordance with our Privacy Policy.
      <SmalSeparator />
      • PL always has the right, in its sole discretion, to accept or reject any
      Assets and/or Games.
      <Separator />
      LANDS
      <SmalSeparator />
      You may purchase Lands within PengVerse. Within your Land, you may edit
      your Metadata to adjust title, description, URL link, preview image, and
      logo. All Metadata (and any URL, images, or logos to which it links or
      that are uploaded) must comply with these Terms and specifically cannot
      link to or contain any material or content that is pornographic,
      threatening, harassing, libelous, hate-oriented, harmful, defamatory,
      racist, xenophobic, or illegal. We reserve the right to moderate and/or
      delete any Metadata that does not comply with these Terms.
      <Separator />
      SALE OF ASSETS AND GAMES; PAYMENT
      <SmalSeparator />
      You may make your Asset eligible to be sold in the PengVerse marketplace.
      You and PL shall mutually agree on the price for the Asset in the
      PengVerse marketplace. You will control the scarcity of the Asset in the
      PengVerse marketplace. Any revenue earned in the PengVerse marketplace for
      sales of the Asset, minus any transaction fees, shall be paid to you
      immediately on the blockchain by the purchaser of your Asset. A Game may
      be made available for sale to users of the PengVerse and you will control
      the pricing and business model (free, free-2-play or premium) of the Game.
      Any revenue earned in the PengVerse marketplace for sales of a Game, minus
      any transaction fees, shall be paid to you immediately on the blockchain
      by the purchaser of your Game.
      <SmalSeparator />
      If you elect to purchase and/or sell Assets and/or Games on PengVerse, any
      financial transactions that you engage in will be conducted solely through
      the Avalanche network via MetaMask (or other Avalanche-compatible wallets
      and browsers). PL has no insight into or control over these payments or
      transactions, nor do we have the ability to reverse any transactions. With
      that in mind, PL will have no liability to you or to any third party for
      any claims or damages that may arise as a result of any transactions that
      you engage in PengVerse.
      <SmalSeparator />
      Each sales transaction that occurs in PengVerse will be subject to a fee
      payable by the purchaser to PL. Such fee will be automatically applied as
      part of the sales transaction.
      <SmalSeparator />
      As between you and PL, you will be solely responsible to pay any and all
      sales, use, value-added and other taxes, duties, and assessments (except
      taxes on PL's net income) now or hereafter claimed or imposed by any
      governmental authority (collectively, “Taxes”) associated with your use of
      PengVerse (including, without limitation, any Taxes that may become
      payable as a result of your ownership, transfer, or creation of any Assets
      and/or Games).
      <Separator />
      OWNERSHIP OF PL CONTENT
      <SmalSeparator />
      By using the Services, you will encounter “PL Content”, which includes all
      of the images, text, information, data, audio, video, graphics and other
      material included on, or otherwise made available through the Services,
      excluding your Assets and/or Games. Except as otherwise set forth in these
      Terms, we do not claim ownership over your Assets and/or Games.
      <SmalSeparator />
      All PL Content is owned by PL or its licensors, and is protected by U.S.
      and international copyright laws, trademark laws and/or other proprietary
      rights and laws. As between you and PL and its licensors, PL or its
      licensors own and retain, solely and exclusively, all rights, title and
      interest in and to the Services, and all PL Content that we or our
      licensors create and that we make available to you through the Services,
      including but not limited to any and all copyrights, trademark rights,
      trade secret rights, patent rights, database rights and other intellectual
      property and proprietary rights therein. All logos and trademarks, service
      marks, logos and trade names displayed on or in connection with the
      Services are the registered and unregistered trademarks and service marks
      of PL or third parties in the United States and/or other countries. Your
      use of the Services does not grant you any ownership over any PL Content,
      and except for the limited license we grant you under these Terms, your
      use of the Services does not grant you any license or permission under any
      copyright, trademark or other intellectual property rights of PL or any
      third party. We reserve all rights not expressly granted to you in these
      Terms.
      <SmalSeparator />
      We grant you a limited, non-exclusive, non-sublicensable and
      non-transferable license to use the Services as they are provided to you
      by us, only as set forth in these Terms. Except as expressly permitted in
      these Terms, you may not reproduce, distribute, adapt, modify, translate,
      create derivative works from, publish or otherwise use any portion of the
      Services or PL Content for any purpose without express prior written
      permission from us or the applicable rights holder. Any commercial
      exploitation of the Services or PL Content without express prior written
      permission from us or the applicable rights holder is strictly prohibited.
      <SmalSeparator />
      All software and software-as-a-service used in connection with the
      Services (“Software”) is proprietary to PL or to third parties, and except
      as may be required to exercise the foregoing license grant, any use,
      redistribution, sale, decompilation, reverse engineering, disassembly,
      translation or reduction of such software to human-readable form is
      prohibited. You agree that PL may update the Software without notice, at
      any time and in our sole discretion, and that these Terms will apply to
      any updated versions.
      <Separator />
      OWNERSHIP OF ASSETS AND GAMES
      <Separator />
      During the operation of the Services, you may upload certain Assets and
      Games that you have created to PengVerse in accordance with these Terms.
      Except as otherwise set forth in these Terms, you remain the owner of your
      Assets and Games at all times, and PL does not claim any ownership rights
      in your Assets and Games. Assets and Games are otherwise subject to the
      following provisions. These Terms apply to all Assets and Games that you
      contribute to PengVerse or to any PL website.
      <SmalSeparator />
      You are solely responsible for ensuring that any Assets and Games you
      submit to the Services comply with any applicable laws and third party
      rights, including but not limited to any intellectual property rights,
      privacy rights and publicity rights. You agree that any information
      included in your Assets and Games may be used in accordance with our
      Privacy Policy. PL always has the right, in its sole discretion, to accept
      or reject any Assets and/or Games.
      <SmalSeparator />
      By using the Services, you grant PL a worldwide, non-exclusive,
      royalty-free, perpetual, irrevocable, sub-licensable (through multiple
      tiers), transferable right and license to use, reproduce, publicly
      display, distribute and adapt the publicly shared Assets and Games for the
      purposes of developing, distributing, providing, improving, and promoting
      the Services, our activities, and your publicly shared Assets and Games.
      You further grant PL the right to use your name and trademarks, if any, in
      connection with our use of your publicly shared Assets and Games.
      <SmalSeparator />
      You may make your Assets and Games available for purchase in PengVerse.
      Each Asset is a non-fungible token (a “NFT”) on the blockchain. When you
      upload an Asset and make it available for sale in PengVerse marketplace,
      you retain ownership of all intellectual property rights associated with
      such Asset but you agree to make a certain number of the Assets available
      for sale as NFTs. End users who purchase the Asset own that underlying,
      purchased NFT completely and have the right to sell, trade, donate, give
      away, transfer, or otherwise dispose of the NFT as they see fit; provided,
      however, that each Asset will be tokenized so that it will have provable
      scarcity and proof of ownership.
      <SmalSeparator />
      If you make your Assets and Games available to other users hereunder, you
      acknowledge and accept that such Assets and Games (i) may be made
      available for purchase in PengVerse; (ii) can be downloaded from a third
      party application or website operated by PL; and (iii) that the purchase
      of such Assets and Games is governed by these Terms.
      <SmalSeparator />
      PL attempts to make sure that all Assets and Games uploaded to PengVerse
      are uploaded and made available for sale by their original creator, but PL
      is not liable if another user breaches any of our terms and conditions
      (including these Terms) and/or creates Assets or Games of which they are
      not the original creator.
      <SmalSeparator />
      If you purchase an Asset in PengVerse, please be aware that the creator of
      the Asset retains copyright to the Asset, which means you cannot use the
      Asset for commercial purposes of any kind; provided, however, that you do
      acquire the right to display and resell the Asset.
      <Separator />
      USER CONTENT
      <SmalSeparator />
      1.1 User Content. “User Content” means any and all information and content
      that a user submits to, or uses with, the Site (e.g., content in the
      user's profile or postings). You are solely responsible for your User
      Content. You assume all risks associated with use of your User Content,
      including any reliance on its accuracy, completeness or usefulness by
      others, or any disclosure of your User Content that personally identifies
      you or any third party. You hereby represent and warrant that your User
      Content does not violate our Acceptable Use Policy (defined in Section
      1.3). You may not represent or imply to others that your User Content is
      in any way provided, sponsored or endorsed by Company. Because you alone
      are responsible for your User Content, you may expose yourself to
      liability if, for example, your User Content violates the Acceptable Use
      Policy. Company is not obligated to backup any User Content, and your User
      Content may be deleted at any time without prior notice. You are solely
      responsible for creating and maintaining your own backup copies of your
      User Content if you desire.
      <SmalSeparator />
      1.2 License. You hereby grant (and you represent and warrant that you have
      the right to grant) to Company an irrevocable, nonexclusive, royalty-free
      and fully paid, worldwide license to reproduce, distribute, publicly
      display and perform, prepare derivative works of, incorporate into other
      works, and otherwise use and exploit your User Content, and to grant
      sublicenses of the foregoing rights, solely for the purposes of including
      your User Content in the Site. You hereby irrevocably waive (and agree to
      cause to be waived) any claims and assertions of moral rights or
      attribution with respect to your User Content.
      <SmalSeparator />
      1.3 Acceptable Use Policy.The following terms constitute our “Acceptable
      Use Policy”:
      <SmalSeparator />
      • (3.a) You agree not to use the Site to collect, upload, transmit,
      display, or distribute any User Content (i) that violates any third-party
      right, including any copyright, trademark, patent, trade secret, moral
      right, privacy right, right of publicity, or any other intellectual
      property or proprietary right; (ii) that is unlawful, harassing, abusive,
      tortious, threatening, harmful, invasive of another's privacy, vulgar,
      defamatory, false, intentionally misleading, trade libelous, pornographic,
      obscene, patently offensive, promotes racism, bigotry, hatred, or physical
      harm of any kind against any group or individual or is otherwise
      objectionable; (iii) that is harmful to minors in any way; or (iv) that is
      in violation of any law, regulation, or obligations or restrictions
      imposed by any third party.
      <SmalSeparator />
      • (3.b) In addition, you agree not to: (i) upload, transmit, or distribute
      to or through the Site any computer viruses, worms, or any software
      intended to damage or alter a computer system or data; (ii) send through
      the Site unsolicited or unauthorized advertising, promotional materials,
      junk mail, spam, chain letters, pyramid schemes, or any other form of
      duplicative or unsolicited messages, whether commercial or otherwise;
      (iii) use the Site to harvest, collect, gather or assemble information or
      data regarding other users, including e-mail addresses, without their
      consent; (iv) interfere with, disrupt, or create an undue burden on
      servers or networks connected to the Site, or violate the regulations,
      policies or procedures of such networks; (v) attempt to gain unauthorized
      access to the Site (or to other computer systems or networks connected to
      or used together with the Site), whether through password mining or any
      other means; (vi) harass or interfere with any other user's use and
      enjoyment of the Site; or (vi) use software or automated agents or scripts
      to produce multiple accounts on the Site, or to generate automated
      searches, requests, or queries to (or to strip, scrape, or mine data from)
      the Site (provided, however, that we conditionally grant to the operators
      of public search engines revocable permission to use spiders to copy
      materials from the Site for the sole purpose of and solely to the extent
      necessary for creating publicly available searchable indices of the
      materials, but not caches or archives of such materials, subject to the
      parameters set forth in our robots.txt file).
      <SmalSeparator />
      1.4 Enforcement. We reserve the right (but have no obligation) to review
      any User Content, and to investigate and/or take appropriate action
      against you in our sole discretion if you violate the Acceptable Use
      Policy or any other provision of these Terms or otherwise create liability
      for us or any other person. Such action may include removing or modifying
      your User Content, terminating your Account in accordance with Section
      Error! Reference source not found., and/or reporting you to law
      enforcement authorities.
      <SmalSeparator />
      1.5 Feedback. If you provide Company with any feedback or suggestions
      regarding the Site (“Feedback”), you hereby assign to Company all rights
      in such Feedback and agree that Company shall have the right to use and
      fully exploit such Feedback and related information in any manner it deems
      appropriate. Company will treat any Feedback you provide to Company as
      non-confidential and non-proprietary. You agree that you will not submit
      to Company any information or ideas that you consider to be confidential
      or proprietary.
      <Separator />
      REPRESENTATIONS AND WARRANTIES
      <SmalSeparator />
      You warrant and represent that: (a) you own or otherwise control all of
      the rights to your Assets and Games; (b) the Assets and/or Games are your
      original creations and are solely the result of your artistic effort; (c)
      the Assets and/or Games are free and clear of liens or encumbrances from
      any source whatsoever; (d) you know of no adverse claims to the Assets
      and/or Games; (e) you have secured or will have secured prior to
      submitting the Assets and/or Games to PL, any and all necessary rights,
      clearances and/or licenses with respect to the Assets and/or Games, and
      any other materials and elements embodied in or used in connection with
      the Services; (f) none of the Services and/or other materials provided,
      created, produced, compiled, developed or otherwise supplied hereunder,
      shall violate or infringe upon the copyright, trademark, privacy, creative
      or other rights of any person, firm, corporation or other third party by
      reason of distribution, exhibition or other use; (g) any Asset and/or Game
      you upload to PengVerse or any information incorporated into your Land
      profile is not libelous, defamatory, obscene, pornographic, abusive,
      indecent, threatening, harassing, hateful, or offensive; and (h) you have
      not entered into and will not enter into any agreement adverse to or
      inconsistent with these Terms.
      <Separator />
      FEEDBACK
      <SmalSeparator />
      We appreciate your feedback and suggestions about our Services and you
      agree that any feedback or suggestions submitted to us about the Services
      are entirely voluntary and that we will be free to use any such feedback
      or suggestions as we see fit and without any obligation to you.
      <Separator />
      RIGHT TO MONITOR, MODERATE, OR REMOVE
      <SmalSeparator />
      You agree that you bear all risks associated with your Assets and Games.
      You are solely responsible for safeguarding your Assets and Games, and PL
      has no duty to store copies of Assets and Games for future availability to
      you or any user except as otherwise provided under these Terms. PL does
      not permit the infringement of intellectual property rights on the
      Services, and will remove Assets and/or Games from the Services if
      properly notified that such Assets and/or Games infringe on another's
      intellectual property rights. We reserve the right to remove Assets and
      Games from the Services, in whole or in part, without prior notice, for
      any reason or for no reason at all. Without limiting our right to
      terminate a user pursuant to these Terms, we reserve the right to
      terminate the account of any user of the Services who has been notified of
      infringing activity and/or has had Assets and/or Games removed from the
      Service. We also reserve the right to decide whether Assets and/or Games
      are appropriate and comply with these Terms for violations other than
      violations of intellectual property law. This shall extend to the right of
      PL to edit, modify, moderate, re-format, change or otherwise remove all or
      part of the descriptions, comments and/or annotations that you and/or
      third parties add and/or make in relation to your Assets and/or Games in
      any manner that we may determine, whenever we deem it appropriate.
      <Separator />
      INDEMNIFICATION
      <SmalSeparator />
      (a) You agree to indemnify and hold PL, its directors, officers and
      employees harmless from and against any claims, causes of action, demands,
      loss or damage by reason of (i) a breach of any representation, warranty
      or covenant hereunder, (ii) any exhibition, presentation, distribution or
      exploitation of your Assets and/or Games or any rights therein, and (iii)
      the negligence, fault or default of you, your employees, authorized
      agents, servants or independent contractors hired by you, or any
      subcontractor hired by any of the foregoing. (b) You shall comply with all
      applicable state, city, and federal laws, ordinances, codes, and
      regulations which affect your creation of any Assets and Games under these
      Terms and/or your relationship with PL. (c) You agree to notify PL
      promptly, in writing, of any legal claim or action of which you have
      knowledge, which is in any way related to these Terms, your Assets, your
      Games, or your obligations hereunder. (d) The warranties, representations
      and indemnifications contained herein shall survive any termination or
      expiration of these Terms or your relationship with PL.
      <Separator />
      RESTRICTIONS AND OBLIGATIONS
      <SmalSeparator />
      It is important to PL that the Services be used safely, and in accordance
      with the law, for the enjoyment of all users. You agree that you will not
      use the Services to:
      <SmalSeparator />
      • Decompile, disassemble, reverse engineer, copy, transfer, or otherwise
      use the Services, PL Content, Assets, and Games, except as permitted by
      these Terms;
      <SmalSeparator />
      • Promote any illegal activity, or advocate, promote or assist any
      unlawful act;
      <SmalSeparator />
      • Violate the legal rights (including the rights of publicity and privacy)
      of others or contain any material that could give rise to any civil or
      criminal liability under applicable laws or regulations or that otherwise
      may be in conflict with these Terms and our Privacy Policy;
      <SmalSeparator />
      • Transmit any material or content that is pornographic, threatening,
      harassing, libelous, hate-oriented, harmful, defamatory, racist,
      xenophobic, or illegal;
      <SmalSeparator />
      • Transmit any material or content that is inappropriate for families or
      otherwise suitable only for adults;
      <SmalSeparator />
      • Transmit any material or content that attempts to falsely state or
      otherwise misrepresent your identity or affiliation with a person or
      entity;
      <SmalSeparator />
      • Transmit or otherwise make available any unsolicited or unauthorized
      advertising, promotional materials, “junk mail,” “spam,” “chain letters,”
      “pyramid schemes,” or any other form of solicitation;
      <SmalSeparator />
      • Transmit material or content that promotes, provides, or relates to
      instructional information about illegal activities or promotes physical
      harm or injury against any individual or group;
      <SmalSeparator />
      • Transmit or encourage the transmission of any material that may infringe
      the intellectual property rights or other rights of third parties,
      including trademark, copyright, patent, or right of publicity, or which
      otherwise constitutes or promotes counterfeit materials or goods;
      <SmalSeparator />
      • Use the services in a manner that (a) is likely to interrupt, suspend,
      slow down or hinder the continuity of the Services, (b) constitutes an
      intrusion or attempt to break into the Services or PL's computer or
      networking systems, (c) will divert of the Services' system resources, (d)
      may place a disproportionate load on the infrastructure of the Services,
      and (e) constitutes an attack on security and authentication measures of
      the Services or PL's computer or networking systems;
      <SmalSeparator />
      • Intrude into a third party's computer system, engage in any activity
      that may damage, control, interfere with or intercept all or part of a
      third party's computer system and violate its integrity or security, or
      otherwise transmit any materials or content that is harmful for third
      party information systems (including but not limited to viruses, worms,
      Trojans); and/or
      <SmalSeparator />
      • Otherwise use the Services for purposes other than those for which they
      were designed.
      <SmalSeparator />
      • Engage in activity which operates to defraud PL, PL users, or any other
      person.
      <SmalSeparator />
      • Engage in gambling, casino-style games, or games of chance.
      <Separator />
      DISCLAIMERS; LIMITATION OF LIABILITY
      <SmalSeparator />
      NOTHING IN THESE TERMS WILL PREJUDICE THE STATUTORY RIGHTS THAT YOU MAY
      HAVE AS A CONSUMER OF THE SERVICES. SOME COUNTRIES, STATES, PROVINCES, AND
      OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR
      THE LIMITATION OF LIABILITY AS STATED IN THIS SECTION, SO THE BELOW TERMS
      MAY NOT FULLY APPLY TO YOU. INSTEAD, IN SUCH JURISDICTIONS, THE EXCLUSIONS
      AND LIMITATIONS BELOW SHALL APPLY ONLY TO THE EXTENT PERMITTED BY THE LAWS
      OF SUCH JURISDICTION. THE SERVICES AND ALL INFORMATION, CONTENT,
      MATERIALS, PRODUCTS (INCLUDING SOFTWARE), AND OTHER SERVICES INCLUDED ON
      OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICES ARE PROVIDED BY PL
      ON AN “AS IS” AND “AS AVAILABLE” BASIS. PL MAKES NO REPRESENTATIONS OR
      WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE
      SERVICES, OR THE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING
      SOFTWARE), OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO
      YOU THROUGH THE SERVICES. YOU EXPRESSLY AGREE THAT YOUR USE OF THE
      SERVICES IS AT YOUR SOLE RISK. TO THE FULL EXTENT PERMISSIBLE BY LAW, PL
      DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
      TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
      PURPOSE. PL DOES NOT WARRANT THAT THE SERVICES, INFORMATION, CONTENT,
      MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR
      OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICES, ARE FREE OF VIRUSES
      OR OTHER HARMFUL COMPONENTS. TO THE FULL EXTENT PERMISSIBLE BY LAW, PL
      WILL NOT BE LIABLE FOR ANY LOSS OF PROFITS OR ANY INDIRECT, INCIDENTAL,
      PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION
      WITH THIS THESE TERMS. FURTHER, TO THE FULL EXTENT PERMISSIBLE BY LAW,
      PL'S AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS
      WILL NOT EXCEED US $100.00. THESE LIMITATIONS AND EXCLUSIONS REGARDING
      DAMAGES APPLY EVEN IF ANY REMEDY FAILS TO PROVIDE ADEQUATE COMPENSATION.
      <Separator />
      MODIFYING AND TERMINATING THE SERVICE
      <SmalSeparator />
      At any time and without notice, PL reserves the right to modify or stop
      offering all or part of the Services. PL may, in its sole discretion and
      at any time, refuse anyone who requests access to PengVerse, terminate
      your rights to create and/or upload Assets and Games, and/or block or
      prevent your access to and use of any Services or features governed by
      these Terms; provided, however, that you will remain the owner of your
      Assets and Games in accordance with these Terms.
      <Separator />
      OTHER WEBSITES AND SERVICES
      <SmalSeparator />
      The Services may contain links and features that enable you to access
      other third-party websites or services (“Third-Party Services”) that are
      not owned or controlled by us. Such Third-Party Services are governed by
      their own terms of use. We do not control Third-Party Services, and we are
      not responsible for the contents of any linked site. A link does not imply
      endorsement of, sponsorship of, or affiliation with the Third-Party
      Services by PL. Please exercise caution before proceeding to any
      Third-Party Services or entering into any transaction with third parties
      linked to from the Services. PL may in no circumstances be held liable for
      the technical availability of Third-Party Services, the content,
      advertising, products and/or services available on Third-Party Services,
      or any transactions that take place between a user and Third-Party
      Services whom the user has been directed via the Services. PL may in no
      circumstances be a party to any disputes whatsoever between you and third
      parties concerning Third-Party Services.
      <Separator />
      GOVERNING LAW; FORUM
      <SmalSeparator />
      The rights and obligations of the parties hereunder and the interpretation
      of these Terms will be governed by the laws of France, without giving
      effect to its principles of conflicts of law. If either party brings
      against the other party any proceeding arising out of these Terms, that
      party may bring proceedings only in the courts of France and no other
      courts, and each party hereby submits to the exclusive jurisdiction of
      those courts for purposes of any such proceeding.
      <Separator />
      AMENDMENT
      <SmalSeparator />
      PL reserves the right to amend these Terms at any time by posting a notice
      on this page. Any user using the Services after an amendment has become
      effective accepts the Terms as amended. A user who does not accept the
      amended Terms shall cease use of the Services.
      <Separator />
    </TermsOfUseContainer>
  );
};

const TermsOfUseContainer = styled.div`
  background-color: #031920;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  text-align: left;
  padding: 32px;
  box-sizing: border-box;
`;

const Separator = styled.div`
  margin-top: 16px;
  display: flex;
`;

const SmalSeparator = styled.div``;

import { isMobile } from 'react-device-detect';

import { HeaderDesktopView } from './DesktopView';
import { HeaderMobileView } from './MobileView';

interface IHeaderProps {
  setHeaderHeight: (height: number) => void;
}

export const Header: React.FC<IHeaderProps> = ({ setHeaderHeight }) => {
  return isMobile ? (
    <HeaderMobileView setHeaderHeight={setHeaderHeight} />
  ) : (
    <HeaderDesktopView setHeaderHeight={setHeaderHeight} />
  );
};

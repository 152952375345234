import WalkingPeng from '../../assets/images/WalkingPeng.gif';
import WalkingDao from '../../assets/images/WalkingDao.gif';
import styled from '@emotion/styled';
import { FC } from 'react';

export const NftSeparator: FC = () => {
  return (
    <NftSeparatorContainer>
      <Peng style={{ backgroundImage: `url(${WalkingPeng})` }}></Peng>
      <Caption>Own & Use your assets</Caption>
      <Peng style={{ backgroundImage: `url(${WalkingDao})` }}></Peng>
    </NftSeparatorContainer>
  );
};

const NftSeparatorContainer = styled.div`
  font-family: 'GravityBold8';
  background-color: #031920;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 200px;
  padding: 0px 40px;
`;

const Caption = styled.div`
  font-size: 28px;
  @media only screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 20px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Peng = styled.div`
  width: 120px;
  height: 60%;
  background-size: contain;
  background-repeat: no-repeat;
  @media only screen and (max-width: 600px) {
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
  }
`;

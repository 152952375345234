import CommunityZone from '../../assets/images/Community.jpg';
import PengScientist from '../../assets/images/Pengscientifique.png';
import styled from '@emotion/styled';
import { FC, useContext } from 'react';
import { HeaderContext } from '../Application';
import { isMobile, browserName } from 'react-device-detect';
import { css } from '@emotion/react';

export const CaptionAndVideo: FC = () => {
  const headerHeight = useContext(HeaderContext);
  console.log(browserName);

  return (
    <CaptionAndVideoContainer
      style={{
        height: `calc(100vh - ${headerHeight.height}px)`,
      }}
    >
      <Caption>
        <Text>
          {isMobile ? (
            <>
              PengVerse is an interoperable pixel metaverse providing acces to
              the most popular AVAX products <br /> & projects.
            </>
          ) : (
            <>
              PengVerse is an interoperable pixel metaverse providing acces to
              the most popular AVAX products & projects.
            </>
          )}
        </Text>
        <ScientistPeng />
      </Caption>
      <Video>
        <Title useNeon={browserName !== 'Chrome' ? 1 : 0}>
          interact with the ecosystem !
        </Title>
        <VideoReader
          controls
          preload="auto"
          src="https://storage.googleapis.com/peng-video/partners.mp4"
        />
      </Video>
    </CaptionAndVideoContainer>
  );
};

const CaptionAndVideoContainer = styled.div`
  font-family: 'GravityBold8';
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: url(${CommunityZone}) no-repeat center center fixed;
  background-size: auto 100%;

  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
    background: url(${CommunityZone}) no-repeat center center;
    background-size: auto 100%;
    background-position: 50%;
    margin-top: 0px;
    padding: 64px 0px;
    box-sizing: border-box;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    flex-direction: column;
    background: url(${CommunityZone}) no-repeat center center;
    background-size: auto 100%;
    background-position: 50%;
    margin-top: 0px;
    padding: 64px 0px;
    box-sizing: border-box;
  }
`;

const Caption = styled.div`
  position: relative;
  width: 40%;
  height: fit-content;
  background: #031920;
  border: 5px solid #000000;
  box-sizing: border-box;
  border-radius: 35px;
  font-size: 24px;
  line-height: 38px;
  display: flex;
  text-align: start;
  align-items: start;
  justify-content: start;

  padding: 16px 16px;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    padding-top: 20px;
    height: fit-content;
    width: 90%;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    font-size: 12px;
    padding-top: 20px;
    height: fit-content;
    width: 90%;
  }
`;

const Text = styled.div`
  border: 4px solid #ffffff;
  padding: 32px 24px;
  @media only screen and (max-width: 600px) {
    padding: 6px 14px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    padding: 6px 14px;
  }
  @media only screen and (max-width: 375px) and (max-height: 667px) {
    font-size: 10px;
    line-height: 24px;
  }
`;

const Video = styled.div`
  position: relative;
  width: 720px;
  height: 450px;
  background: #031920;
  border: 3px solid #000000;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 32px 16px;
  animation: float 5s ease-in-out infinite;
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.25);
  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }
  @media only screen and (max-width: 600px) {
    padding-top: 20px;
    height: fit-content;
    width: 90%;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    padding-top: 20px;
    height: fit-content;
    width: 90%;
  }
`;

const VideoReader = styled.video`
  width: 100%;
  height: 100%;
  border-style: solid;
  border-color: white;
  border-width: 2px;
  background-color: black;
`;

const ScientistPeng = styled.div`
  background-image: url(${PengScientist});
  position: absolute;
  width: 220px;
  height: 220px;
  background-size: contain;
  background-repeat: no-repeat;
  bottom: -70px;
  right: -50px;
  filter: drop-shadow(10px 10px 4px rgba(0, 0, 0, 0.25));
  @media only screen and (max-width: 600px) {
    width: 200px;
    height: 200px;
    bottom: -80px;
    right: 0px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    width: 200px;
    height: 200px;
    bottom: -80px;
    right: 0px;
  }
  @media only screen and (max-width: 375px) and (max-height: 667px) {
    width: 140px;
    height: 160px;
    bottom: -70px;
    right: -20px;
  }
`;

const Title = styled.div<{ useNeon: 1 | 0 }>`
  position: absolute;
  box-sizing: border-box;
  font-size: 30px;
  text-align: center;
  top: -34px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 0px;
  letter-spacing: -2px;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #157e8c;
  color: white;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 42px #157e8c, 0 0 77px #157e8c,
    0 0 100px #157e8c;
  ${({ useNeon }) =>
    useNeon
      ? css`
          animation: light 4s infinite linear;
        `
      : null}

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    top: -20px;
    -webkit-text-stroke-width: 1px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    font-size: 16px;
    top: -20px;
    -webkit-text-stroke-width: 1px;
  }

  @keyframes light {
    0% {
      text-shadow: 0 0 7px #fff, 0 0 10px #157e8c, 0 0 42px #157e8c,
        0 0 77px #157e8c, 0 0 100px #157e8c;
    }
    25% {
      text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 32px #157e8c,
        0 0 87px #157e8c, 0 0 120px #157e8c;
    }
    50% {
      text-shadow: 0 0 7px #fff, 0 0 10px #157e8c, 0 0 32px #157e8c,
        0 0 87px #157e8c, 0 0 120px #157e8c;
    }
    75% {
      text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 32px #157e8c,
        0 0 87px #157e8c, 0 0 120px #157e8c;
    }
    90% {
      text-shadow: -4px 4px 0px #157e8c, 4px 4px 0px #157e8c,
        4px 4px 0px #157e8c, 4px 4px 0px #157e8c;
    }
  }
`;

import styled from '@emotion/styled';

export enum ButtonVariant {
  WHITE = 'white',
  BLACK = 'black',
  RED = 'red',
  GREEN = 'green',
}

const primaryColor = {
  [ButtonVariant.BLACK]: '#000000',
  [ButtonVariant.WHITE]: '#ffffff',
  [ButtonVariant.RED]: '#d66a6a',
  [ButtonVariant.GREEN]: '#6ad681',
};

const secondaryColor = {
  [ButtonVariant.WHITE]: '#000000',
  [ButtonVariant.BLACK]: '#ececec',
  [ButtonVariant.RED]: '#000000',
  [ButtonVariant.GREEN]: '#000000',
};

export type ButtonProps = { variant?: ButtonVariant; light?: boolean };

export const Button = styled.button<ButtonProps>`
  background-color: ${({ variant = ButtonVariant.WHITE, light }) =>
    light ? primaryColor[ButtonVariant.WHITE] : primaryColor[variant]};
  color: ${({ variant = ButtonVariant.WHITE, light }) =>
    light ? primaryColor[variant] : secondaryColor[variant]};
  border-radius: 12px;
  cursor: pointer;
  border: ${({ variant = ButtonVariant.WHITE, light }) =>
    light ? `3px solid ${primaryColor[variant]}` : 'none'};
  padding: 12px 24px;
`;

import styled from '@emotion/styled';
import { ApolloProvider } from '@apollo/client';
import { Pane } from 'evergreen-ui';
import { useState, createContext, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Home } from '.';
import { MetamaskProvider } from '../contexts/metamask';
import { Header } from './header';
import { Play } from './play';
import { client } from '../graphql/client';
import { RequireAuth } from '~/helpers/requireAuth';
import { AuthProvider } from '~/contexts/auth';
import { Policy } from './policy/Policy';
import { TermsOfUse } from './terms-of-use/TermsOfUse';

export const HeaderContext = createContext({ height: 0 });

export const Application = () => {
  const [_headerHeight, setHeaderHeight] = useState(0);
  const [inGame, setInGame] = useState(false);
  let location = useLocation();

  useEffect(() => {
    if (location.pathname === '/play') {
      setInGame(true);
    } else {
      setInGame(false);
    }
  }, [location]);

  return (
    <ApplicationContainer>
      <ApolloProvider client={client}>
        <MetamaskProvider>
          <AuthProvider>
            {!inGame ? <Header setHeaderHeight={setHeaderHeight} /> : null}
            <HeaderContext.Provider value={{ height: _headerHeight }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/policy" element={<Policy />} />
                <Route path="/termsOfUse" element={<TermsOfUse />} />
                <Route
                  path="/play"
                  element={
                    <RequireAuth>
                      <Play />
                    </RequireAuth>
                  }
                />
              </Routes>
            </HeaderContext.Provider>
          </AuthProvider>
        </MetamaskProvider>
      </ApolloProvider>
    </ApplicationContainer>
  );
};

const ApplicationContainer = styled(Pane)`
  background-color: #031920;
  /* @media only screen and (max-width: 600px) {
    padding-right: 16px;
    padding-left: 16px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  /* @media only screen and (min-width: 600px) and (max-width: 959px) {
    padding-right: 16px;
    padding-left: 16px;
  } */
`;

import { PositionState } from '../../types';
import { GameScene } from '../GameScene';

export function emitPosition(
  this: GameScene,
  positionState: PositionState,
  save: boolean,
) {
  this.room?.send('move', positionState);
}

export function startPositionEmitter(this: GameScene) {
  if (!this.interval) {
    let lastSaveIntervals = 0;
    let lastStateEmited: PositionState | undefined;
    this.interval = setInterval(() => {
      if (this.player && this.penguin) {
        lastSaveIntervals = (lastSaveIntervals + 1) % 50;
        const positionState: PositionState = {
          direction: this.currentDirection,
          action: this.currentAction,
          x: this.penguin.x,
          y: this.penguin.y,
        };
        if (
          lastStateEmited?.action !== positionState.action ||
          lastStateEmited?.direction !== positionState.direction ||
          lastStateEmited?.x !== positionState.x ||
          lastStateEmited?.y !== positionState.y
        ) {
          emitPosition.bind(this)(positionState, lastSaveIntervals === 0);
          lastStateEmited = positionState;
        }
      }
    }, 20);
  }
}

import { useEffect, useState } from 'react';
import { useGameContext } from '~/contexts/game';
import { Penguin } from '~/models/penguin';

export const usePenguin = (id: number) => {
  const { player } = useGameContext();
  const [penguin, setPenguin] = useState<Penguin | null>(null);

  useEffect(() => {
    if (player) {
      const _penguin = new Penguin(id, player?.penguinContract);
      _penguin.initialize().then(setPenguin);
    }
  }, [id, player]);

  return [penguin] as const;
};

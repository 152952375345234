import { useCallback, useEffect, useState } from 'react';
import { Player } from '../../models/player';
import { Account } from '../../models/account';
import { TotemContract } from '../../models/items/totem/totem_contract/TotemContract';
import { PenguinContract } from '../../models/penguin/penguinContract/penguinContract';
import useMetamask from '../../contexts/metamask';

export const usePlayer = () => {
  const [player, setPlayer] = useState<Player>();
  const { web3, connectToWeb3 } = useMetamask();
  const [loading, setLoading] = useState(false);

  const initialize = useCallback(
    async (fakeAddress?: string) => {
      if (!player) {
        setLoading(true);
        if (web3) {
          const account = new Account(web3);
          await account.initializeAccount();
          const penguinContract = new PenguinContract(web3, account);
          const totemContract = new TotemContract(web3, account);
          const player = new Player(penguinContract, totemContract, account);
          await player.initialize();
          if (fakeAddress) {
            player.account.cheatChangeAddress(fakeAddress);
          }
          setPlayer(player);
          setLoading(false);
        } else {
          await connectToWeb3();
        }
      }
    },
    [connectToWeb3, web3],
  );

  useEffect(() => {
    if (web3 && !player) {
      initialize();
    }
  }, [web3, player, initialize]);

  return [player, initialize, loading] as const;
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { Button, ButtonVariant } from '~/components/button/Button';

type ToggleButtonProps = {
  active?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  variant?: ButtonVariant;
  children: ReactNode;
};
export const ToggleButton: React.FC<ToggleButtonProps> = ({
  active,
  onClick,
  variant,
  children,
}) => {
  return (
    <RoundedButton active={active} onClick={onClick} variant={variant}>
      {children}
      {active && <MuteIndicator />}
    </RoundedButton>
  );
};

const RoundedButton = styled(Button)<{ active?: boolean }>`
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  ${({ active }) =>
    active &&
    css`
      opacity: 80%;
    `}
`;

const MuteIndicator = styled.div`
  position: absolute;
  background-color: #d66a6a;
  width: 6px;
  height: 40px;
  transform: rotate(45deg);
`;

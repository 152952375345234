import { Player } from '~/generated/colyseus/Player';
import { Action, Direction, PlayerInstance, Spritesheet } from '../../types';
import { GameScene } from '../GameScene';

export function move(this: GameScene) {
  if (this.penguin && this.cursorKeys) {
    const speed = 150;
    this.penguin.body.maxSpeed = speed;
    if (
      this.cursorKeys.up.isDown ||
      this.cursorKeys.w.isDown ||
      this.cursorKeys.z.isDown
    ) {
      this.currentAction = Action.WALK;
      this.penguin.body.setVelocityY(-speed);
      if (this.currentDirection !== Direction.UP) {
        this.currentDirection = Direction.UP;
        // sprite?.play(`penguinSheet${penguinId}` + Direction.Up);
      }
    } else if (this.cursorKeys.down.isDown || this.cursorKeys.s.isDown) {
      this.currentAction = Action.WALK;
      this.penguin.body.setVelocityY(speed);
      if (this.currentDirection !== Direction.DOWN) {
        this.currentDirection = Direction.DOWN;
        // sprite?.play(`penguinSheet${penguinId}` + Direction.Down);
      }
    } else {
      this.penguin.body.setVelocityY(0);
    }

    if (this.cursorKeys.right.isDown || this.cursorKeys.d.isDown) {
      this.currentAction = Action.WALK;
      this.penguin.body.setVelocityX(speed);
      if (this.currentDirection !== Direction.RIGHT) {
        this.currentDirection = Direction.RIGHT;
        // sprite?.play(`penguinSheet${penguinId}` + Direction.Right);
      }
    } else if (
      this.cursorKeys.left.isDown ||
      this.cursorKeys.q.isDown ||
      this.cursorKeys.a.isDown
    ) {
      this.currentAction = Action.WALK;
      this.penguin.body.setVelocityX(-speed);
      if (this.currentDirection !== Direction.LEFT) {
        this.currentDirection = Direction.LEFT;
        // sprite?.play(`penguinSheet${penguinId}` + Direction.Left);
      }
    } else {
      this.penguin.body.setVelocityX(0);
    }

    if (
      this.penguin.body.velocity.x === 0 &&
      this.penguin.body.velocity.y === 0
    ) {
      this.currentAction = Action.STOP;
    }

    if (
      this.currentDirection &&
      (!this.penguin.anims.currentAnim ||
        (this.currentAction !== Action.STOP && !this.penguin.anims.isPlaying) ||
        this.penguin.anims.currentAnim.key !==
          `penguinSheet${this.penguinId}` + this.currentDirection)
    ) {
      this.penguin.play(
        `penguinSheet${this.penguinId}` + this.currentDirection,
      );
    } else if (this.currentAction === Action.STOP) {
      this.penguin.stop();
    }
  }
}

export function triggerLayerEvents(this: GameScene) {
  if (this.penguin) {
    const pengCoordinates = {
      x: Number((this.penguin.x / 32).toFixed(0)),
      y: Number((this.penguin.y / 32).toFixed(0)),
    };

    const layers = this.tileMap?.layers.filter((layer) =>
      layer.tilemapLayer.hasTileAt(pengCoordinates.x, pengCoordinates.y),
    );
    if (layers?.length) {
      const { exitLayer, websiteLayer, audioLayer, jitsisLayer, twitchLayer } =
        layers.reduce(
          (
            acc: {
              exitLayer: Phaser.Tilemaps.LayerData | null;
              websiteLayer: Phaser.Tilemaps.LayerData | null;
              audioLayer: Phaser.Tilemaps.LayerData | null;
              jitsisLayer: Phaser.Tilemaps.LayerData | null;
              twitchLayer: Phaser.Tilemaps.LayerData | null;
            },
            layer,
          ) => {
            if (layer.name.includes('exits/')) {
              acc = { ...acc, exitLayer: layer };
            } else if (layer.name.includes('websites/')) {
              acc = { ...acc, websiteLayer: layer };
            } else if (layer.name.includes('audio/')) {
              acc = { ...acc, audioLayer: layer };
            } else if (layer.name.includes('jitsis/')) {
              acc = { ...acc, jitsisLayer: layer };
            } else if (layer.name.includes('twitch/')) {
              acc = { ...acc, twitchLayer: layer };
            }
            return acc;
          },
          {
            exitLayer: null,
            websiteLayer: null,
            audioLayer: null,
            jitsisLayer: null,
            twitchLayer: null,
          },
        );

      if (exitLayer && !this.exiting) {
        this.exiting = true;
        const exitTo = getLayerProperty(exitLayer, 'exitTo');
        const x = getLayerProperty(exitLayer, 'x');
        const y = getLayerProperty(exitLayer, 'y');

        this.events.emit('exitTo', {
          newMapName: exitTo.value,
          coordinates: { x: x.value * 32, y: y.value * 32 },
        });
      }

      if (audioLayer) {
        const musicName = getLayerProperty(audioLayer, 'musicName').value;
        const source = getLayerProperty(audioLayer, 'source').value;
        const audioLoop = getLayerProperty(audioLayer, 'audioLoop').value;
        if (!this.currentMusic || this.currentMusic !== musicName) {
          this.events.emit('playMusic', {
            musicName: musicName,
            source: source,
            audioLoop: audioLoop,
          });
          this.currentMusic = musicName;
        }
      } else if (this.currentMusic) {
        this.events.emit('stopMusic');
        this.currentMusic = undefined;
      }

      if (jitsisLayer) {
        if (!this.inJitsiLayer) {
          try {
            const jitsiConfig = JSON.parse(
              getLayerProperty(jitsisLayer, 'jitsiConfig').value,
            );
            const jitsiRoom = getLayerProperty(jitsisLayer, 'jitsiRoom').value;
            this.events.emit('openJitsi', {
              jitsiRoom,
              jitsiConfig,
            });
            this.inJitsiLayer = true;
          } catch (err) {
            console.log('err', err);
          }
        }
      } else {
        if (this.inJitsiLayer) {
          this.inJitsiLayer = false;
          this.events.emit('closeJitsi');
        }
      }

      if (websiteLayer && !this.inWebsiteLayer) {
        const openWebsite = getLayerProperty(websiteLayer, 'openWebsite');
        this.events.emit('openWebsite', {
          url: openWebsite.value,
        });
        this.inWebsiteLayer = true;
      } else if (!websiteLayer && this.inWebsiteLayer) {
        this.events.emit('closeWebsite');
        this.inWebsiteLayer = false;
      }

      if (twitchLayer && !this.inTwitchLayer) {
        const twitchChannel = getLayerProperty(twitchLayer, 'twitchChannel');
        if (twitchChannel) {
          this.events.emit('startTwitch', {
            channel: twitchChannel.value,
          });
          this.inTwitchLayer = true;
        }
      } else if (!twitchLayer && this.inTwitchLayer) {
        this.events.emit('stopTwitch');
        this.inTwitchLayer = false;
      }
    }
    layers?.forEach((l) => {
      const layerToWatch = this.layersToWatch.get(l.name);
      if (layerToWatch && !this.watchedLayer.has(l.name)) {
        layerToWatch.onEnter(l);
        this.watchedLayer.set(l.name, { ...layerToWatch, layer: l });
      }
    });
    this.watchedLayer.forEach((wl) => {
      if (layers?.findIndex((l) => l.name === wl.layer?.name) === -1) {
        wl.onLeave(wl.layer!);
        this.watchedLayer.delete(wl.layer!.name);
      }
    });
  }
}

export const getLayerProperty = (
  layer: Phaser.Tilemaps.LayerData,
  propName: string,
): any => {
  return layer.properties.find(
    (prop: any) => (prop.name as string) === propName,
  );
};

function checkIfPlayerNear(this: GameScene, otherPlayer: PlayerInstance) {
  const distance = Phaser.Math.Distance.Between(
    this.penguin!.body.x,
    this.penguin!.body.y,
    otherPlayer.x,
    otherPlayer.y,
  );
  if (distance < 50) {
    detectNearPlayer.bind(this)(otherPlayer);
  }
}

function detectNearPlayer(this: GameScene, playerNear: PlayerInstance) {
  const index = this.playersNear.findIndex(
    (p) => playerNear.playerAddress === p.playerAddress,
  );
  if (index === -1) {
    this.playersNear.push(playerNear);
    this.events.emit('playerNear', playerNear);
  }
}

export const updatePlayerPosition = (
  player: Player,
  sprite: Spritesheet,
  name: Phaser.GameObjects.Text,
  physics: Phaser.Physics.Arcade.ArcadePhysics,
) => {
  if (sprite && sprite.body && player.publicAddress) {
    const target = new Phaser.Math.Vector2();
    target.x = player.x;
    target.y = player.y;

    const nameTarget = new Phaser.Math.Vector2();
    nameTarget.x =
      Math.floor(sprite.body.x - name.width / 2 + sprite.width / 2) - 15;
    nameTarget.y = Math.floor(sprite.body.y) - 23;

    const distance = Phaser.Math.Distance.Between(
      sprite.body.x,
      sprite.body.y,
      target.x,
      target.y,
    );

    const Namedistance = Phaser.Math.Distance.Between(
      name.x,
      name.y,
      target.x,
      target.y,
    );

    if (
      player.direction &&
      (!sprite.anims.isPlaying ||
        sprite.anims.currentAnim.key !==
          `penguinSheet${player.nftId}` + player.direction)
    ) {
      sprite.play(`penguinSheet${player.nftId}${player.direction}`);
    }

    const distanceToStop = 5;
    const defaultSpeed = 150;

    if (distance > 32 * 20) {
      sprite.body.reset(target.x, target.y);
    } else if (distance <= distanceToStop && player.action === Action.WALK) {
      const speed =
        (defaultSpeed * Math.pow(distance, 2)) / Math.pow(distanceToStop, 2);
      physics.moveToObject(sprite, target, speed);
      physics.moveToObject(name, nameTarget, speed);
    } else if (distance <= distanceToStop && player.action === Action.STOP) {
      sprite.body.reset(target.x, target.y);
      sprite.stop();
      if (Namedistance > 2) {
        physics.moveToObject(name, nameTarget, 0);
        name.setPosition(
          Math.floor(sprite.body.x - name.width / 2 + sprite.width / 2),
          Math.floor(sprite.body.y + sprite.height / 2) - 24,
        );
      }
    } else if (distance > distanceToStop) {
      physics.moveToObject(sprite, target, defaultSpeed);
      physics.moveToObject(name, nameTarget, defaultSpeed);
    }
  }
};

import {
  split,
  ApolloClient,
  ApolloLink,
  concat,
  HttpLink,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { api, auth } from '../config';
import { cache } from './cache';
import { onError } from '@apollo/client/link/error';
import { history } from '~/history';

const WS_OPERATIONS = ['UpdatePlayerPosition'];

const httpLink = new HttpLink({ uri: api.httpUrl });

const wsLink = new GraphQLWsLink(
  createClient({
    url: api.wsUrl,
    connectionParams: {
      authorization: localStorage.getItem(auth.storageKey) || null,
    },
  }),
);

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: localStorage.getItem(auth.storageKey) || null,
    },
  }));

  return forward(operation);
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      (definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription') ||
      (!!definition.name && WS_OPERATIONS.includes(definition.name.value))
    );
  },
  wsLink,
  httpLink,
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, path, name }) => {
      if (name === 'UNAUTHENTICATED' || name === 'UNAUTHORIZED') {
        history.push('/');
        localStorage.removeItem(auth.storageKey);
        client.clearStore();
      }
      console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`);
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const client = new ApolloClient({
  cache,
  link: concat(authMiddleware, concat(errorLink, splitLink)),
});

import styled from '@emotion/styled';
import { Penguin } from '../../models/penguin';

interface IMenuProps {
  currentPenguin: Penguin;
}

export const Menu: React.FC<IMenuProps> = ({ currentPenguin }) => {
  return (
    <>
      <MenuContainer
        style={{
          backgroundImage: currentPenguin
            ? `url(${currentPenguin.image})`
            : undefined,
        }}
      ></MenuContainer>
    </>
  );
};

const MenuContainer = styled.div`
  position: absolute;
  width: 64px;
  height: 64px;
  top: 25px;
  left: 25px;
  background-position: center;
  background-size: contain;
  border-radius: 32px;
`;

const MenuOverlay = styled.div`
  position: absolute;
  background-color: #303030c0;
  width: 100vw;
  height: 100vh;
  top: -25px;
  left: -25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: initial;
`;

const MenuWindow = styled.div`
  width: 50vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  background-color: #0f2f4dd7;
  box-shadow: 7px 7px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const Title = styled.h1`
  color: white;
`;

const Pengs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const PengNumber = styled.img`
  cursor: pointer;
  width: 50px;
  height: 50px;
`;

const ExitGame = styled.h2`
  cursor: pointer;
`;

import { useEffect, useState } from 'react';
import { createGenericContext } from '~/helpers/createGenericContext';
import { usePlayer } from '~/hooks/use-player/UsePlayer';
import { Penguin } from '~/models/penguin';
import { Player } from '~/models/player';
import { GameScene } from '~/modules/game/phaser/game-scene/GameScene';

type GameContextType = {
  player?: Player;
  currentPenguin?: Penguin;
  currentMap?: GameScene;
  setCurrentPenguin: (penguin: Penguin) => void;
  setCurrentMap: (map?: GameScene) => void;
  loading: boolean;
};

export const [useGameContext, GameContextProvider] =
  createGenericContext<GameContextType>();

type Props = {
  children: React.ReactNode;
};

export const GameProvider = ({ children }: Props) => {
  const [currentPenguin, setCurrentPenguin] = useState<Penguin>();
  const [currentMap, setCurrentMap] = useState<GameScene>();
  const [player, initializePlayer, playerLoading] = usePlayer();

  useEffect(() => {
    initializePlayer();
  }, [initializePlayer]);

  return (
    <GameContextProvider
      value={{
        player,
        currentPenguin,
        currentMap,
        setCurrentMap,
        setCurrentPenguin,
        loading: playerLoading,
      }}
    >
      {children}
    </GameContextProvider>
  );
};

import { Navigate, useLocation } from 'react-router-dom';
import { useAuthContext } from '~/contexts/auth';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  let auth = useAuthContext();
  let location = useLocation();

  if (!auth.user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

import styled from '@emotion/styled';
import { usePenguin } from '~/hooks/use-penguin';
import { Call } from './types/Call';
import { Caller } from './types/Caller';

type Props = {
  caller: Caller;
  direction: Call['direction'];
};

export const AwaitingCall: React.FC<Props> = ({ caller, direction }) => {
  const [penguin] = usePenguin(Number(caller.nftTokenId));

  return (
    <Wrapper>
      <div>{direction === 'incoming' ? 'Incoming call' : 'Calling'}</div>
      {penguin && (
        <div>
          <ProfilePicture src={penguin?.image} />
        </div>
      )}
      {caller.nickname ??
        `${caller.publicAddress.substring(
          0,
          5,
        )}...${caller.publicAddress.substring(
          caller.publicAddress.length - 5,
        )}`}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: min-content min-content min-content;
  gap: 30px;
  justify-content: center;
  padding: 50px 0;
`;

const ProfilePicture = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

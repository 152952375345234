import CommunityZone from '../../assets/images/bannerAdventure.jpg';
import PoweredByAvalanche from '../../assets/images/PoweredByAvalanche.png';
import { ReactComponent as TwitterSvg } from './../../assets/icons/twitter.svg';
import { ReactComponent as DiscordSvg } from './../../assets/icons/discord.svg';
import { browserName } from 'react-device-detect';
import styled from '@emotion/styled';
import { FC, useContext } from 'react';
import { HeaderContext } from '../Application';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

export const JoinCommunity: FC = () => {
  const headerHeight = useContext(HeaderContext);
  const navigate = useNavigate();
  return (
    <JoinCommunityContainer
      style={{
        height: `calc(100vh - ${headerHeight.height}px)`,
      }}
    >
      <TopPart>
        <Title useNeon={browserName !== 'Chrome' ? 1 : 0}>
          Join the community
        </Title>
        <Caption>
          Join other players and supporters on Twitter or Discord and give your
          voice to
          <br /> build the metaverse we all want.
        </Caption>
        <Button
          onClick={() =>
            window.open('https://t.co/7kZ1ZLrDUu', '_blank')!.focus()
          }
        >
          Join our discord <DiscordIcon />
        </Button>
        <Button
          onClick={() =>
            window.open('https://twitter.com/AvaxPenguinFam', '_blank')!.focus()
          }
        >
          Follow us on twitter <TwitterIcon />
        </Button>
      </TopPart>
      <Footer>
        <Links>
          <LinksFooter>
            <Licensed> © Peng Family 2022</Licensed>
            <div>
              <TwitterIcon
                onClick={() =>
                  window
                    .open('https://twitter.com/AvaxPenguinFam', '_blank')!
                    .focus()
                }
                style={{ marginRight: '24px', width: '2rem', height: '2rem' }}
              />
              <DiscordIcon
                onClick={() =>
                  window.open('https://t.co/7kZ1ZLrDUu', '_blank')!.focus()
                }
                style={{ width: '2rem', height: '2rem' }}
              />
            </div>
          </LinksFooter>
          <PoweredAvalanche></PoweredAvalanche>
        </Links>
        <Informations>
          Informations:
          <Info onClick={() => navigate('/policy')}> Privacy policy </Info>
          <Info onClick={() => navigate('/termsOfUse')}> Terms of use </Info>
          </Informations>
      </Footer>
    </JoinCommunityContainer>
  );
};

const JoinCommunityContainer = styled.div`
  background-color: #031920;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  background-size: auto 70%;
  background-position: top;

  @media only screen and (max-width: 600px) {
    background-size: auto 100%;
    background-position: 50%;
    margin-top: 0px;
    box-sizing: border-box;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    background-size: auto 100%;
    background-position: 50%;
    margin-top: 0px;
    box-sizing: border-box;
  }
`;

const TopPart = styled.div`
  font-family: 'GravityBold8';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${CommunityZone}) no-repeat center center;
  background-size: auto 100%;
  background-position: 90% 0%;
  height: 70%;
  width: 100%;
  @media only screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 30px;
    background-position: 50% 0%;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    font-size: 20px;
    line-height: 30px;
    background-position: 50% 0%;
  }
`;

const Footer = styled.div`
  font-family: 'Montserrat';
  height: 30%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 32px 16px 32px;
  box-sizing: border-box;
`;

const Title = styled.div<{ useNeon: 1 | 0 }>`
  font-size: 30px;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 42px #157e8c, 0 0 77px #157e8c,
    0 0 100px #157e8c;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #157e8c;
  ${({ useNeon }) =>
    useNeon
      ? css`
          animation: light 4s infinite linear;
        `
      : null}

  @media only screen and (max-width: 600px) {
    font-size: 30px;
    line-height: 40px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    font-size: 30px;
    line-height: 40px;
  }

  @keyframes light {
    0% {
      text-shadow: 0 0 7px #fff, 0 0 10px #157e8c, 0 0 42px #157e8c,
        0 0 77px #157e8c, 0 0 100px #157e8c;
    }
    25% {
      text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 32px #157e8c,
        0 0 87px #157e8c, 0 0 120px #157e8c;
    }
    50% {
      text-shadow: 0 0 7px #fff, 0 0 10px #157e8c, 0 0 32px #157e8c,
        0 0 87px #157e8c, 0 0 120px #157e8c;
    }
    75% {
      text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 32px #157e8c,
        0 0 87px #157e8c, 0 0 120px #157e8c;
    }
    90% {
      text-shadow: -4px 4px 0px #157e8c, 4px 4px 0px #157e8c,
        4px 4px 0px #157e8c, 4px 4px 0px #157e8c;
    }
  }
`;

const Caption = styled.div`
  font-size: 20px;
  line-height: 30px;

  @media only screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 30px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media only screen and (max-width: 375px) and (max-height: 667px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Button = styled.button`
  font-family: 'GravityBold8';
  background-color: #006b8c;
  color: white;
  font-size: 20px;
  padding: 16px;
  margin: 0px;
  border: none;
  cursor: pointer;
  border: 3px solid black;
  box-sizing: border-box;
  box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media only screen and (max-width: 600px) {
    width: 70%;
    font-size: 12px;
    line-height: 16px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    width: 70%;
    font-size: 12px;
    line-height: 16px;
  }
`;

const TwitterIcon = styled(TwitterSvg)`
  fill: white;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  
  @media only screen and (max-width: 600px) {
    width: 2rem;
    height: 2rem;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    width: 2rem;
    height: 2rem;
  }
`;

const DiscordIcon = styled(DiscordSvg)`
  fill: white;
  width: 4rem;
  height: 4rem;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    width: 2rem;
    height: 2rem;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    width: 2rem;
    height: 2rem;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  height: 100%;
`;

const Licensed = styled.div`
  margin-bottom: 16px;
`;

const PoweredAvalanche = styled.div`
  width: 200px;
  height: 80px;

  background-image: url(${PoweredByAvalanche});
  background-size: 100%;
  background-repeat: no-repeat;
`;

const Informations = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  height: 100%;
  width: 25%;
`;

const LinksFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
`;

const Info = styled.div`
  margin-top: 16px;  
`
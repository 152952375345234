import { Pane } from "evergreen-ui";
import "./App.css";
import { Application } from "./modules";

function App() {
  return (
    <Pane color="white" backgroundColor="#0b1a22" className="App">
      <Application />
    </Pane>
  );
}

export default App;

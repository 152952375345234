import styled from '@emotion/styled';
import { useContext, useEffect, useState } from 'react';
import BackgroundHome from '../../assets/images/BackgroundHome.png';
import { useNavigate } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { useAuthContext } from '~/contexts/auth';
import { HeaderContext } from '../Application';

export const Landing: React.FC = () => {
  const { user } = useAuthContext();
  let navigate = useNavigate();
  const headerHeight = useContext(HeaderContext);
  const [showTitle, setShowTitle] = useState(false);
  const styles = useSpring({
    delay: 500,
    opacity: showTitle ? 1 : 0,
  });

  useEffect(() => {
    setTimeout(() => setShowTitle(true), 300);
  }, []);

  return (
    <LandingContainer headerHeight={headerHeight.height}>
      <TitleContainer style={styles}>
        <Title>An Avalanche pixel</Title>
        <Title>metaverse</Title>
        <SubTitle style={{ marginBottom: '90px' }}>
          Use your NFTs, own assets & more
        </SubTitle>
        {/* <Button disabled={!user} onClick={() => navigate('/play')}>
          Play in browser
        </Button> */}
      </TitleContainer>
    </LandingContainer>
  );
};

const LandingContainer = styled.div<{ headerHeight: number }>`
  background: url(${BackgroundHome}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${({ headerHeight }) => `calc(100vh - ${headerHeight}px)`};
  background: url(${BackgroundHome}) no-repeat center center fixed;

  @media only screen and (max-width: 600px) {
    background: url(${BackgroundHome}) no-repeat center center;
    background-size: auto 100%;
    margin-top: 0px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    background: url(${BackgroundHome}) no-repeat center center;
    background-size: auto 100%;
    margin-top: 0px;
  }
`;

const Title = styled.h1`
  font-family: 'GravityBold8';
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.6);
  text-align: start;
  font-size: 64px;
  text-align: center;
  margin: 0px;
  letter-spacing: 2px;
  /* ----------- Non-Retina Screens ----------- */
  @media screen and (min-device-width: 1200px) and (max-device-width: 1920px) and (-webkit-min-device-pixel-ratio: 1) {
    font-size: 64px;
    margin-bottom: 40px;
  }

  /* ----------- Retina Screens ----------- */
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    font-size: 64px;
    margin-bottom: 60px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 32px;
    line-height: 60px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    font-size: 32px;
    line-height: 60px;
  }
`;

const SubTitle = styled.p`
  font-family: 'Montserrat';
  text-align: start;
  font-weight: 800;
  font-size: 3rem;
  margin: 0px;
  margin-bottom: 1.5rem;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;

  /* ----------- Non-Retina Screens ----------- */
  @media screen and (min-device-width: 1200px) and (max-device-width: 1920px) and (-webkit-min-device-pixel-ratio: 1) {
    font-size: 3rem;
    margin-bottom: 40px;
  }

  /* ----------- Retina Screens ----------- */
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    font-size: 3rem;
    margin-bottom: 60px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 2rem;
    margin-top: 60px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    font-size: 2rem;
    margin-top: 60px;
  }
`;

const TitleContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  font-family: 'GravityBold8';
  background-color: #006b8c;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.4), inset 3px 0px 3px #0a3745,
    inset 0px 3px 3px #5bcbee;
  color: white;
  font-size: 1.6rem;
  padding: 2.4rem;
  margin: 0px;
  border: none;
  cursor: pointer;
  :disabled {
    color: #343434;
    background-color: #605f5f;
    cursor: initial;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4), inset 4px 0px 4px #605f5f,
      inset 0px 4px 4px #605f5f;
  }
  :active {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4), inset 4px 0px 1px #10323d,
      inset 0px 4px 1px #10323d;
  }

  /* ----------- Non-Retina Screens ----------- */
  @media screen and (min-device-width: 1200px) and (max-device-width: 1920px) and (-webkit-min-device-pixel-ratio: 1) {
    font-size: 1.6rem;
  }

  /* ----------- Retina Screens ----------- */
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    font-size: 1.6rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 1rem;
    margin-bottom: 4rem;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    font-size: 1rem;
    margin-bottom: 4rem;
  }
`;

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export enum Action {
  Stop = 'STOP',
  Walk = 'WALK'
}

export type AddMessageGqlInputType = {
  conversationId: Scalars['String'];
  message: Scalars['String'];
};

export type Auth = {
  __typename?: 'Auth';
  token: Scalars['String'];
  user: User;
};

export type Conversation = {
  __typename?: 'Conversation';
  access: ConversationAccess;
  id: Scalars['String'];
  messages: PaginatedMessages;
  name?: Maybe<Scalars['String']>;
};


export type ConversationMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  limit?: Scalars['Int'];
};

export enum ConversationAccess {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export enum Direction {
  Down = 'DOWN',
  Left = 'LEFT',
  Right = 'RIGHT',
  Up = 'UP'
}

export type Disconect = {
  __typename?: 'Disconect';
  ok: Scalars['Boolean'];
};

export type Message = {
  __typename?: 'Message';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  message: Scalars['String'];
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  addMessage: Message;
  createPlayerState: PlayerState;
  disconnect: Disconect;
  login: Auth;
  signout: Signout;
  updatePlayerPosition: PlayerState;
  updateUser: User;
};


export type MutationAddMessageArgs = {
  addMessageInput: AddMessageGqlInputType;
};


export type MutationCreatePlayerStateArgs = {
  map: Scalars['String'];
  nftId: Scalars['String'];
};


export type MutationLoginArgs = {
  publicAddress: Scalars['String'];
  signature: Scalars['String'];
};


export type MutationUpdatePlayerPositionArgs = {
  updatePlayerPositionInput: UpdatePlayerPositionGqlInputType;
};


export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserGqlInputType;
};

export type Nft = {
  __typename?: 'Nft';
  id: Scalars['String'];
  metadata: NftMetadata;
  nftContract: NftContract;
  spriteUrl?: Maybe<Scalars['String']>;
  tokenId: Scalars['String'];
};

export type NftContract = {
  __typename?: 'NftContract';
  blockchain: Scalars['String'];
  contractAddress: Scalars['String'];
  id: Scalars['String'];
  spriteUrl?: Maybe<Scalars['String']>;
};

export type NftMetadata = {
  __typename?: 'NftMetadata';
  attributes: Array<NftMetadataAttribute>;
  description: Scalars['String'];
  generation: Scalars['Float'];
  image: Scalars['String'];
  name: Scalars['String'];
  tokenId: Scalars['Float'];
};

export type NftMetadataAttribute = {
  __typename?: 'NftMetadataAttribute';
  trait_type: Scalars['String'];
  value: Scalars['String'];
};

export type Nonce = {
  __typename?: 'Nonce';
  nonce: Scalars['String'];
  publicAddress: Scalars['String'];
};

export type PaginatedMessages = {
  __typename?: 'PaginatedMessages';
  items: Array<Message>;
  total: Scalars['Int'];
};

export type PlayerState = {
  __typename?: 'PlayerState';
  action: Action;
  active: Scalars['Boolean'];
  direction: Direction;
  id: Scalars['String'];
  room: Room;
  user: User;
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  conversation: Conversation;
  me?: Maybe<User>;
  nft: Nft;
  nftMetadata: NftMetadata;
  nonce: Nonce;
  user: User;
};


export type QueryConversationArgs = {
  id: Scalars['String'];
};


export type QueryNftArgs = {
  tokenId: Scalars['String'];
};


export type QueryNftMetadataArgs = {
  tokenId: Scalars['String'];
};


export type QueryNonceArgs = {
  publicAddress: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['String'];
};

export type Room = {
  __typename?: 'Room';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  map: Scalars['String'];
  playerStates: Array<PlayerState>;
};

export type Signout = {
  __typename?: 'Signout';
  ok: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  onMessage: Message;
  onPlayerStateUpdate: PlayerState;
  onUserUpdated: User;
};


export type SubscriptionOnMessageArgs = {
  conversationId: Scalars['String'];
};


export type SubscriptionOnPlayerStateUpdateArgs = {
  roomId: Scalars['String'];
};

export type UpdatePlayerPositionGqlInputType = {
  action: Action;
  direction: Direction;
  playerStateId: Scalars['String'];
  save: Scalars['Boolean'];
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type UpdateUserGqlInputType = {
  id: Scalars['String'];
  nftId?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  activeNft?: Maybe<Nft>;
  conversations: Array<Conversation>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  lastActiveAt: Scalars['DateTime'];
  nfts: Array<Nft>;
  nickname?: Maybe<Scalars['String']>;
  playerState?: Maybe<PlayerState>;
  publicAddress: Scalars['String'];
};

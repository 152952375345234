import styled from '@emotion/styled';
import { FC, useContext } from 'react';
import { HeaderContext } from '../Application';
import NftZone from '../../assets/images/NftZone.png';
import { isMobile, browserName } from 'react-device-detect';
import { css } from '@emotion/react';

export const Teaser: FC = () => {
  const headerHeight = useContext(HeaderContext);

  return (
    <TeaserContainer
      style={{
        height: `calc(100vh - ${headerHeight.height}px)`,
      }}
    >
      <VideoContainer>
        <Title useNeon={browserName !== 'Chrome' ? 1 : 0}>
          Welcome to the pengverse
        </Title>
        <Video>
          <VideoReader
            controls
            preload="auto"
            src="https://storage.googleapis.com/peng-video/TeaserV2.mp4"
          />
        </Video>
        <Caption>
          {isMobile ? (
            <>Propose a unique metaverse experience using Avalanche</>
          ) : (
            <>Propose a unique metaverse experience using Avalanche</>
          )}
        </Caption>
      </VideoContainer>
    </TeaserContainer>
  );
};

const TeaserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'GravityBold8';
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${NftZone}) no-repeat center center fixed;
  background-size: auto 100%;
  height: 100%;

  @media only screen and (max-width: 600px) {
    background: url(${NftZone}) no-repeat center center;
    background-size: auto 100%;
    background-position: 50%;
    margin-top: 0px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    background: url(${NftZone}) no-repeat center center;
    background-size: auto 100%;
    background-position: 50%;
    margin-top: 0px;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  background: #031920;
  border: 5px solid #000000;
  box-sizing: border-box;
  border-radius: 41px;
  height: 90%;
  width: 60%;
  margin-top: 30px;
  animation: float 5s ease-in-out infinite;
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.25);
  @media only screen and (max-width: 600px) {
    height: fit-content;
    width: 90%;
    padding-top: 20px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    padding-top: 20px;
    height: fit-content;
    width: 90%;
  }

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }
`;

const Title = styled.div<{ useNeon: 1 | 0 }>`
  position: absolute;
  box-sizing: border-box;
  font-size: 32px;
  text-align: center;
  top: -34px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 0px;
  letter-spacing: -2px;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #157e8c;
  color: white;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 42px #157e8c, 0 0 77px #157e8c,
    0 0 100px #157e8c;
  ${({ useNeon }) =>
    useNeon
      ? css`
          animation: light 4s infinite linear;
        `
      : null}

  @media only screen and (max-width: 600px) {
    font-size: 18px;
    top: -20px;
    -webkit-text-stroke-width: 1px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    font-size: 18px;
    top: -20px;
    -webkit-text-stroke-width: 1px;
  }
  @media only screen and (max-width: 375px) and (max-height: 667px) {
    font-size: 16px;
    top: -20px;
    -webkit-text-stroke-width: 1px;
  }

  @keyframes light {
    0% {
      text-shadow: 0 0 7px #fff, 0 0 10px #157e8c, 0 0 42px #157e8c,
        0 0 77px #157e8c, 0 0 100px #157e8c;
    }
    25% {
      text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 32px #157e8c,
        0 0 87px #157e8c, 0 0 120px #157e8c;
    }
    50% {
      text-shadow: 0 0 7px #fff, 0 0 10px #157e8c, 0 0 32px #157e8c,
        0 0 87px #157e8c, 0 0 120px #157e8c;
    }
    75% {
      text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 32px #157e8c,
        0 0 87px #157e8c, 0 0 120px #157e8c;
    }
    90% {
      text-shadow: -4px 4px 0px #157e8c, 4px 4px 0px #157e8c,
        4px 4px 0px #157e8c, 4px 4px 0px #157e8c;
    }
  }
`;

const Video = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  width: 100%;
  height: 90%;
  padding: 48px 32px 0px 32px;
  box-sizing: border-box;
  @media only screen and (max-width: 600px) {
    justify-content: center;
    padding: 16px 32px 0px 32px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    justify-content: center;
    padding: 16px 32px 0px 32px;
  }
`;

const Caption = styled.div`
  text-align: center;
  width: 100%;
  font-size: 20px;
  margin-top: 20px;
  @media only screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 18px;
    margin-top: 0px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    font-size: 12px;
    line-height: 18px;
    margin-top: 0px;
  }
`;

const VideoReader = styled.video`
  width: 100%;
  height: 100%;
  border-style: solid;
  border-color: white;
  border-width: 2px;
  background-color: black;
  @media only screen and (max-width: 600px) {
    width: 80vw;
    height: 60vw;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    width: 80vw;
    height: 60vw;
  }
`;

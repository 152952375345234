import {
  Spritesheet,
  Tiles,
  ITiledMap,
  PlayerInstance,
  PhaserCreateProps,
  CursorKeys,
  WatchableLayers,
  Direction,
  Action,
} from '../types';
import * as config from '~/config';
import { Player } from '../../../../models/player';
import { Client, Room } from 'colyseus.js';
import {
  getCursorKeys,
  hideLayers,
  setCamera,
  setPlayerPenguin,
  setSound,
  setWorld,
} from './functions/createTools';
import * as preloadTools from './functions/preloadTools';
import * as updateTools from './functions/updateTools';
import * as helpers from './functions/helpers';
import * as connectionTools from './functions/connectionTools';
import { Maps } from './functions/mapGenerator';
import { layerWatcher } from './functions/scripting';
import { State } from '~/generated/colyseus/State';
import { Player as ServerPlayer } from '~/generated/colyseus/Player';
import { auth } from '~/config';
import { updatePlayerPosition } from './functions/updateTools';
import { AnimatedTile } from './AnimatedTile';

const sceneConfig: Phaser.Types.Scenes.SettingsConfig = {
  active: false,
  visible: false,
  // key: 'forest',
};

export class GameScene extends Phaser.Scene {
  gameSceneName: string;
  player?: Player;
  onPlayerNear?: (playerNear: PlayerInstance) => void;
  protected tiledMap?: ITiledMap;
  protected tileMap?: Phaser.Tilemaps.Tilemap;
  protected animatedTiles: AnimatedTile[] = [];
  protected cursorKeys?: CursorKeys;
  protected currentDirection: Direction = Direction.DOWN;
  protected currentAction: Action = Action.STOP;
  protected tilesets: Tiles[] = [];
  protected penguin?: Spritesheet;
  protected penguinId: number = 0;
  protected players: Map<
    string,
    { player: ServerPlayer; sprite: Spritesheet; name: Phaser.GameObjects.Text }
  > = new Map();
  protected interval?: any;
  protected layersToWatch: WatchableLayers = new Map();
  protected watchedLayer: WatchableLayers = new Map();
  public currentMusic?: string;
  protected playersNear: PlayerInstance[] = [];
  protected openApp?: PhaserCreateProps['openApp'];
  protected inWebsiteLayer = false;
  protected inJitsiLayer = false;
  protected inTwitchLayer = false;
  room?: Room;
  protected exiting = false;

  constructor(
    gameSceneName: Maps,
    currentPenguinId: number,
    player: Player,
    map: ITiledMap,
  ) {
    super({ ...sceneConfig, key: gameSceneName });
    this.gameSceneName = gameSceneName;
    this.penguinId = currentPenguinId;
    this.player = player;
    this.tiledMap = map;
  }

  // ===========================================================
  // ======================PHASER LOOP==========================
  // ===========================================================
  preload() {
    preloadTools.redefineTilesetsPaths.bind(this)();
    preloadTools.loadTiledMapAndAssets.bind(this)();
    this.events.emit('preloadOver');
  }

  async create({ startPosition, openApp }: PhaserCreateProps) {
    this.openApp = openApp;
    this.cursorKeys = getCursorKeys(this.input);
    setPlayerPenguin.bind(this)(startPosition);
    setWorld.bind(this)();
    hideLayers.bind(this)();
    layerWatcher.bind(this)();
    setCamera(this.physics, this.cameras, this.penguin!, this.tileMap!);
    setSound.bind(this);
    await this.connect();
    await this.initializePlayer(this.penguinId);
    helpers.animateTiles.bind(this)();
    this.events.emit('createOver');
  }

  update(time: number, delta: number) {
    this.animatedTiles.forEach((tile) => tile.update(delta));
    if (this.penguin) {
      updateTools.move.bind(this)();
      updateTools.triggerLayerEvents.bind(this)();
      this.players.forEach((p) => {
        updatePlayerPosition(p.player, p.sprite, p.name, this.physics);
      });
    }
  }

  // ===========================================================
  // ========================PUBLICS============================
  // ===========================================================

  async connect() {
    const client = new Client(config.gameServer.url);
    const accessToken = localStorage.getItem(auth.storageKey);

    const room = await client.joinOrCreate<State>(this.gameSceneName, {
      map: this.gameSceneName,
      accessToken,
    });

    room.state.players.onAdd = async (player, sessionId) => {
      if (player.publicAddress !== this.player?.account.address) {
        await helpers.loadAvatars.bind(this)([player.nftId]);
        let sprite = helpers.createPlayerSprite.bind(this)(player);
        const name = helpers.createPlayerName.bind(this)(sprite, player);
        this.players.set(player.publicAddress, { player, sprite, name });

        player.onChange = async () => {
          if (!this.textures.exists(`penguinSheet${player.nftId}`)) {
            sprite.destroy();
            await helpers.loadAvatars.bind(this)([player.nftId]);
            sprite = helpers.createPlayerSprite.bind(this)(player);
          }
          this.players.set(player.publicAddress, { player, sprite, name });
        };
      }
    };

    // remove local reference when entity is removed from the server
    room.state.players.onRemove = (player, sessionId) => {
      const localPlayer = this.players.get(player.publicAddress);
      localPlayer?.sprite.destroy();
      this.players.delete(player.publicAddress);
      localPlayer?.name.destroy();
    };

    this.room = room;
    connectionTools.startPositionEmitter.bind(this)();
  }

  disconnect() {
    this.room?.connection.close();
    clearInterval(this.interval);
  }

  unLockKeys() {
    this.input.keyboard.enabled = true;
  }

  lockKeys() {
    this.input.keyboard.enabled = false;
  }

  async changeAvatar(avatarId: number) {
    if (!this.textures.exists(`penguinSheet${avatarId}`)) {
      await helpers.loadAvatars.bind(this)([avatarId]);
    }
    this.penguin?.setTexture(`penguinSheet${avatarId}`);
    helpers.removeAnimations(this.anims, `penguinSheet${avatarId}`);
    helpers.createAnimations(this.anims, `penguinSheet${avatarId}`);
    this.penguinId = avatarId;
  }

  async initializePlayer(penguinId: number) {
    await this.changeAvatar(penguinId);
    this.penguin?.setVisible(true);
  }
}

import styled from '@emotion/styled';
import { FC, useEffect, useRef, useState } from 'react';
import { animated } from 'react-spring';

interface IAudioControllerProps {
  style?: React.CSSProperties;
  onChange: (value: number) => void;
  title: string;
  defaultValue: number;
}

export const AudioController: FC<IAudioControllerProps> = ({
  style,
  onChange,
  title,
  defaultValue,
}) => {
  const [initialize, setInitialize] = useState(false);
  const barRef = useRef<HTMLDivElement>(null);
  const [unit, setUnit] = useState(1);
  const [value, setValue] = useState(0);
  const [drag, setDrag] = useState(false);

  useEffect(() => {
    if (barRef.current) {
      setUnit(barRef.current.clientWidth / 100);
      setValue(defaultValue * unit);
      setInitialize(true);
    }
  }, [barRef.current]);

  useEffect(() => {
    if (initialize) {
      onChange(Number((value / unit).toFixed(0)));
    }
  }, [value, unit, initialize]);

  return (
    <Wrapper style={{ ...style }}>
      <Header>
        <>{title}</>
        <ValueDisplay>{(value / unit).toFixed(0)}%</ValueDisplay>
      </Header>
      <AudioBar
        ref={barRef}
        onMouseDown={(e) => {
          if (barRef.current) {
            setValue(e.pageX - barRef.current.getBoundingClientRect().x);
            setDrag(true);
          }
        }}
        onMouseMove={(e) => {
          if (barRef.current && drag) {
            setValue(e.pageX - barRef.current.getBoundingClientRect().x);
          }
        }}
        onMouseUp={() => setDrag(false)}
      >
        <InsideBar
          style={{ width: (value !== 0 ? value - 4 : 0) + 'px' }}
        ></InsideBar>
      </AudioBar>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

const AudioBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #082838;
  padding: 2px;
`;

const InsideBar = styled(animated.div)`
  height: 100%;
  background-color: #1780b5;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 8px;
`;

const ValueDisplay = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
  background-color: #082838;
  padding: 2px 4px;
  margin-left: 64px;
  font-size: 10px;
  height: 16px;
`;

import { Player } from '~/generated/colyseus/Player';
import { Direction } from '~/generated/graphql/types';
import { App } from '~/modules/game/smartphone/types';
import { AnimationManager, Spritesheet } from '../../types';
import { AnimatedTile, TilesetTileData } from '../AnimatedTile';
import { GameScene } from '../GameScene';
import { generateAnimation } from './createTools';

export const createAnimations = (
  animationManager: AnimationManager,
  sprisheetName: string,
) => {
  generateAnimation(Direction.Down, animationManager, sprisheetName, 0, 2);
  generateAnimation(Direction.Right, animationManager, sprisheetName, 6, 8);
  generateAnimation(Direction.Left, animationManager, sprisheetName, 3, 5);
  generateAnimation(Direction.Up, animationManager, sprisheetName, 9, 11);
};

export const removeAnimations = (
  animationManager: AnimationManager,
  sprisheetName: string,
) => {
  animationManager.remove(sprisheetName + Direction.Up);
  animationManager.remove(sprisheetName + Direction.Down);
  animationManager.remove(sprisheetName + Direction.Right);
  animationManager.remove(sprisheetName + Direction.Left);
};

export async function loadAvatars(this: GameScene, avatarIds: number[]) {
  return new Promise<void>((resolve, reject) => {
    avatarIds.forEach((avatarId) => {
      if (!this.textures.exists(`penguinSheet${avatarId}`)) {
        //todo: check if the sprite has alredy been loaded. or the complete could not be triggered
        this.load.spritesheet(
          `penguinSheet${avatarId}`,
          `https://storage.googleapis.com/peng-sprites/bucket/${avatarId}.png`,
          { frameWidth: 32, frameHeight: 32 },
        );
      } else {
        resolve();
      }
    });
    this.load.once('complete', (e: Phaser.Loader.LoaderPlugin) => {
      resolve();
    });

    this.load.start();
  });
}

export function createPlayerSprite(
  this: GameScene,
  player: Player,
): Spritesheet {
  const sprite = this.add.sprite(
    player.x,
    player.y,
    `penguinSheet${player.nftId}`,
  ) as Spritesheet;

  sprite.setInteractive();
  sprite.on('pointerdown', () => {
    this.openApp!(App.CONTACT, { player });
  });
  sprite.setDepth(this.penguin!.depth - 1);
  this.physics.add.existing(sprite);
  sprite.body.setSize(1, 1);
  createAnimations(this.anims, `penguinSheet${player.nftId}`);

  return sprite;
}

export function createPlayerName(
  this: GameScene,
  sprite: Spritesheet,
  player: Player,
) {
  var style = {
    font: '6px GravityBold8',
    fill: '#00000',
    wordWrapWidth: sprite.width,
    align: 'center',
    zIndex: 30000,
  };
  const name = this.add.text(
    sprite.x,
    sprite.y - 36,
    player.nickname
      ? player.nickname
      : `${player.publicAddress.substring(
          0,
          5,
        )}...${player.publicAddress.substring(
          player.publicAddress.length - 5,
        )}`,
    style,
  );
  name.setResolution(2);
  this.physics.add.existing(name);
  name.depth = 3000;
  name.active = true;
  return name;
}

export function hideLayer(this: GameScene, layerName: string) {
  if (this.tileMap) {
    const layer = this.tileMap.getLayer(layerName);
    if (layer) {
      layer.tilemapLayer.visible = false;
      layer.tilemapLayer.setCollisionByProperty({ collides: true }, true);
    }
  }
}

export function showLayer(this: GameScene, layerName: string) {
  if (this.tileMap) {
    const layer = this.tileMap.getLayer(layerName);
    if (layer) {
      layer.tilemapLayer.visible = true;
      layer.tilemapLayer.setCollisionByProperty({ collides: true }, false);
    }
  }
}

export function removeCollides(this: GameScene, layerName: string) {
  if (this.tileMap) {
    const layer = this.tileMap.getLayer(layerName);
    if (layer) {
      layer.tilemapLayer.setCollisionByProperty({ collides: true }, false);
    }
  }
}

export function addCollides(this: GameScene, layerName: string) {
  if (this.tileMap) {
    const layer = this.tileMap.getLayer(layerName);
    if (layer) {
      layer.tilemapLayer.setCollisionByProperty({ collides: true }, true);
    }
  }
}

export function animateTiles(this: GameScene) {
  this.tileMap?.tilesets.forEach((tileset) => {
    const tileData = tileset.tileData as TilesetTileData;
    for (let tileid in tileData) {
      this.tileMap?.layers.forEach((layer) => {
        if (layer.tilemapLayer.type === 'StaticTilemapLayer') return;
        layer.data.forEach((tileRow) => {
          tileRow.forEach((tile) => {
            if (tile.index - tileset.firstgid === parseInt(tileid, 10)) {
              this.animatedTiles.push(
                new AnimatedTile(
                  tile,
                  tileData[tileid]!.animation!,
                  tileset.firstgid,
                ),
              );
            }
          });
        });
      });
    }
  });
}

import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { useGameContext } from '~/contexts/game';
import { Item } from '~/models/items/Item';
import PengLab from '../../../../../assets/images/lelabdespengs.jpg';
import { InventoryDetails } from './components/InventoryDetails';
import { Categories, InventoryHeader } from './components/InventoryHeader';

interface IInventoryProps {}

export const InventoryApp: FC<IInventoryProps> = () => {
  const { player } = useGameContext();
  const [currentCategory, setCurrentCategory] = useState<Categories>(Categories.QUEST);
  const [items, setItems] = useState<Item<any>[]>([]);

  useEffect(() => {
    if (player) {
      if (currentCategory === Categories.QUEST) {
        setItems(player.inventory.keyItems);
      } else {
        setItems([]);
      }
    }
  }, [currentCategory]);

  return (
    <InventoryContainer>
      <InventoryHeader onCategorySelect={setCurrentCategory} />
      <InventoryDetails items={items}></InventoryDetails>
    </InventoryContainer>
  );
};

const InventoryContainer = styled.div`
  background-image: url(${PengLab});
  background-position: 25%;
  background-size: 500%;
  height: 100%;
  width: 100%;
  padding-top: 6px;
`;

import styled from '@emotion/styled';
import { FC, useState } from 'react';
import { Penguin } from '~/models/penguin';
import { PengDetails } from './PengsDetails';

interface IPengAppProps {
  penguins: Penguin[];
  currentPeng: Penguin;
}

export const MyPengsApp: FC<IPengAppProps> = ({ penguins, currentPeng }) => {
  const [selectedPeng, setSelectedPeng] = useState<Penguin>();
  return (
    <PengAppContainer penguinUrl={currentPeng.image}>
      <Overlay>
        <Header>
          {selectedPeng ? (
            <>
              <div>Peng ID :</div>
              <div>{selectedPeng.id}</div>
            </>
          ) : (
            <>
              <div>TOTEL PENGS :</div>
              <div>{penguins.length}</div>
            </>
          )}
        </Header>
        {selectedPeng ? (
          <PengDetails
            unselectPeng={() => {
              setSelectedPeng(undefined);
            }}
            selectedPenguin={selectedPeng}
          />
        ) : (
          <PengList>
            {penguins.map((penguin) => (
              <PenguinSelector
                key={penguin.id}
                penguinUrl={penguin.image}
                onClick={() => setSelectedPeng(penguin)}
              />
            ))}
          </PengList>
        )}
      </Overlay>
    </PengAppContainer>
  );
};

const PengAppContainer = styled.div<{ penguinUrl: string }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-image: ${({ penguinUrl }) => `url(${penguinUrl})`};
  transition: background-image 0.2s ease-in-out;
  background-size: 170%;
  background-position: -170px;
  overflow-y: overlay;
`;

const Overlay = styled.div`
  overflow-y: overlay;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #060c217a;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 30px;
  width: 100%;
  margin-top: 10px;
  padding: 0px 16px;
  box-sizing: border-box;
  background-color: #3c3c3c58;
  font-size: 10px;
`;

const PengList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  & > * {
    margin-bottom: 20px;
  }
`;

const PenguinSelector = styled.div<{ penguinUrl: string }>`
  background-image: ${({ penguinUrl }) => `url(${penguinUrl})`};
  height: 60px;
  width: 100%;
  border-radius: 12px;
  background-position-y: -100px;
  background-size: 100%;
  border-width: 2px;
  border-color: #000000;
  border-style: solid;
  cursor: pointer;
`;

import {
  ArcadePhysics,
  Spritesheet,
  AnimationManager,
  ILayer,
  CursorKeys,
} from '../../types';
import { GameScene } from '../GameScene';
import * as helpers from './helpers';

export function createLayers(
  this: GameScene,
  layers: ILayer[],
  addedTileset: Phaser.Tilemaps.Tileset[],
  zIndex = 0,
  lastLayerName = '',
): number {
  layers.forEach((layer) => {
    const name = lastLayerName + layer.name;
    if (layer.data && layer.data.length) {
      const newLayer = this.tileMap!.createLayer(
        lastLayerName + layer.name,
        addedTileset,
      );
      newLayer.setDepth(zIndex);
      newLayer.setCollisionByProperty({ collides: true });
      addColider(this.physics, this.penguin!, newLayer);
    }
    if (layer.layers && layer.layers.length) {
      zIndex += 1;
      zIndex = createLayers.bind(this)(
        layer.layers,
        addedTileset,
        zIndex,
        name + '/',
      );
    }
    if (layer.name === 'floorLayer') {
      this.penguin!.setDepth(zIndex + 2);
      zIndex += 3;
    } else {
      zIndex += 1;
    }
  });
  return zIndex;
}

export const addColider = (
  physics: ArcadePhysics,
  sprite: Spritesheet,
  layer: Phaser.Tilemaps.TilemapLayer,
) => {
  if (sprite) {
    physics.add.collider(sprite, layer);
  }
};

export const generateAnimation = (
  name: string,
  animationManager: AnimationManager,
  sprisheetName: string,
  start: number,
  end: number,
) => {
  animationManager.create({
    key: sprisheetName + name,
    frameRate: 6,
    frames: animationManager.generateFrameNumbers(sprisheetName, {
      start,
      end,
    }),
    repeat: -1,
  });
};

export const setCamera = (
  physics: ArcadePhysics,
  cameras: Phaser.Cameras.Scene2D.CameraManager,
  playerSpritesheet: Spritesheet,
  tileMap: Phaser.Tilemaps.Tilemap,
) => {
  physics.world.setBounds(0, 0, 32 * tileMap.width, 32 * tileMap.height);
  //  ajout du champs de la caméra de taille identique à celle du monde
  cameras.main.setBounds(0, 0, 32 * tileMap.width, 32 * tileMap.height);

  // ancrage de la caméra sur le joueur
  cameras.main.startFollow(playerSpritesheet, true);
  cameras.main.setZoom(2);
};

export const getCursorKeys = (input: Phaser.Input.InputPlugin): CursorKeys => {
  // third param is emitonRepeat. can be used to listen the events instead of
  // the ugly move function from updateTools
  const space = input.keyboard.addKey('space', false);
  const shift = input.keyboard.addKey('shift', false);
  const up = input.keyboard.addKey('up', false);
  const down = input.keyboard.addKey('down', false);
  const left = input.keyboard.addKey('left', false);
  const right = input.keyboard.addKey('right', false);
  const z = input.keyboard.addKey('z', false);
  const w = input.keyboard.addKey('w', false);
  const q = input.keyboard.addKey('q', false);
  const a = input.keyboard.addKey('a', false);
  const s = input.keyboard.addKey('s', false);
  const d = input.keyboard.addKey('d', false);

  return { space, shift, left, up, right, down, z, w, q, a, s, d };
};

export function setWorld(this: GameScene) {
  this.tileMap = this.make.tilemap({
    key: this.gameSceneName,
    tileHeight: 32,
    tileWidth: 32,
  });
  // chargement du jeu de tuiles
  const tilesetsAdded = this.tilesets.map((tileset) => {
    const addedTileset = this.tileMap!.addTilesetImage(
      tileset.name,
      tileset.name,
    );

    return addedTileset;
  });

  createLayers.bind(this)(this.tiledMap!.layers, tilesetsAdded);
}

export function setPlayerPenguin(
  this: GameScene,
  startPosition: { x: number; y: number },
) {
  const penguin = this.add.sprite(
    startPosition.x,
    startPosition.y,
    '',
  ) as Spritesheet;
  this.physics.add.existing(penguin);
  penguin.setVisible(false);
  penguin.body.collideWorldBounds = true;
  penguin.body.setSize(12, 10);
  penguin.body.setOffset(10, 22);
  this.penguin = penguin;
}

export function setSound(this: GameScene) {
  this.sound.pauseOnBlur = false;
}

export function hideLayers(this: GameScene) {
  helpers.hideLayer.bind(this)('Totem/LAVA');
  helpers.hideLayer.bind(this)('Totem/LAVA2');
  helpers.hideLayer.bind(this)('Totem/LAVA3');
  helpers.hideLayer.bind(this)('Totem/FOREST');
  helpers.hideLayer.bind(this)('Totem/FORESTUP');
  helpers.hideLayer.bind(this)('Totem/FORESTUPUP');
  helpers.hideLayer.bind(this)('Totem/ICE');
  helpers.hideLayer.bind(this)('Totem/ICE1');
  helpers.hideLayer.bind(this)('Totem/ICE2');
  helpers.hideLayer.bind(this)('Totem/ICE3');
  helpers.hideLayer.bind(this)('Totem/STORM');
  helpers.hideLayer.bind(this)('Totem/STORM2');
  helpers.hideLayer.bind(this)('doors/avalabs1');
  helpers.hideLayer.bind(this)('doors/avalabs2');
  helpers.hideLayer.bind(this)('doors/avalabs3');
  helpers.hideLayer.bind(this)('doors/avalabs4');

}

import Peer from 'peerjs';
import { useState, useEffect, useCallback } from 'react';
import { Player } from '~/generated/colyseus/Player';
import { createGenericContext } from '~/helpers/createGenericContext';
import { Call } from '~/modules/game/smartphone/apps/visio/types/Call';
import { Caller } from '~/modules/game/smartphone/apps/visio/types/Caller';
import { App } from '~/modules/game/smartphone/types';
import { useAudioContext } from '../audio';
import { useAuthContext } from '../auth';
import { useSmartphoneContext } from '../smartphone';

type VisioContextType = {
  takeCall: () => void;
  callUser: (player: Player) => void;
  hangUp: () => void;
  call: Call | null;
  incomingStream: MediaStream | null;
  outgoingStream: MediaStream | null;
  toggleMuteMicrophone: () => void;
  toggleMuteCamera: () => void;
  toggleMuteSound: () => void;
  microphoneMuted: boolean;
  cameraMuted: boolean;
  soundMuted: boolean;
};

export const [useVisioContext, VisioContextProvider] =
  createGenericContext<VisioContextType>();

type Props = {
  children: React.ReactNode;
};

export const VisioProvider = ({ children }: Props) => {
  const { user } = useAuthContext();
  const { openApp, closeSmartphone, currentApp } = useSmartphoneContext();
  const { mute, unMute } = useAudioContext();

  const [call, setCall] = useState<Call | null>(null);

  const [microphoneMuted, setMicrophoneMuted] = useState(false);
  const [cameraMuted, setCameraMuted] = useState(true);

  const [soundMuted, setSoundMuted] = useState(false);

  const [incomingStream, setIncomingStream] = useState<MediaStream | null>(
    null,
  );
  const [outGoingStream, setOutGoingStream] = useState<MediaStream | null>(
    null,
  );

  const [peer, setPeer] = useState<Peer>();

  const hangUp = useCallback(() => {
    if (call) {
      call.mediaConnection.close();
      incomingStream?.getTracks().forEach((track) => track.stop());
      outGoingStream?.getTracks().forEach((track) => track.stop());
      setCall(null);
      setIncomingStream(null);
      setOutGoingStream(null);
      unMute();
    }
    closeSmartphone();
  }, [call, closeSmartphone, incomingStream, outGoingStream, unMute]);

  const takeCall = useCallback(async () => {
    if (call) {
      const userStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      userStream.getVideoTracks()[0].enabled = false;
      setCameraMuted(true);
      setOutGoingStream(userStream);
      call.mediaConnection.answer(userStream);
    }
  }, [call]);

  const callUser = useCallback(
    async (player: Player) => {
      if (peer) {
        const userStream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        userStream.getVideoTracks()[0].enabled = false;
        setCameraMuted(true);
        setOutGoingStream(userStream);
        const userMetadata: Caller = {
          publicAddress: user!.publicAddress,
          nickname: user!.nickname ?? undefined,
          nftTokenId: user?.activeNft ? +user.activeNft.tokenId : undefined,
        };
        const mediaConnection = peer.call(player.publicAddress, userStream, {
          metadata: userMetadata,
        });
        setCall({
          direction: 'outgoing',
          mediaConnection,
          callerMetadata: {
            publicAddress: player.publicAddress,
            nickname: player.nickname ?? undefined,
            nftTokenId: player.nftId,
          },
        });
      }
    },
    [peer, user],
  );

  const toggleMuteMicrophone = useCallback(() => {
    if (outGoingStream) {
      const audioTrack = outGoingStream.getAudioTracks()[0];
      audioTrack.enabled = !audioTrack.enabled;
      setMicrophoneMuted(!audioTrack.enabled);
    }
  }, [outGoingStream]);

  const toggleMuteCamera = useCallback(() => {
    if (outGoingStream) {
      const videoTrack = outGoingStream.getVideoTracks()[0];
      videoTrack.enabled = !videoTrack.enabled;
      setCameraMuted(!videoTrack.enabled);
    }
  }, [outGoingStream]);

  const toggleMuteSound = useCallback(() => {
    if (incomingStream) {
      const audioTrack = incomingStream.getAudioTracks()[0];
      audioTrack.enabled = !audioTrack.enabled;
      setSoundMuted(!audioTrack.enabled);
    }
  }, [incomingStream]);

  useEffect(() => {
    if (user?.publicAddress && !peer) {
      setPeer(
        new Peer(user.publicAddress, {
          host: 'peerjs.pengfamily.io',
          port: 443,
          secure: true,
        }),
      );
    }
  }, [user?.publicAddress, peer]);

  useEffect(() => {
    if (peer) {
      peer.on('call', (mediaConnection) => {
        setCall({
          direction: 'incoming',
          mediaConnection,
          callerMetadata: mediaConnection.metadata,
        });
      });
      peer.on('disconnected', () => {
        peer.reconnect();
      });
    }
  }, [peer]);

  useEffect(() => {
    if (call?.mediaConnection.peerConnection) {
      const connectionEventListner = () => {
        if (
          ['closed', 'disconnected'].includes(
            call.mediaConnection.peerConnection.connectionState,
          )
        ) {
          call.mediaConnection.peerConnection.removeEventListener(
            'connectionstatechange',
            connectionEventListner,
          );
          hangUp();
        }
      };
      call.mediaConnection.peerConnection.addEventListener(
        'connectionstatechange',
        connectionEventListner,
      );
    }
  }, [call?.mediaConnection.peerConnection, hangUp]);

  useEffect(() => {
    if (call) {
      call.mediaConnection.on('stream', (callerStream) => {
        setIncomingStream(callerStream);
        mute();
      });

      if (currentApp?.app !== App.VISIO) {
        openApp(App.VISIO);
      }
    }
  }, [call, currentApp?.app, mute, openApp]);

  return (
    <VisioContextProvider
      value={{
        takeCall,
        callUser,
        hangUp,
        call,
        incomingStream,
        outgoingStream: outGoingStream,
        toggleMuteCamera,
        toggleMuteMicrophone,
        toggleMuteSound,
        cameraMuted,
        microphoneMuted,
        soundMuted,
      }}
    >
      {children}
    </VisioContextProvider>
  );
};

import { Button } from 'evergreen-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { Penguin } from '~/models/penguin';
import { ReactComponent as LeftArrowSvg } from '../../../../../assets/icons/leftArrow.svg';
import { useGameContext } from '~/contexts/game';
import { useUpdateUserMutation } from '~/generated/graphql/hooks';
import { useAuthContext } from '~/contexts/auth';

interface IPengDetailsProps {
  selectedPenguin: Penguin;
  unselectPeng: () => void;
}

export const PengDetails: FC<IPengDetailsProps> = ({
  selectedPenguin,
  unselectPeng,
}) => {
  const { setCurrentPenguin, currentMap } = useGameContext();
  const { user } = useAuthContext();
  const [updateUser] = useUpdateUserMutation();
  return (
    <PengDetailsContainer>
      <Header>
        <LeftArrowIcon onClick={() => unselectPeng()} />
      </Header>
      <PortraitAndCo>
        <Portrait src={selectedPenguin.image} />
        <Infos>
          <Info>{`Peng Id: ${selectedPenguin.name}`}</Info>
          <Info>{`Dna: ${selectedPenguin.metadata.attributes[7].value}`}</Info>
          <ChangePeng
            onClick={async () => {
              await updateUser({
                variables: { id: user!.id, nftId: String(selectedPenguin.id) },
              });
              setCurrentPenguin(selectedPenguin);
              currentMap?.changeAvatar(selectedPenguin.id);
            }}
            appearance="primary"
          >
            {' '}
            Choose this peng{' '}
          </ChangePeng>
        </Infos>
      </PortraitAndCo>
      <PropertiesTitle>Properties :</PropertiesTitle>
      <Properties>
        <PropertiesCol>
          <Property>
            <PropertyTitle>Head :</PropertyTitle>
            <div>{selectedPenguin.metadata.attributes[4].value}</div>
          </Property>
          <Property>
            <PropertyTitle>Body :</PropertyTitle>
            <div>{selectedPenguin.metadata.attributes[3].value}</div>
          </Property>
          <Property>
            <PropertyTitle>Mouth :</PropertyTitle>
            <div>{selectedPenguin.metadata.attributes[5].value}</div>
          </Property>
        </PropertiesCol>
        <PropertiesCol>
          <Property>
            <PropertyTitle>BodyColor :</PropertyTitle>
            <div>{selectedPenguin.metadata.attributes[1].value}</div>
          </Property>
          <Property>
            <PropertyTitle>Glasses :</PropertyTitle>
            <div>{selectedPenguin.metadata.attributes[4].value}</div>
          </Property>
          <Property>
            <PropertyTitle>Lefthand :</PropertyTitle>
            <div>{selectedPenguin.metadata.attributes[6].value}</div>
          </Property>
        </PropertiesCol>
      </Properties>
    </PengDetailsContainer>
  );
};

const PengDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
  height: 100%;
  border-radius: 8px;

  background-color: #140507ab;
  box-sizing: border-box;
  padding: 4px 8px;

  & > * {
    margin: 8px 0px;
  }
`;

const Header = styled.div`
  display: flex;
  height: 20px;
  width: 100%;
`;

const PortraitAndCo = styled.div`
  display: flex;
  width: 100%;
`;

const PropertiesTitle = styled.div`
  display: flex;
  width: 100%;
  font-size: 10px;
`;

const Properties = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
`;

const LeftArrowIcon = styled(LeftArrowSvg)`
  min-width: 18px;
  max-width: 18px;
  min-height: 18px;
  max-height: 18px;
  cursor: pointer;
`;

const Portrait = styled.img`
  height: 100px;
  border-radius: 12px;
  margin-left: 20px;
`;

const PropertiesCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  height: 100%;
`;

const Property = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  background: rgba(19, 6, 7, 0.74);
  font-size: 8px;

  width: 100px;
  height: 60px;

  border-radius: 8px;
`;

const PropertyTitle = styled.div`
  margin-left: 20px;
  font-size: 6px;
`;

const Infos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
`;
const Info = styled.div`
  font-size: 8px;
  width: 100%;
`;

const ChangePeng = styled(Button)`
  background: #1f69b6;
  color: white;
  font-family: 'GravityBold8';
  font-size: 7px;
  width: 110px;
  border: none;
  border-radius: 16px;
`;

import styled from '@emotion/styled';

export const Policy = () => {
  return (
    <PolicyContainer>
      <h1> PengVerse Privacy Policy </h1>
      <Separator />
      GDPR - Privacy-By-Design and Record of Processing Activities
      <Separator />
      We are committed to a lawful, fair and transparent processing of personal
      data, adhering to the EU General Data Protection Regulation (GDPR). This
      privacy policy documents our general privacy-by-design considerations
      (articles 5, 25 and 26 GDPR) and records our processing activities
      (article 30(1) GDPR). This privacy policy describes the type of
      information we may collect from you or that you may provide when you visit
      the website https://www.pengfamily.io/ , all platforms and services
      offered thereon, and through our mobile applications (collectively, our
      “Website”) and our practices for collecting, using, maintaining,
      protecting, and disclosing that information.
      <Separator />
      Company name: Peng Labs (“PL”), a Simplified Joint Stock Company with
      capital of € 1000, headquartered in Paris (75001), 229 rue Saint Honoré,
      registered in the Paris Trade and Companies Register under number 909 398
      828, Mail: privacy@pengfamily.io
      <Separator />
      *Not formally a DPO under the GDPR. Note that the below uses “you” and
      “us” in the NRS of the paragraphs. 1 to 11 to allow inclusion without
      modification in our information notices provided to data subjects.
      <Separator />
      Our Website is not intended for children under 18 years of age. No one
      under age 18 may provide any personal information to or on the Website. We
      do not knowingly collect personal information from children under 18. If
      you are under 18, do not use or provide any information on this Website or
      on or through any of its features/register on the Website, make any
      purchases through the Website, use any of the interactive or public
      comment features of this Website or provide any information about yourself
      to us, including your name, address, telephone number, email address, or
      any screen name or user name you may use. If we find out we have collected
      or received personal information from a child under 18 without
      verification of parental consent, we will delete that information. If you
      believe we might have any information from or about a child under 18,
      please contact us at privacy@pengfamily.io
      <Separator />
      1. We process the following (categories of) personal data when you play
      our games, interact with or participate in our gaming platforms, connect
      to a social networking site through our games, register an account, enter
      a promo code, or place an order:
      <Separator />
      Your IP address, and the country where you are located. Your device type
      (for example, PC or Mac, iPhone 7, Samsung Galaxy S7) and operating system
      version.
      <Separator />
      For analytics, when you installed and played the game, your progress
      throughout the game, and events that are connected to your game-play. We
      generally cannot identify you with this data, but treat it the same way as
      personal data.
      <Separator />
      2. What (purposes) do we use the data for? We use this personal data to
      perform analytics for our games and to allow third-parties to sell, buy
      and deliver advertising in these games. We use your IP address to comply
      with legal obligations, specifically to comply with VAT reporting
      obligations
      <Separator />
      We use social network data to show you personalized content and
      synchronize your online progression across multiple devices. For example,
      we might show a list with only your friends in it, or show your name or
      avatar on an in-game object.
      <Separator />
      3. What is our legal basis for the processing? For both analytics and
      advertising, we base the processing of personal data on the legitimate
      interest of PL. For analytics, this is our ability to analyze how players
      progress through our games to make them better, and to know our player
      base on an aggregate level. For advertising, this is our ability to be
      able to off-set development costs and risk with advertising income. We
      also process your personal data for the legitimate business interest of
      complying with a legal obligation — we are subject to certain legal
      requirements which may require us to process your personal data. We may
      also be obliged by law to disclose your personal data to a regulatory body
      or law enforcement agency. For the third-parties we use, their legitimate
      interest is to be able to offer us their services, and for advertising
      third-parties and advertisers to be able to show you advertising.
      <Separator />
      4. Where do we obtain the data from (sources)? We obtain data from you
      (when you provide it directly through the Website) and from our
      third-party services providers:
      <Separator />
      Google Analytics
      <Separator />
      Google Tags
      <Separator />
      5. For how long and where do we store data? We store the personal data
      that we collect for up to 5 years, and only aggregate data after that
      time. Aggregation data means that we can no longer look at data for
      individual players. We store the data with our third-party service
      provider, Amazon AWS. When a third-party service provider is a controller,
      they may keep data as well, either for a shorter or longer period.
      <Separator />
      6. What safeguards have we taken to protect data? Taking into account the
      nature, scope, context and purpose of the processing, we have taken the
      following technical and organizational measures:
      <Separator />
      Access to our database and user accounts by third-party service providers
      is protected with access control.
      <Separator />
      Access to the database is limited, not all staff in our organization have
      full access.
      <Separator />
      Data sent from our games is encrypted in transit where technically
      possible. We do not actively monitor the security of our databases and
      need to rely on our processors to notify us of any data breaches.
      <Separator />
      7. Who do we share data with? We may disclose aggregated data and any
      information that does not identify an individual without restriction.
      <Separator />
      We may also disclose personal information that we collect or you provide:
      <Separator />
      To our subsidiaries and affiliates.
      <Separator />
      To contractors, service providers, and other third parties we use to
      support our business.
      <Separator />
      To a buyer or other successor in the event of a merger, reorganization,
      dissolution, or other transfer or sale of some or all of PL’s assets.
      <Separator />
      To fulfill the purposes for which you provide it.
      <Separator />
      For any other purpose disclosed by us when you provide the information.
      <Separator />
      With your consent.
      <Separator />
      We share data with the following third-parties. These are either
      controllers, or processors acting on our behalf. We have linked to their
      respective policies and given a basic description of what they do:
      <Separator />
      Google Analytics
      <Separator />
      Google Tags
      <Separator />
      We may also disclose your personal information:
      <Separator />
      To comply with any court order, law, or legal process, including to
      respond to any government or regulatory request.
      <Separator />
      To enforce or apply our Terms of Use and other agreements, including for
      billing and collection purposes.
      <Separator />
      If we believe disclosure is necessary or appropriate to protect the
      rights, property, or safety or PL our end users, or others.
      <Separator />
      8. Transfer of your personal data You should also know that your personal
      data may be transferred from your home country to third parties. If you
      are an EU resident, this could imply that we also transfer data to
      countries that do not offer an adequate level of protection for your
      personal data. When we transfer this data, we will do so only when we have
      put in place appropriate safeguards to protect your personal data. Please
      contact us to get a copy of the safeguards that we have put in place. Our
      contact details are at the end of this privacy policy.
      <Separator />
      9. Your Rights You have the right to request access to your personal data
      that we process. You also have the right to:
      <Separator />
      Rectify incorrect personal data or erase it in certain circumstances.
      <Separator />
      Restrict or object to the processing of your personal data.
      <Separator />
      Receive your data so that you can use it elsewhere (data portability).
      <Separator />
      If you wish to exercise any of the above rights, please send us an email
      at privacy@pengfamily.io. You may also update your personal data in your
      profile on PengVerse via the settings. Finally, you have the right to
      lodge a complaint with a supervisory authority. If you do not know who
      your supervisory authority is, please contact us and we will tell you.
      <Separator />
      10. How to contact us You can contact us with any questions you may have
      about the processing of your personal data or the use of cookies and
      similar technologies by us, or to exercise the above rights: Web:
      http://pengfamily.io
      <Separator />
      Address: Peng Labs Paris (75001), 229 rue Saint Honoré, Mail:
      privacy@pengfamily.io
      <Separator />
      11. Version information: We last updated this notice on April 28, 2022.
      <Separator />
    </PolicyContainer>
  );
};

const PolicyContainer = styled.div`
  background-color: #031920;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  text-align: left;
  padding: 32px;
  box-sizing: border-box;
`;

const Separator = styled.div`
  margin-top: 16px;
  display: flex;
`;

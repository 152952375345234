import { ReactComponent as SettingsSVG } from '../../../assets/icons/settings.svg';
import { ReactComponent as BagSVG } from '../../../assets/icons/bag.svg';
import CompassPng from '../../../assets/icons/compass.png';
import styled from '@emotion/styled';
import { FC } from 'react';
import { App } from './types';
import { useSmartphoneContext } from '~/contexts/smartphone';
import { useGameContext } from '~/contexts/game';

export const SmartphoneBottom: FC = () => {
  const { openApp } = useSmartphoneContext();
  const { currentPenguin } = useGameContext();

  return (
    <SmartphoneBottomContainer>
      <AppButton onClick={() => openApp(App.SETTINGS)} key={'settings'}>
        <SettingsIcon />
      </AppButton>
      <PenguinButton onClick={() => openApp(App.MY_PENGS_APP)} key={'my_pengs'}>
        <PengIcon src={currentPenguin?.image} />
      </PenguinButton>
      <AppButton onClick={() => openApp(App.INVENTORY)} key={'inventory'}>
        {<BagIcon />}
      </AppButton>
      <AppButton key={'map'}>
        <CompassIcon src={CompassPng} />
      </AppButton>
    </SmartphoneBottomContainer>
  );
};

const SmartphoneBottomContainer = styled.div`
  background-color: rgba(228, 228, 228, 0.6);
  height: 60px;
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  align-items: center;

  justify-content: space-around;
`;

const AppButton = styled.div`
  display: flex;
  align-items: center;

  justify-content: center;
  background-color: #0f2c3b;
  padding: 8px;

  width: 30px;
  height: 30px;
  border-radius: 10px;
  cursor: pointer;
`;

const SettingsIcon = styled(SettingsSVG)`
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
`;

const CompassIcon = styled.img`
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
`;

const PenguinButton = styled(AppButton)`
  padding: 4px;
  width: 38px;
  height: 38px;
`;

const PengIcon = styled.img`
  min-width: 38px;
  max-width: 38px;
  min-height: 38px;
  max-height: 38px;
  border-radius: 8px;
`;

const BagIcon = styled(BagSVG)`
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
`;

import { ReactComponent as Title } from './../../assets/icons/Title.svg';
import { ReactComponent as Logo } from './../../assets/icons/Logo.svg';
import { ReactComponent as Burger } from './../../assets/icons/Burger.svg';
import styled from '@emotion/styled';
import { Pane, majorScale } from 'evergreen-ui';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

interface IHeaderMobileViewProps {
  setHeaderHeight: (height: number) => void;
}

export const HeaderMobileView: React.FC<IHeaderMobileViewProps> = ({
  setHeaderHeight,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  let navigate = useNavigate();

  useEffect(() => {
    if (headerRef && headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, [headerRef, setHeaderHeight]);

  return (
    <Container
      ref={headerRef}
      height={majorScale(6)}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <LogoContainer onClick={() => navigate('/')}>
        <LogoPengFam />
        <TitlePengFam />
      </LogoContainer>
      {/* must be commented until there is other pages or game is fine on mobile */}
      {/* <BurgerContainer>
        <BurgerIcon />
      </BurgerContainer> */}
    </Container>
  );
};

const Container = styled(Pane)`
  max-width: 100vw;
  padding: 0px 8px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitlePengFam = styled(Title)`
  fill: white;
  cursor: pointer;
  height: 12px;
  max-height: 12px;
  width: 110px;
  max-width: 110px;

  :hover {
    fill: white;
  }
  :focus {
    fill: white;
  }
  :active {
    fill: white;
  }
`;

const BurgerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoPengFam = styled(Logo)`
  fill: white;
  cursor: pointer;
  height: 20px;
  max-height: 20px;
  width: 20px;
  max-width: 20px;
  margin-right: 8px;

  :hover {
    fill: white;
  }
  :focus {
    fill: white;
  }
  :active {
    fill: white;
  }
`;

const BurgerIcon = styled(Burger)`
  * {
    fill: white;
  }
  cursor: pointer;
  height: 30px;
  max-height: 30px;
  width: 30px;
  max-width: 30px;
  width: fit-content;
  margin-right: 8px;

  :hover {
    fill: white;
  }
  :focus {
    fill: white;
  }
  :active {
    fill: white;
  }
`;

export const app = {
  url: process.env.REACT_APP_URL,
  stage: process.env.REACT_APP_STAGE ?? 'development',
} as const;

export const auth = {
  storageKey: 'pengverse-auth',
} as const;

export const api = {
  httpUrl: process.env.REACT_APP_API_HTTP_URL!,
  wsUrl: process.env.REACT_APP_API_WS_URL!,
} as const;

export const gameServer = {
  url: process.env.REACT_APP_GAME_SERVER_URL!,
} as const;

export const imageBucket = {
  url: process.env.REACT_APP_IMAGE_BUCKET_URL!,
} as const;

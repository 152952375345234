import styled from '@emotion/styled';
import { AudioProvider } from '~/contexts/audio';
import { GameProvider } from '~/contexts/game';
import { SmartphoneProvider } from '~/contexts/smartphone';
import { Game } from '../game/Game';

interface IPlayProps {}

export const Play: React.FC<IPlayProps> = () => {
  return (
    <Container
      id="game-container"
      style={{
        width: '100%',
        border: 'none',
      }}
    >
      <GameProvider>
        <AudioProvider>
          <SmartphoneProvider>
            <Game />
          </SmartphoneProvider>
        </AudioProvider>
      </GameProvider>
    </Container>
  );
};

const Container = styled.div``;

import styled from '@emotion/styled';
import { JaaSMeeting } from '@jitsi/react-sdk';
import { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useGesture } from 'react-use-gesture';
import { useAudioContext } from '~/contexts/audio';
import { useAuthContext } from '~/contexts/auth';
import { GameScene } from '../phaser/game-scene/GameScene';
import { IjitsiEvent } from '../phaser/types';

const WEBSITE_INITIAL_WIDTH = 700;

type Props = { gameScene?: GameScene };
export const Jitsi = ({ gameScene }: Props) => {
  const { user } = useAuthContext();
  const { mute, unMute } = useAudioContext();
  const [_jitsiRoom, _setJitsiRoom] = useState<string | null>(null);

  const [{ width: previewWidth }, setPreviewWidth] = useSpring(
    () => ({
      width: _jitsiRoom ? WEBSITE_INITIAL_WIDTH : 0,
    }),
    [_jitsiRoom],
  );

  const [{ width: websiteWidth }, setWebsiteWidth] = useSpring(
    () => ({
      width: _jitsiRoom ? WEBSITE_INITIAL_WIDTH : 0,
    }),
    [_jitsiRoom],
  );

  const [dragging, setDragging] = useState(false);

  const bind = useGesture({
    onDrag: ({ movement: [mx], active }) => {
      setDragging(active);
      setPreviewWidth({ width: websiteWidth.get() - mx, immediate: active });
    },
    onDragEnd: ({ movement: [mx], active }) => {
      !active && setWebsiteWidth({ width: websiteWidth.get() - mx });
    },
  });

  useEffect(() => {
    gameScene?.events.on(
      'openJitsi',
      ({ jitsiConfig, jitsiRoom }: IjitsiEvent) => {
        mute();
        _setJitsiRoom(jitsiRoom);
      },
    );

    gameScene?.events.on('closeJitsi', () => {
      setPreviewWidth({ width: 0 });
      setWebsiteWidth({ width: 0 });
      setTimeout(() => {
        unMute();
        _setJitsiRoom(null);
      }, 300);
    });
  });
  if (!gameScene || !_jitsiRoom) {
    return null;
  }
  return (
    <>
      <ScreenSeparator
        {...bind()}
        style={{
          transform: previewWidth.to((w) => `translateX(${-w}px)`),
        }}
      >
        <GrabHandle>
          <GrabHandleBar />
          <GrabHandleBar />
          <GrabHandleBar />
        </GrabHandle>
      </ScreenSeparator>
      <WebsiteWrapper
        style={{
          width: websiteWidth.to((w) => `${w}px`),
        }}
      >
        <JassWrapper>
          <JaaSMeeting
            appId={'vpaas-magic-cookie-97996c4bae934c7186da405d723f5384'}
            roomName={_jitsiRoom}
            userInfo={{
              displayName: user!.nickname || user!.publicAddress,
              email: user!.publicAddress,
            }}
            configOverwrite={{
              prejoinPageEnabled: true,
              disableThirdPartyRequests: true,
              disableLocalVideoFlip: false,
              backgroundAlpha: 0.5,
              websiteWidth: '100%',
              innerHeight: '100%',
            }}
            interfaceConfigOverwrite={{
              VIDEO_LAYOUT_FIT: 'nocrop',
              MOBILE_APP_PROMO: false,
              TILE_VIEW_MAX_COLUMNS: 4,
            }}
            onApiReady={(externalApi) => {}}
            getIFrameRef={(_iframeRef) => {
              _iframeRef.style.width = '100%';
              _iframeRef.style.height = '100%';
            }}
          />
        </JassWrapper>
      </WebsiteWrapper>
      <ResizePreview
        style={{
          width: previewWidth.to((w) => `${w}px`),
          display: dragging ? 'block' : 'none',
        }}
      />
    </>
  );
};

const WebsiteWrapper = styled(animated.div)`
  margin-left: 22px;
`;

const ResizePreview = styled(animated.div)`
  top: 0;
  right: 0;
  height: 100%;
  position: absolute;
  opacity: 0.5;
  background-color: #364e91;
`;

const ScreenSeparator = styled(animated.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  width: 22px;
  height: 100%;
  background-color: #587a8b;
  box-shadow: -1px -1px 1px 0px #ffffff;
`;

const GrabHandle = styled.div`
  display: flex;
`;

const GrabHandleBar = styled.div`
  margin: 0 1px;
  width: 3px;
  height: 16px;
  border: 1px solid #525252;
  box-shadow: inset -1px -1px 1px 0px #ffffff;
  border-radius: 2px;
`;

const JassWrapper = styled.div`
  height: 100%;
`;

import styled from '@emotion/styled';
import { Button } from '~/components/button/Button';

export const Root = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input``;

type TextInputProps = {
  light?: boolean;
  align?: 'right' | 'left' | 'center';
};
export const TextInput = styled(Input)<TextInputProps>`
  font-family: 'GravityBold8';
  border: none;
  text-align: ${({ align }) => (align ? align : 'left')};
  background-color: ${({ light }) => (light ? '#white' : 'none')};
  font-size: 18px;
  border-radius: 0px;
  
  :focus {
    border-radius: 0px;
    outline-color: ${({ light }) => (light ? '#black' : 'none')};
  }
  :focus-visible {
    border-radius: 0px;
    outline-color: ${({ light }) => (light ? '#black' : 'none')};
  }
  padding: 4px;
`;
TextInput.defaultProps = { type: 'text' };

export const Select = styled.select`
  padding: 12px;
  border: 1px solid #eaeaea;
  option {
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export const SubmittButton = styled(Button)`
  font-family: 'GravityBold8';
  font-size: 10px;
  border-radius: 0px;
`;
SubmittButton.defaultProps = {
  type: 'submit',
};

import { useState } from 'react';
import {
  useLoginMutation,
  useMeQuery,
  useNonceLazyQuery,
  useSignoutMutation,
} from '~/generated/graphql/hooks';
import { UserFieldsFragment } from '~/generated/graphql/operations';
import { createGenericContext } from '~/helpers/createGenericContext';
import { auth } from '~/config';
import useMetamask from '~/contexts/metamask';
import * as Sentry from '@sentry/react';

type AuthContextType = {
  user?: UserFieldsFragment;
  signin: () => Promise<void>;
  signout: () => void;
};

export const [useAuthContext, AuthContextProvider] =
  createGenericContext<AuthContextType>();

type Props = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: Props) => {
  const { connectToWeb3, disconnectWeb3 } = useMetamask();
  const [user, setUser] = useState<UserFieldsFragment | undefined>();
  useMeQuery({
    onCompleted: (data) => {
      setUser(data.me ?? undefined);
      if (data.me) {
        connectToWeb3();
      }
    },
    skip: !localStorage.getItem(auth.storageKey),
  });

  const [getNonce] = useNonceLazyQuery();

  const [login] = useLoginMutation({
    onCompleted: async (data) => {
      localStorage.setItem(auth.storageKey, data.login.token);
    },
  });

  const [_signout] = useSignoutMutation({
    onCompleted: async (data) => {
      localStorage.removeItem(auth.storageKey);
      setUser(undefined);
      disconnectWeb3();
    },
  });

  const signout = () => {
    _signout();
  };

  const signin = async () => {
    const { web3, publicAddress } = await connectToWeb3();
    try {
      if (!web3 || !publicAddress) {
        return;
      }
      const { data: nonceData } = await getNonce({
        variables: { publicAddress },
      });
      const signature = await web3.eth.personal.sign(
        nonceData!.nonce.nonce,
        publicAddress,
        '',
      );
      const { data: authData } = await login({
        variables: { signature, publicAddress },
      });
      setUser(authData?.login.user);
      Sentry.setUser(authData?.login.user ?? null);
    } catch (err) {
      disconnectWeb3();
    }
  };

  return (
    <AuthContextProvider value={{ user, signin, signout }}>
      {children}
    </AuthContextProvider>
  );
};

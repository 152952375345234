import styled from '@emotion/styled';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import ReactTwitchEmbedVideo from 'react-twitch-embed-video';
import { useAudioContext } from '~/contexts/audio';

interface IDraggablePosition {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
}
interface ITwitchProps {
  channel: string;
  viewportRef: React.RefObject<HTMLDivElement>;
}

export const Twitch: FC<ITwitchProps> = ({ channel, viewportRef }) => {
  const { isPlaying, stopMusic, startCurrentSong } = useAudioContext();
  const twitchRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<IDraggablePosition>({
    right: 0,
    left: 0,
    bottom: 0,
    top: 0,
  });
  const [init, setInit] = useState(false);
  const [dragMode, setDragMode] = useState(false);

  useEffect(() => {
    if (twitchRef.current && !init) {
      setPosition({
        right: 0,
        top: 0,
      });

      setInit(true);
    }
  }, [twitchRef, init]);

  useEffect(() => {
    if (isPlaying) {
      stopMusic();
    }
  }, [isPlaying, stopMusic]);

  useEffect(() => {
    return () => {
      if (!isPlaying) {
        startCurrentSong();
      }
    };
  }, [isPlaying, startCurrentSong]);

  const update = useCallback(
    (e: MouseEvent) => {
      if (twitchRef.current) {
        setPosition({
          left: e.pageX - 16,
          top: e.pageY - 16,
        });
      }
    },
    [twitchRef],
  );

  useEffect(() => {
    if (dragMode) {
      window.addEventListener('mousemove', update);
    } else {
      window.removeEventListener('mousemove', update);
    }
  }, [dragMode, update]);

  return (
    <TwitchContainer dragMode={dragMode ? 1 : 0} style={{ ...position }}>
      <Drag
        dragMode={dragMode ? 1 : 0}
        onClick={() => {
          viewportRef.current!.style.cursor = dragMode ? 'initial' : 'none';
          setDragMode(!dragMode);
        }}
      />
      <VideoContainer ref={twitchRef}>
        <ReactTwitchEmbedVideo
          width={'400px'}
          height={'280px'}
          layout="video"
          theme="dark"
          channel={channel}
        />
      </VideoContainer>
    </TwitchContainer>
  );
};

const TwitchContainer = styled.div<{ dragMode: 1 | 0 }>`
  cursor: ${({ dragMode }) => (dragMode ? 'none' : 'cursor')};
  position: absolute;
  width: fit-content;
  height: fit-content;
  background-color: #161616d1;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
`;

const Drag = styled.div<{ dragMode: 1 | 0 }>`
  cursor: ${({ dragMode }) => (dragMode ? 'none' : 'grab')};
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
`;

const VideoContainer = styled.div`
  border: solid 1px black;
`;

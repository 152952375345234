import { InMemoryCache } from '@apollo/client';
import { TypedTypePolicies } from '~/generated/graphql/helpers';
import * as fieldTypePolicies from './fieldTypePolicies';

const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {},
  },
  PaginatedMessages: {
    fields: {
      items: fieldTypePolicies.agregate(
        fieldTypePolicies.AgregationOrder.ADD_AT_BOTTOM,
        false,
      ),
    },
  },
  Conversation: {
    fields: {
      messages: {
        keyArgs: ['id'],
      },
    },
  },
};

export const cache = new InMemoryCache({
  typePolicies,
});

import styled from '@emotion/styled';
import { FC } from 'react';
import PengWorksBanner from '../../../../../assets/images/error404penguins.jpeg';
import { AudioSettings } from './Components/Audio/Audio';
import { Category } from './Components/Category';

interface ISettingsAppProps {}

export const SettingsApp: FC<ISettingsAppProps> = () => {
  return (
    <Wrapper>
      <BackDrop>
        <Category title="sound settings">
          <AudioSettings />
        </Category>
      </BackDrop>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #0b1a22;
  overflow: hidden;
  background-image: url(${PengWorksBanner});
  background-position: 30%;
  background-size: 500%;
`;

const BackDrop = styled.div`
  height: 100%;
  backdrop-filter: contrast(0.5);
  display: flex;
  flex-direction: column;
  padding: 36px 18px 36px 0px;
`;

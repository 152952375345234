import styled from '@emotion/styled';
import { FC } from 'react';
import { Item } from '~/models/items/Item';

interface IInventoryDetailsProps {
  items: Item<any>[];
}

export const InventoryDetails: FC<IInventoryDetailsProps> = ({ items }) => {
  return (
    <InventoryDetailsContainer>
      <Scrollable>
        {items.map((i) => {
          return (
            <ItemContainer key={i.name}>
              <ItemPicture
                placeholder={i.image}
                src={i.animation_url || i.image}
              />
            </ItemContainer>
          );
        })}
      </Scrollable>
    </InventoryDetailsContainer>
  );
};

const InventoryDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  max-height: calc(100% - 70px);
  overflow: overlay;
`;

const ItemContainer = styled.div`
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #0a1518cc;
  margin-bottom: 10px;
`;

const ItemPicture = styled.img`
  min-width: 80px;
  max-width: 80px;
  min-height: 80px;
  max-height: 80px;
  border-radius: 8px;
`;

const Scrollable = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: start;
  flex-wrap: wrap;
  padding: 10px 0px;
`;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/fonts/GravityBold8.ttf';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { app } from '~/config';
import { history } from '~/history';

if (app.stage !== 'development') {
  Sentry.init({
    dsn: 'https://54f41d42c83649fe9cd0b22df5a13358@o1218847.ingest.sentry.io/6360838',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: app.stage,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

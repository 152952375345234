import { useCallback, useEffect, useState } from 'react';
import { createGenericContext } from '~/helpers/createGenericContext';
import { useLocalStorage } from 'react-use';

type AudioMusicType = {
  volume: number;
  setVolume: (volume: number) => void;
  effectVolume: number;
  setEffectVolume: (volume: number) => void;
  playLoadingMusic: () => void;
  stopLoadingMusic: () => void;
  playMusic: (src: string, musicName: string, loop?: boolean) => void;
  startCurrentSong: () => void;
  stopMusic: () => void;
  mute: () => void;
  unMute: () => void;
  isPlaying: boolean;
};

type Props = {
  children: React.ReactNode;
};

export const [useAudioContext, AudioContextProvider] =
  createGenericContext<AudioMusicType>();

export const AudioProvider = ({ children }: Props) => {
  const [muted, setMuted] = useState(false);
  const [currentMusic, setCurrentMusic] = useState<string>();
  const [audio] = useState<HTMLAudioElement>(new Audio());
  const [volume = 0.5, _setVolume] = useLocalStorage<number>(
    'music-volume',
    0.5,
  );
  const [effectVolume = 0.5, _setEffectVolume] = useLocalStorage<number>(
    'effect-volume',
    0.5,
  );

  const mute = useCallback(() => {
    setMuted(true);
    audio.volume = 0;
  }, [audio]);

  const unMute = useCallback(() => {
    setMuted(false);
    audio.volume = volume;
  }, [audio, volume]);

  const stopMusic = useCallback(() => {
    if (audio && currentMusic) {
      audio.pause();
      setCurrentMusic(undefined);
    }
  }, [audio, currentMusic]);

  const playMusic = useCallback(
    async (src: string, musicName: string, loop?: boolean) => {
      if (currentMusic !== musicName) {
        stopMusic();
        audio.loop = !!loop;
        audio.src = src;
        audio.volume = muted ? 0 : volume;
        audio.load();
        audio.play();
        setCurrentMusic(musicName);
      }
    },
    [currentMusic, stopMusic, audio, muted, volume],
  );

  const startCurrentSong = useCallback(() => {
    if (currentMusic && audio) {
      audio.play();
    }
  }, [currentMusic, audio]);

  const playLoadingMusic = useCallback(() => {
    playMusic(
      'https://storage.googleapis.com/peng-music/Shoppingz.wav',
      'Shoppingz',
      true,
    );
  }, [playMusic]);

  const stopLoadingMusic = useCallback(() => {
    if (currentMusic === 'Shoppingz') {
      stopMusic();
    }
  }, [stopMusic, currentMusic]);

  const setVolume = useCallback(
    (vol: number) => {
      _setVolume(vol / 100);
      setMuted(false);
    },
    [_setVolume],
  );

  const setEffectVolume = useCallback(
    (effectVol: number) => {
      _setEffectVolume(effectVol / 100);
      setMuted(false);
    },
    [_setEffectVolume],
  );

  useEffect(() => {
    if (audio) {
      audio.volume = volume;
    }
  }, [volume, audio]);

  return (
    <AudioContextProvider
      value={{
        volume: volume * 100,
        setVolume,
        playMusic,
        startCurrentSong,
        stopMusic,
        playLoadingMusic,
        stopLoadingMusic,
        effectVolume: effectVolume * 100,
        setEffectVolume,
        mute,
        unMute,
        isPlaying: audio ? !audio.paused : false,
      }}
    >
      {children}
    </AudioContextProvider>
  );
};

import styled from '@emotion/styled';
import { FC, useEffect, useRef, useState } from 'react';
import { animated, config, useSpring } from 'react-spring';
import { ReactComponent as ArrowDownSvg } from '../../../../../../assets/icons/arrow-down.svg';

interface ICategoryProps {
  title: string;
}

export const Category: FC<ICategoryProps> = ({ title, children }) => {
  const [open, setOpen] = useState(false);
  const defaultHeight = 0;
  const [contentHeight, setContentHeight] = useState(defaultHeight);
  const accordionRef = useRef<HTMLDivElement>(null);

  const expand = useSpring({
    config: config.stiff,
    height: open ? `${contentHeight}px` : `${defaultHeight}px`,
    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
  });

  useEffect(() => {
    if (accordionRef && accordionRef.current) {
      setContentHeight(accordionRef.current.children[0].clientHeight);
    }
  }, [accordionRef.current, children]);

  return (
    <Container>
      <Header>
        <TitleAndOpen onClick={() => setOpen(!open)}>
          <Title>{title.toUpperCase()}</Title>
          <Rotator style={{ transform: expand.transform }}>
            <Arrow></Arrow>
          </Rotator>
        </TitleAndOpen>
      </Header>
      <Content style={{ height: expand.height }}>
        <ChildrenWrapper ref={accordionRef}>{children}</ChildrenWrapper>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #206283;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
`;

const Title = styled.div`
  width: 100%;
  font-size: 12px;
`;

const TitleAndOpen = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  cursor: pointer;
`;

const Rotator = styled(animated.div)`
  margin-right: 8px;
  display: flex;
  cursor: pointer;
`;

const Arrow = styled(ArrowDownSvg)`
  width: 20px;
  height: 20px;
`;

const Content = styled(animated.div)`
  display: flex;
  overflow: hidden;
  border-width: 0px;
  width: 100%;
`;

const ChildrenWrapper = styled.div`
  border-width: 0px;
  width: 100%;
`;

import * as React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 50%;
  top: 50%;
  width: 600px;
  margin: 20px;
  overflow: auto;
  background-color: #0f2c3b;
  transform: translate(-50%, -50%);
  border: black;
  border-style: solid;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 20px;
  margin: 40px;
`;

export const Header = styled.div`
  font-family: 'GravityBold8';
  font-size: 18px;
  flex-direction: row;
  padding: 0 20px;
  margin: 40px;
`;

export const Footer = styled.div`
  flex-direction: row;
  padding: 0 20px;
  margin: 40px;
`;

type DialogProps = {
  visible: boolean;
  hide: () => void;
  children: React.ReactNode;
};

export function Root({
  children,
  visible,
  hide,
  ...props
}: DialogProps): JSX.Element | null {
  if (!visible) {
    return null;
  }
  return (
    <>
      <Overlay onClick={hide} />
      <Wrapper>{children}</Wrapper>
    </>
  );
}

import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { MessageFieldsFragment } from '~/generated/graphql/operations';
import { usePenguin } from '~/hooks/use-penguin';
interface IMessageProps {
  sender: MessageFieldsFragment['user'];
  message: string;
  externalMessage: boolean;
}

export const Message: FC<IMessageProps> = ({
  sender,
  message,
  externalMessage,
}) => {
  const [tokenId, setTokenId] = useState(sender.activeNft?.tokenId);
  const [penguin] = usePenguin(Number(tokenId));
  useEffect(() => {
    setTokenId(sender.activeNft?.tokenId);
  }, [sender.activeNft]);

  return (
    <MessageContainer>
      {sender.activeNft ? <ProfilePicture src={penguin?.image} /> : null}
      <Data>
        <HeaderMessage
          style={{
            color: externalMessage ? '#5ae3ff' : '#18cc18',
          }}
        >
          {sender.nickname
            ? sender.nickname
            : `${sender.publicAddress.substring(
                0,
                5,
              )}...${sender.publicAddress.substring(
                sender.publicAddress.length - 5,
              )}`}
        </HeaderMessage>
        <MessageView>{message}</MessageView>
      </Data>
    </MessageContainer>
  );
};

const MessageContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  & > * {
    margin-right: 8px;
  }
  margin-bottom: 16px;
`;

const ProfilePicture = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 12px;
`;

const MessageView = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  font-size: 10px;
  text-align: start;  
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  font-size: 10px;
`;

const HeaderMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  font-weight: bolder;
  margin-bottom: 4px;
`;

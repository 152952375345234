import styled from '@emotion/styled';
import { FC, useContext } from 'react';
import { HeaderContext } from '../Application';
import { browserName, isMobile } from 'react-device-detect';
import PengzBanner from '../../assets/images/BannieresdesPengz.jpg';
import Snasch from '../../assets/images/Snasch.png';
import Thevert from '../../assets/images/Thevert.png';
import Yopif from '../../assets/images/Yopif.png';
import OxPeng from '../../assets/images/0xpeng.png';
import Lofo from '../../assets/images/Lofo.png';
import ThePeng from '../../assets/images/The-peng.png';
import { css } from '@emotion/react';

export const Team: FC = () => {
  const headerHeight = useContext(HeaderContext);
  return (
    <TeamContainer
      style={{
        height: `calc(100vh - ${headerHeight.height}px)`,
      }}
    >
      <Title useNeon={browserName !== 'Chrome' ? 1 : 0}>Team</Title>
      <Members>
        <Member>
          <MemberPeng>
            <PengImage src={`${ThePeng}`} />
          </MemberPeng>
          <Infos>
            <Name>The peng</Name>
            <Role>Lead project</Role>
          </Infos>
        </Member>
        <Member>
          <MemberPeng>
            <PengImage src={`${Snasch}`} />
          </MemberPeng>
          <Infos>
            <Name>snasch</Name>
            <Role>Lead dev</Role>
          </Infos>
        </Member>

        <Member>
          <MemberPeng>
            <PengImage src={`${Lofo}`} />
          </MemberPeng>
          <Infos>
            <Name>Lofo</Name>
            <Role>Artist designer</Role>
          </Infos>
        </Member>
        <Member>
          <MemberPeng>
            <PengImage src={`${Thevert}`} />
          </MemberPeng>
          <Infos>
            <Name>Thevert</Name>
            <Role style={{ whiteSpace: isMobile ? 'nowrap' : 'initial' }}>
              Community manager
            </Role>
          </Infos>
        </Member>
        <Member>
          <MemberPeng>
            <PengImage src={`${OxPeng}`} />
          </MemberPeng>
          <Infos>
            <Name>0xPeng</Name>
            <Role>Lead ops</Role>
          </Infos>
        </Member>
        <Member>
          <MemberPeng>
            <PengImage src={`${Yopif}`} />
          </MemberPeng>
          <Infos>
            <Name>Yopif</Name>
            <Role>Dev</Role>
          </Infos>
        </Member>
      </Members>
    </TeamContainer>
  );
};

const TeamContainer = styled.div`
  font-family: 'GravityBold8';
  background: linear-gradient(rgba(3, 25, 32, 0.8), rgba(3, 25, 32, 0.8)),
    url(${PengzBanner}) no-repeat center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  @media only screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 30px;
    background-position: 50% 0%;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    font-size: 20px;
    line-height: 30px;
    background-position: 50% 0%;
  }
`;

const Members = styled.div`
  flex-grow: 4;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
`;

const Member = styled.div`
  width: 30%;
  /* height: fit-content; */
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0px 32px;
  box-sizing: border-box;
  @media only screen and (max-width: 600px) {
    width: 49%;
    flex-direction: column;
    padding: 0px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    width: 24%;
  }
`;

const MemberPeng = styled.div`
  background-color: #091e2c;
  width: 140px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000000;
  @media only screen and (max-width: 600px) {
    align-items: center;
    padding: 0px 0px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    align-items: center;
    padding: 0px 0px;
  }
  @media only screen and (max-width: 375px) and (max-height: 667px) {
    width: 100px;
  }
`;

const PengImage = styled.img`
  width: 100%;
  border: 1px solid #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
`;

const Infos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  min-height: 100%;
  padding: 0px 32px;
  box-sizing: border-box;
  text-align: start;
  @media only screen and (max-width: 600px) {
    align-items: center;
    padding: 0px 0px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    align-items: center;
    padding: 0px 0px;
  }
  @media only screen and (max-width: 375px) and (max-height: 667px) {
    align-items: center;
  }
`;

const Name = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  @media only screen and (max-width: 600px) {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
  }
  @media only screen and (max-width: 375px) and (max-height: 667px) {
    margin-top: 8px;
    font-size: 12px;
    line-height: 14px;
  }
`;

const Role = styled.div`
  font-size: 12px;
  line-height: 18px;
  @media only screen and (max-width: 600px) {
    font-size: 10px;
    line-height: 14px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    font-size: 10px;
    line-height: 14px;
  }
  @media only screen and (max-width: 375px) and (max-height: 667px) {
    font-size: 8px;
    line-height: 14px;
  }
`;

const Title = styled.div<{ useNeon: 1 | 0 }>`
  flex-grow: 1;
  margin-top: 32px;
  font-size: 48px;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 42px #157e8c, 0 0 77px #157e8c,
    0 0 100px #157e8c;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #157e8c;
  ${({ useNeon }) =>
    useNeon
      ? css`
          animation: light 4s infinite linear;
        `
      : null}

  @media only screen and (max-width: 600px) {
    font-size: 30px;
    line-height: 40px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    font-size: 30px;
    line-height: 40px;
  }
  @media only screen and (max-width: 375px) and (max-height: 667px) {
    margin-top: 0px;
    font-size: 24px;
  }

  @keyframes light {
    0% {
      text-shadow: 0 0 7px #fff, 0 0 10px #157e8c, 0 0 42px #157e8c,
        0 0 77px #157e8c, 0 0 100px #157e8c;
    }
    25% {
      text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 32px #157e8c,
        0 0 87px #157e8c, 0 0 120px #157e8c;
    }
    50% {
      text-shadow: 0 0 7px #fff, 0 0 10px #157e8c, 0 0 32px #157e8c,
        0 0 87px #157e8c, 0 0 120px #157e8c;
    }
    75% {
      text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 32px #157e8c,
        0 0 87px #157e8c, 0 0 120px #157e8c;
    }
    90% {
      text-shadow: -4px 4px 0px #157e8c, 4px 4px 0px #157e8c,
        4px 4px 0px #157e8c, 4px 4px 0px #157e8c;
    }
  }
`;
